import React from "react";
import { FallingLines } from 'react-loader-spinner'


const Loader = () => {
    return (
        <>
            <div style={{ backgroundColor: 'rgba(0,0,0,0.9)', position: 'fixed', left: 0, top: 0, width: "100%", height: '100%', zIndex: 99999, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FallingLines
                    color="#fff"
                    width="100"
                    visible={true}
                    ariaLabel='falling-lines-loading'
                />
            </div>


        </>
    )
}
export default Loader;
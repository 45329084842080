import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { GoCommentDiscussion } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Footers from "../../components/Footers/Footers";
import Headers from "../../components/header/header";
import Loader from "../../components/Loader/loader";
import { ApiUrl, Getimage, getToken } from "../../utils/common";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


function ArticleView() {
    const location = useLocation();
    const ArticleData = location?.state;
    const [loading, setLoading] = useState(false);
    const [articleComment, setArticleComment] = useState('');
    const [show, setShow] = useState()
    const [replyId, setReplyId] = useState(0);
    const [commentReply, setCommentReply] = useState([]);
    const [articles, setArticles] = useState([]);
    var date = new Date()
    var exactDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

    const FindArticle = articles?.find((i) => i?.Id === ArticleData?.Id);

    const getArticles = () => {
        setLoading(true)
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Article/GetUserArticles`
        };

        axios(option)
            .then(e => {

                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                setArticles(data);
            })
            .catch((err) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        getArticles();
    }, [])
    const LikeArticle = (el, item) => {
        setLoading(true)
        const data = {};
        data.ArticleId = item;
        data.LikeOnDate = exactDate;
        var flag = (el.currentTarget.attributes['likeit'].value === 'true');
        data.IsUnlike = !flag;

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Article/LikeArticle`

        };
        axios(option)
            .then(e => {

                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                if (e?.data?.success) {
                    getArticles();
                }


            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
                setLoading(false)
            });
    }
    const ArticleCommentValidate = () => {
        if (!articleComment || articleComment.trim() === "") {
            toast.error("Please Enter your comment")
            return false;
        }
        return true;
    }


    const ReplyToArticle = (Id, el) => {
        if (!ArticleCommentValidate()) return;
        setLoading(true);
        const data = {};
        data.ArticleId = Id;
        data.Comment = articleComment;
        data.CommentDate = exactDate;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Article/CommentOnArticle`

        };
        axios(option)
            .then(e => {
                setLoading(false);
                if (e?.data?.success) {
                    setArticleComment('');
                    toast.success(e?.data?.message);
                    getArticleResponse(Id);
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.data?.messsage);
            });
    }
    const getArticleResponse = (id) => {
        setLoading(true)
        const Data = {};
        Data.data = id;

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: JSON.stringify(Data),
            url: `${ApiUrl()}/Article/ArticleComments`
        };

        axios(option)
            .then(e => {

                setLoading(false)
                const data = JSON.parse(e?.data?.data);
                setCommentReply(data);
            })
            .catch(err => {
                setLoading(false)
                toast.error(err?.data?.messsage);
            })
    }
    return (
        <>
            <div className="page-container">
                {
                    loading ? <Loader /> : null
                }

                <Headers innerPages={"innerPages"} />

                <section className="eat-ps">
                    <div className="container">
                        <section className="bredcrum-ps">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-8 col-md-8">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                                <Link to="/Articles" className="breadcrumb-item active">
                                                    Articles
                                                </Link>
                                                <li className="breadcrumb-item active" aria-current="page">Article View</li>

                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* breadcrumb */}

                        <div className="row">
                            <div className="col-12 col-md-10 col-sm-12 m-auto">

                                <div>
                                    <h1 className="article-view-title">{FindArticle?.Title}</h1>
                                    <div className="eatps-box">
                                        {
                                            FindArticle?.ImageUrl !== null ? <img src={`${Getimage(FindArticle?.ImageUrl)}`} alt="Article Image" className="adequat-img" /> : null
                                        }

                                    </div>


                                    <p dangerouslySetInnerHTML={{ __html: FindArticle?.Content }} className="article-content-view"></p>
                                    <div className="article-view-like">
                                        <div className="new-like-comment published-by-article">
                                            <a className="like-btn active" id="aa" likeit={`${!FindArticle?.IsLiked}`} onClick={(e) => { LikeArticle(e, ArticleData?.Id) }} >
                                                {
                                                    (FindArticle?.IsLiked) ? <AiFillLike className="icons-queries" /> : <AiOutlineLike className="icons-queries" />
                                                }
                                            </a>
                                            <span id="qLikeCount" className="like-count">{FindArticle?.LikesTotal}</span>
                                            <a className="like-btn comment-icon" onClick={() => { setShow(show !== ArticleData?.Id ? ArticleData?.Id : null); setReplyId(ArticleData?.Id); getArticleResponse(ArticleData?.Id) }}><GoCommentDiscussion className="icons-queries" />{FindArticle?.CommentCount}</a>
                                        </div>
                                        <div className="d-flex">
                                            <div className="new-image-article-view">
                                                <img src={`${Getimage(FindArticle?.ProfilePhotoUrl, "images/dummy.svg")}`} alt="publisher" className="eat-riya image-user-article" />
                                            </div>
                                            <div className="new-image-article-view">
                                                <span className="">{FindArticle?.ProfileName}</span>
                                                <span className="articleview-heading">{moment(FindArticle?.DateCreated).format('Do MMM YYYY')}</span>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {(show && replyId === FindArticle?.Id) ?

                                    <div className="new-name-box col-md-10 mt-2">
                                        {
                                            commentReply?.map((art, index) => {
                                                return (
                                                    <Fragment key={index} >

                                                        <div className="queries-reply-section-outer">
                                                            <div className="query-response-box">
                                                                <img src={`${Getimage(art?.UserPhotoUrl, "images/dummy.svg")}`} alt="User Img." className="image-new-cards-dshbord" />
                                                                <div className="title-text new-title-text">
                                                                    <div className="spam-mark-icon">
                                                                        <h1>{art?.CommentByUser}</h1>
                                                                    </div>
                                                                    <p dangerouslySetInnerHTML={{ __html: art?.Comment }}></p>
                                                                    <div className="new-like-comment">
                                                                        <span className="undrline-txt tab-float-design">{art?.CommentDate === null ? null : <>{moment(art?.CommentDate).format('Do MMM YYYY, h:mm A')}</>}</span>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="separator-query-resp"></div>

                                                        </div>
                                                    </Fragment>

                                                );
                                            })
                                        }

                                        <div className="input-grp-fil mb-3 query-reply-box">
                                            <CKEditor className="editor-main"
                                                editor={ClassicEditor}
                                                config={{
                                                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                                }}
                                                data={articleComment}
                                                onChange={(event, editor) => setArticleComment(editor?.getData())}

                                            />
                                            {/* <input type="text" className="form-control frm-cntrol txtReply" placeholder="Comment Here...." onChange={(e) => setArticleComment(e?.target?.value)} value={articleComment}></input> */}
                                            <span className="input-group-text" id="basic-addon2" onClick={(e) => ReplyToArticle(FindArticle?.Id, e)}>Reply</span>
                                        </div>
                                    </div>
                                    : null
                                }


                            </div>
                        </div>
                    </div>
                </section>
                {/* main section */}

                <Footers />
            </div>
        </>
    );
}
export default ArticleView;
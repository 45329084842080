import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Getimage } from "../../utils/common";

const GoldMember = ({ profilelistUser, onCardClick, search, setSearch, searchUser }) => {
    const goldMembers = profilelistUser.filter(item => item?.Active && item?.IsGoldMember === true);
    const [userData, setUserData] = useState('');
    const [serviceData, setServiceData] = useState('');
    const userDetail = (e) => {
        setUserData(e);
    }
    const ServiceData = (item) => {
        setServiceData(item)
        setUserData('');
    }

    useEffect(() => {
        if (serviceData) {
            setSearch(serviceData)
        }
    }, [serviceData])
    return (
        <>
            <div className="sidebar-ps">
                <h1 className="gold-mmbr row">Gold Members</h1>

                <div className="main-section-search-sidebar">
                    <input type="text" placeholder="Search Users...." className="search-input-sidebar" onChange={(e) => setSearch(e?.target?.value)} value={search} />
                    <i className="fa fa-search cls-icon icon-search-sidebar"></i>
                </div>
                {
                    goldMembers?.length && searchUser ?
                        goldMembers?.map((item, index) => (
                            <Fragment key={index} >
                                {
                                    <div className="siderbar-box text-center">
                                        <img src={`${Getimage(item?.Profile?.[0]?.ProfilePhotoUrl, "images/dummy.svg")}`} onError={(e) => { e.target.src = "images/dummy.svg" }} alt="userImg" className="user-img-queries-sidebar" />
                                        <h2 className="username-sidebar-cards">{item?.Profile?.[0]?.ProfileName}</h2>
                                        {
                                             item?.Id === 27 && item?.Profile?.[0]?.ConsultationFeePoint > 0 ?
                                                <p className="clr-chng mb-1 text-black text-opacity-75 fees"><b>Consultation Fee: </b>{item?.Profile?.[0]?.ConsultationFeePoint}</p>
                                                : null}
                                        <p className="profile-info-data-dashboard">{item?.Profile?.[0]?.Description?.trim()?.substr(0, 100)}{item?.Profile?.[0]?.Description?.length > 100 ? "..." : null}</p>
                                        <div className="selectUser">
                                            <button className="sidebarSelectgold" onClick={() => onCardClick(item?.Profile?.[0])}>Select User</button>
                                            <button className="sidebarViewButton" onClick={() => userDetail(item?.Profile?.[0])}>View Profile</button>
                                        </div>
                                    </div>
                                }
                            </Fragment>
                        ))
                        :
                        <div className="userNotFound">
                            <p>Gold members not found !!</p>
                        </div>
                }
            </div>

            <Modal show={userData?.Id}
                className="modal modl-PS" id="modalPS">
                <div className="modal-dialog m-none">
                    <div className="modal-content">

                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setUserData('')}></button>
                        </div>

                        <div className="modal-body">
                            <div className="main-mdl">
                                <div className="mdl-left">

                                    <img src={`${Getimage(userData?.ProfilePhotoUrl, "images/dummy.svg")}`} onError={(e) => { e.target.src = "images/dummy.svg" }} alt="img." className="main-image-modal" />
                                </div>

                                <div className="mdl-text-bx">
                                    <h2>{userData?.ProfileName}</h2>
                                    <p className="clr-chng">{userData?.Designation}</p>
                                    <p>{userData?.Description}</p>
                                </div>
                                <div className="ulmodal">

                                    <ul className="ul-list row">
                                        {
                                            userData?.Services?.split(',')?.map((item, index) => (
                                                <Fragment key={index} >
                                                    {
                                                        item === "" ? null : <li className="main-section-services-prev col-md-4 mb-set">
                                                            <a className="ps-profileyr servc-ps srvc-list li a set-padding" onClick={() => ServiceData(item)} style={{ cursor: 'pointer' }}>
                                                                {item}
                                                            </a>
                                                        </li>
                                                    }
                                                </Fragment>

                                            ))
                                        }
                                    </ul>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}
export default GoldMember;
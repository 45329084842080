import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";
import BannerOneimage from '../../images/ps-banner-1.png';
import BannerTwoimage from '../../images/ps-banner-2.png';
import BannerThreeimage from '../../images/ps-banner-3.png';
import BannerFourimage from '../../images/ps-banner-4.png';

const Banner = ({ search, onSearch, getMainProfiles }) => {

    const [showDataModal, setShowDataModal] = useState(false);

    const handleBannerClose = () => setShowDataModal(false);
    const handleBannerShow = () => setShowDataModal(true);
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 5000,
        cssEase: "linear",
        pauseOnHover: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    appendDots: dots => <ul>{dots}</ul>,
                    customPaging: i => (
                        <div className="banner-slick-dots-custom">
                        </div>
                    )
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    appendDots: dots => <ul>{dots}</ul>,
                    customPaging: i => (
                        <div className="banner-slick-dots-custom">
                        </div>
                    )
                }
            }
        ]
    };

    return (
        <>
            <section>
                <div className=" banner-slider">
                    <div className="row">
                        <Slider {...settings}>
                            <div className="banner-slider-inner">
                                <div className="banner-back-image" style={{ background: `url(${BannerOneimage})` }}>
                                    <div className="banner-read-more">
                                        <h1>Welcome to Private Square!</h1>
                                        <p>Are you feeling stressed? Feel free to share all your concerns and feelings and
                                            get expert consultation who will help you to deal with it.</p>
                                        <button onClick={() => { handleBannerShow() }}>Read More</button>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-slider-inner">
                                <div className="banner-back-image" style={{ background: `url(${BannerFourimage})` }}></div>
                            </div>
                            <div className="banner-slider-inner">
                                <div className="banner-back-image" style={{ background: `url(${BannerTwoimage})` }}></div>
                            </div>
                            <div className="banner-slider-inner" >
                                <div className="banner-back-image" style={{ background: `url(${BannerThreeimage})` }}>
                                    <div className="banner-first-image"></div>
                                    <div className="col-md-12">
                                        <div className="col-md-7 text-upper-section-bnr">
                                            <div className="container cntr-sction-banner">
                                                <div className="banner-stress">Are You feeling Stress</div>
                                                <div className="banner-consult">Consult our Specialists</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>


            <section className="bnr-input">
                <div className="bnr-inputIcone">
                    <input type="text" placeholder="Search Users...." className="input-type"
                        onChange={(e) => onSearch(e?.target?.value)} value={search} />
                    <i className="fa fa-search cls-icon" onClick={() => getMainProfiles()}></i>
                </div>
            </section>


            <Modal show={showDataModal} onHide={handleBannerClose}
                className="modal modl-PS responsive-mobile-modal banner-readmore-modal" id="modalPS">
                <div className="modal-dialog m-none">
                    <div className="modal-content">

                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleBannerClose()}></button>
                        </div>

                        <div className="modal-body">
                            <div className="main-mdl">
                                <div className="modal-spam-section">
                                    <h1 className="why-spam-heading banner-modal-heading">Welcome to Private Square!</h1>
                                    <p className="banner-data-read">Private Square is an online consultation platform. Here you can share what you
                                        are feeling, your thoughts, stress, anxiety, or any feelings that is bothering your
                                        mental health.
                                    </p>
                                    <p className="banner-data-read">
                                        Feel free to write and share your feelings and we assure you that your name will
                                        be strictly anonymous as per your preference. Share your thoughts and worries
                                        freely and we promise you to keep it strictly private.
                                    </p>
                                    <p className="banner-data-read">
                                        Here in Private Squares, we have a group of expert consultants who will provide
                                        you with the best solutions that will help you to deal with your stress
                                        management.
                                    </p>
                                    <p className="banner-data-read">
                                        Feel free to get expert consultations from professionals who are always here to help you out.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Banner;

import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiFillDelete, AiFillEdit, AiFillLike, AiOutlineLike } from "react-icons/ai";
import { GoCommentDiscussion } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiUrl, Getimage, getToken } from "../../utils/common";
import Footers from "../../components/Footers/Footers";
import Loader from '../../components/Loader/loader';
import Headers from "../../components/header/header";
import moment from "moment";
import Aos from 'aos';


const SpamQueries = () => {
    // all states
    const [profilelist, setProfilelist] = useState([]);
    const [profilelistUser, setProfilelistUser] = useState([]);
    const [userData, setUserData] = useState('');
    const [Profileimage, setProfileImg] = useState('');
    const [userName, setUserName] = useState('');
    const [userDesignation, setUserDesignation] = useState('');
    const navigate = useNavigate();
    const [getQuery, setGetQuery] = useState([]);

    const getSpamUsers = getQuery?.filter((item) => item?.SpamByUsers?.length);
    const [loading, setLoading] = useState(false);
    const [deleteQueryModal, setDeleteQueryModal] = useState(false);
    const handleDeleteClose = () => setDeleteQueryModal(false);
    const [deleteDataStore, setDeleteDataStore] = useState('');
    const ActiveData = getSpamUsers.filter(item => item?.Active)

    const [deleteMainQueryModal, setDeleteMainQueryModal] = useState(false);
    const handleMainDeleteClose = () => setDeleteMainQueryModal(false);
    const [deleteMainThreadData, setDeleteMainThreadData] = useState('');

    const DeleteMainThread = (item) => {
        setDeleteMainQueryModal(true);
        setDeleteMainThreadData(item)
    }

    // editor state 
    const data = "";
    const getMainProfiles = () => {

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "data": data },
            url: `${ApiUrl()}/User`

        };
        axios(option)
            .then(e => {
                const data = JSON.parse(e?.data?.data || '{}');
                setProfilelistUser(data);
                setUserData()

            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            });
    }

    // get profile api
    const getProfiles = () => {
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Profile`
        };
        axios(option)
            .then(e => {
                var pro = {};
                pro.Profile = JSON.parse(e?.data?.data).Profile?.[0];
                pro.FullName = GetFullName(pro.Profile);

                setProfilelist(pro);
                setUserDesignation(pro?.Profile?.Designation);
                setUserName(pro?.Profile?.ProfileName);
                setProfileImg(`${Getimage(pro?.Profile?.ProfilePhotoUrl)}`);
            })
            .catch((err) => {
                navigate('/');
            });
    }

    const GetFullName = (d) => {
        var name = d.FirstName || "";
        if (d.MiddleName != null) name += (" " + d.MiddleName);
        if (d.LastName != null) name += (" " + d.LastName || "");
        return name;

    }

    useEffect(() => {
        getMainProfiles();
        getProfiles();
        getQueries();

    }, [])

    // queries display api
    const getQueries = () => {
        setLoading(true)
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Queries/GetUserQueries`
        };

        axios(option)
            .then(e => {
                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                setGetQuery(data);

            })
            .catch((err) => {
                setLoading(false)
            })
    }
 
    // delete userquery api
    const DeleteUserQuery = () => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { 'Data': deleteDataStore },
            url: `${ApiUrl()}/Queries/DeleteQueryResponse`,
        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    handleDeleteClose();
                    window?.location?.reload();

                }
            })
            .catch(err => {
            })
    }

    const DeleteQuery = () => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { 'Data': deleteMainThreadData?.Id },
            url: `${ApiUrl()}/Queries/DeleteQuery`
        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    handleMainDeleteClose();
                    toast.success(e?.data?.message);
                    getQueries();
                }
            })
            .catch(err => {

            })
    }


    useEffect(() => {
        Aos.init();
    }, []);

    return (
        <>
            <div className="page-container">
                {
                    loading ? <Loader /> : null
                }
                <Headers innerPages={"innerPages"} />
                <section className="bredcrum-ps">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active add-mr-right" aria-current="page"> <a href="/#/spamqueries">Spam Queries</a></li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section className="quries-clrfix">


                    <div className="container userquery-tab-container">
                        <div className={`row ${ActiveData.length === 0 ? "tab-row-set" : "tab-row-set-new"}`}>
                            <div className="col-md-9">


                                {
                                    ActiveData.length === 0 ?
                                        <div className="no-query-section">
                                            <div className="no-query-section-two">
                                                <h2>You have no spam queries.</h2>
                                                <Link className="no-query-link" to="/">Go to Dashboard</Link>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {
                                                ActiveData.length === 0 ? null :

                                                    <div className="row">
                                                        <div className="col-12 set-width-on-tab new-get-query-div">
                                                            <div className="row">
                                                            </div>

                                                            {
                                                                getSpamUsers?.map((item, index) => {

                                                                    return (

                                                                        <Fragment key={index}>
                                                                            {
                                                                                item?.Active && item?.SaveAsDraft === false ? <>

                                                                                    <div data-aos="flip-down" data-aos-easing="ease-in-sine" data-aos-duration="400" className="pd-on-mob">
                                                                                        <div className={`row ${ActiveData.length >= 1 ? "new-height-private" : "auto-height-private"}`}>
                                                                                            <div className="ps-name-box">
                                                                                                <img src={item?.Anonymous ? "images/anonymoususer.png" : `${Getimage(item?.ProfilePhotoUrl,"images/dummy.svg")}`} alt="User Img." className="image-main-cards-dshbord" />
                                                                                                <div className="title-text new-title-text">
                                                                                                    <div>
                                                                                                        <h1>{item?.ProfileName === "" ? "Anonymous" : item?.ProfileName} - {item?.Title}</h1>
                                                                                                        {
                                                                                                            item.Private ? <img src="images/lock-in-a-circle.png" className="anonymous-img-queries new-private-img-queries" tabIndex="0" data-toggle="tooltip" title="Private Query" /> : null
                                                                                                        }
                                                                                                        {
                                                                                                            item?.Anonymous ? <img src="images/anonymous.jpg" className="anonymous-img-queries" tabIndex="0" data-toggle="tooltip" title="Anonymous User" /> : null
                                                                                                        }

                                                                                                        <AiFillDelete className="delete-icon-query-final" onClick={() => DeleteMainThread(item)} tabIndex="0" data-toggle="tooltip" title="Delete Query" />
                                                                                                    </div>
                                                                                                    <div className="query-text-figure" dangerouslySetInnerHTML={{ __html: item?.QueryText }}></div>
                                                                                                    <div className="new-like-comment">
                                                                                                        <a className="like-btn active" id="aa" >
                                                                                                            <AiOutlineLike className="icons-queries" /> 
                                                                                                        </a>                                                                                        
                                                                                                        <span id="qLikeCount" className="like-count">{item?.LikesTotal}</span>
                                                                                                        <a className="like-btn comment-icon"><GoCommentDiscussion className="icons-queries" />{item?.ReplyCount}</a>
                                                                                                        <span className="undrline-txt">{item?.DateCreated === null ? null : <> <span className='published-date'>Published On:</span> {moment(item?.DateCreated)?.format('Do MMM YYYY, h:mm A')} </>}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', width: '100%' }}>
                                                                                                    {
                                                                                                        item?.QueryTo?.length ?
                                                                                                            <div className="outer-section-participants spam-overlay-page">
                                                                                                                <OverlayTrigger
                                                                                                                    placement="right"
                                                                                                                    delay={{ show: 250, hide: 250 }}
                                                                                                                    overlay={
                                                                                                                        <Tooltip id="button-tooltip" className="new-query-tooltip">
                                                                                                                            <div className="new-participant-section">
                                                                                                                                <div className="participants-section">
                                                                                                                                    {
                                                                                                                                        item?.QueryTo?.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <div key={index} className="participants-inner-section">
                                                                                                                                                    <img src={item?.ProfilePhotoUrl === '' ? "images/anonymoususer.png" : `${Getimage(item?.ProfilePhotoUrl,"images/dummy.svg")}`} alt="image" className="participants-image" />
                                                                                                                                                    <div className="information_section_participants">
                                                                                                                                                        <p>{item?.ProfileName}</p>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </Tooltip>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <h3>Participants

                                                                                                                        <div>
                                                                                                                            <span>{item?.QueryTo?.length}</span>
                                                                                                                        </div>
                                                                                                                    </h3>

                                                                                                                </OverlayTrigger>
                                                                                                            </div> : null
                                                                                                    }

                                                                                                    {
                                                                                                        item?.SpamByUsers?.length ?
                                                                                                            <div className="outer-section-participants spam-overlay-page">
                                                                                                                <OverlayTrigger
                                                                                                                    placement="right"
                                                                                                                    delay={{ show: 250, hide: 250 }}
                                                                                                                    overlay={
                                                                                                                        <Tooltip id="button-tooltip" className="new-query-tooltip">
                                                                                                                            <div className="new-participant-section">
                                                                                                                                <div className="participants-section">
                                                                                                                                    {
                                                                                                                                        item?.SpamByUsers?.map((item, index) => {
                                                                                                                                            const getSpamuser = profilelistUser?.filter((i) => i?.Id === item?.SpamByUserId)?.[0]?.Profile?.[0];
                                                                                                                                            return (
                                                                                                                                                <div key={index} className="participants-inner-section">
                                                                                                                                                    <img src={item?.ProfilePhotoUrl === '' ? "images/anonymoususer.png" : `${Getimage(getSpamuser?.ProfilePhotoUrl,"images/dummy.svg")}`} alt="image" className="participants-image" />
                                                                                                                                                    <div className="information_section_participants">
                                                                                                                                                        <p>{getSpamuser?.ProfileName}</p>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </Tooltip>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <h3>Spam By

                                                                                                                        <div>
                                                                                                                            <span>{item?.SpamByUsers?.length}</span>
                                                                                                                        </div>
                                                                                                                    </h3>

                                                                                                                </OverlayTrigger>
                                                                                                            </div> : null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> </>
                                                                                    : null
                                                                            }
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>

                                                    </div>

                                            }
                                        </>

                                }

                            </div>
                        </div>
                    </div>
                </section>

                <Footers />

                {/* delete query response modal */}
                <Modal show={deleteQueryModal} onHide={handleDeleteClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleDeleteClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading delete-modal-heading"><span>Are</span> you sure want to delete this Query?</h1>
                                        <div className="why-spam-btn">
                                            <button className="btn btn-primary button-delete-modal" onClick={() => DeleteUserQuery()}>
                                                Yes
                                            </button>

                                            <button className="btn btn-danger button-delete-modal-No" onClick={() => handleDeleteClose()}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>


                {/* delete query modal */}
                <Modal show={deleteMainQueryModal} onHide={handleMainDeleteClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleMainDeleteClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading delete-modal-heading"><span>Are</span> you sure want to delete this Query?</h1>
                                        <div className="why-spam-btn">
                                            <button className="btn btn-primary button-delete-modal" onClick={() => DeleteQuery()}>
                                                Yes
                                            </button>

                                            <button className="btn btn-danger button-delete-modal-No" onClick={() => handleMainDeleteClose()}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}
export default SpamQueries;

import React from "react";
import Autocomplete from "react-autocomplete";

const  AutoComplete = ({value,onValueChange,onSelect,handleClick,options}) => {
  return (
    <>
      <Autocomplete style={{width: '70%', display: "visible"}}  
        getItemValue={(item) => item?.ServiceName}
        shouldItemRender={(item, value) => item?.ServiceName?.toLowerCase().indexOf(value?.toLowerCase()) > -1}
        items={options}
        renderItem={(item, isHighlighted) =>
          <div style={{ background: isHighlighted ? '#689cca' : 'transparent' }}>
            {item?.ServiceName}
          </div>
        }
        value={value}
        onChange={onValueChange}
        onSelect={onSelect}
        inputProps={{ placeholder: 'Add services' }}
      />
      {value?.length ? <button type="button" className="button-add-service" onClick={handleClick}>Add Service</button> : null}
      
    </>
  )
}


export default AutoComplete;
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Getimage, getToken } from "../../utils/common";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from "react-router-dom";
import moment from "moment";


const ProfileModal = ({ userData, setUserData, ServiceData, setCopyText, copyText, userProfileData }) => {
    const navigate = useNavigate()
    const PagePathname = window.location.href;
    useEffect(() => {
        if (copyText) {
            const CopiedTimeOut = setTimeout(() => {
                setCopyText(false);
            }, 1000)
            return () => clearTimeout(CopiedTimeOut)
        }

    }, [copyText])

    const [modalIsOpen, setModalIsOpen] = useState(true);
    const PathAfteropenModal = window?.location?.search?.split('=')?.[0];

    const userLogin = getToken();
    const daysOfWeekOrder = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return (
        <>
            <Modal onEntered={(e) => setModalIsOpen(true)} show={userData?.Id}
                className="modal modl-PS" id="modalPS">
                <div className="modal-dialog m-none">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {
                                setUserData('');
                                if (PathAfteropenModal) {
                                    window.location.href = window.location.origin
                                }
                            }}
                            ></button>

                        </div>

                        <div className="modal-body">
                            {
                                PathAfteropenModal && modalIsOpen ? null :
                                    <div className="copy-button-modal-section">
                                        <CopyToClipboard text={`${PagePathname}?id=${userData?.UserId}`} onCopy={() => setCopyText(true)}>
                                            <a>Share Profile</a>
                                        </CopyToClipboard>
                                        {
                                            copyText ? <h4 className="copied-text">Copied</h4> : null
                                        }
                                    </div>
                            }
                            <div className="main-mdl">
                                <div className="mdl-left">
                                    <img src={`${Getimage(userData?.ProfilePhotoUrl, "images/dummy.svg")}`} onError={(e) => { e.target.src = "images/dummy.svg" }} alt="User." className="main-image-modal" />
                                </div>

                                <div className="mdl-text-bx">
                                    <h2>{userData?.ProfileName}</h2>
                                    <p className="clr-chng">{userData?.Designation}</p>
                                    <p>{userData?.Description}</p>

                                </div>
                                <div className="ulmodal">
                               

                                    <ul className="ul-list row">
                                    <div className="col-md-12 days-available-profile-modal ms-3"><span>Services</span></div>
                                        {
                                            userData?.Services?.split(',')?.map((item, i) => (
                                                <Fragment key={i}>
                                                    {
                                                        item === "" ? null : <li className="main-section-services-prev col-md-4 mb-set">
                                                            <a className="ps-profileyr servc-ps srvc-list li a set-padding" onClick={() => ServiceData(item)} style={{ cursor: 'pointer' }}>
                                                                {item}
                                                            </a>
                                                        </li>
                                                    }
                                                </Fragment>

                                            ))
                                        }
                                    </ul>
                                </div>

                            </div>
                       

                            {userData?.AvailabilityDays ? (
                                <div>
                                    <div className="col-md-12 days-available-profile-modal"><span>Availability of Days</span></div>
                                    <div className="col-md-12 d-flex gap-4">
                                        {userData?.AvailabilityDays.split(',')
                                            .map(day => day.slice(0, 3))
                                            .sort((a, b) => daysOfWeekOrder.indexOf(a) - daysOfWeekOrder.indexOf(b))
                                            .map((day, index) => (
                                                <div className="selectedWeekDay-profile-modal" key={index}>
                                                    {day}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ) : null}


                            {userData?.WorkingHours?.length ? <>  <div className="col-md-12 days-available-profile-modal"><span>Working Hours</span></div>
                                <div className="col-md-12 d-flex gap-4">
                                    {
                                        userData?.WorkingHours.sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime)).map((i) => (

                                            <div className="selectedWeekDay-profile-modal">{moment(i.StartTime).format("hh:mm A")}-{moment(i.EndTime).format("hh:mm A")}</div>

                                        ))
                                    }
                                </div>  </> : null}

                            {
                                userData?.UserId === userProfileData?.Id ? null : userLogin ?
                                    <button className="contact-button-modal" onClick={() => navigate('/queries', { state: { modalData: userData } })}>Contact This Person</button>
                                    : null
                            }


                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}
export default ProfileModal;
import React from "react";
import './popup.css';
import CloseImage from '../../../../src/close.png';



function Popup(props) {


    return (props.trigger) ? (

        <div className="popup">
            <div className="popup-inner">
                {
                    props.showactivebutton === true ? <button className="close-btn" onClick={() => {props.setTrigger(false);props.setShowInput(false)}}>
                        <img src={CloseImage} alt="close-img" />
                    </button> : null
                }

                {props.children}
            </div>

        </div>

    ) : "";
}

export default Popup;
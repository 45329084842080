import React from "react";


const Emojis = [
    {   
        id: '1',
        image: '/images/smile.png',
        value: 'Smile'     
    },
    {   
        id: '2',
        image: '/images/sad.png',
        value: 'Sad'     
    },
    {   
        id: '3',
        image: '/images/laughing.png',
        value: 'Laugh'     
    },
    {   
        id: '4',
        image: '/images/angry.png',
        value: 'Angry'     
    },
    {   
        id: '5',
        image: '/images/heart.png',
        value: 'Heart'     
    },
]
export default Emojis;
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footers from "../../components/Footers/Footers";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ApiUrl, Getimage, getToken, Getsaltyeml, GetRoleName } from "../../utils/common";
import axios from "axios";
import { AiFillDelete, AiFillEdit, AiOutlineLike } from 'react-icons/ai';
import { GoCommentDiscussion } from 'react-icons/go';
import { Form, Modal, OverlayTrigger, Tooltip, } from "react-bootstrap";
import { toast } from "react-toastify";
import MemberSidebar from "../../components/goldmembersidebar/goldmember";
import { ImCross } from "react-icons/im";
import { AiFillLike } from 'react-icons/ai';
import Loader from "../../components/Loader/loader";
import Headers from "../../components/header/header";
import moment from "moment/moment";
import GoldMember from "../../components/goldMember/goldmember";
import Aos from 'aos';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { RiDeleteBin6Line } from "react-icons/ri";

const Quaries = () => {
    // all states
    const location = useLocation();
    const AnotherPageEditData = location?.state?.editData;
    const selectedData = location?.state?.data?.Profile?.[0];
    const ProfileModalData = location?.state?.modalData;
    const [profilelist, setProfilelist] = useState([]);
    const [profilelistUser, setProfilelistUser] = useState([]);
    const [userData, setUserData] = useState('');
    const findItemData = profilelistUser?.find((i) => i?.Id === selectedData?.UserId);
    const findModalData = profilelistUser?.find((i) => i?.Id === ProfileModalData?.UserId);
    const [cardData, setCardData] = useState([]);
    const [Profileimage, setProfileImg] = useState('');
    const [userName, setUserName] = useState('');
    const [userDesignation, setUserDesignation] = useState('');
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [queryText, setQueryText] = useState("");
    const [isPublic, setIsPublic] = useState("private");
    const [anonymous, setAnonymous] = useState("anonymous");
    const [getQuery, setGetQuery] = useState([]);

    const active = getQuery?.filter((i) => i?.Active);
    const [show, setShow] = useState();
    const [replyId, setReplyId] = useState(0);
    const [queryReply, setQueryReply] = useState("");
    const [loading, setLoading] = useState(false);
    const [queriesList, setQueriesList] = useState([]);
    const [anonymousName, setAnonymousName] = useState();
    const [search, setSearch] = useState();
    const [showSpam, setShowSpam] = useState(false);
    const handlespamClose = () => setShowSpam(false);
    const handlespamShow = () => setShowSpam(true);
    const [spamMarkData, setSpamMarkData] = useState();
    const [spamReason, setSpamReason] = useState();
    const [spamSucessData, setSpamSuccessData] = useState();

    const [deleteQueryModal, setDeleteQueryModal] = useState(false);
    const handleDeleteClose = () => setDeleteQueryModal(false);
    const [deleteDataStore, setDeleteDataStore] = useState('');
    const [maxPageTitle, setMaxPageTitle] = useState(40);
    const [deleteMainQueryModal, setDeleteMainQueryModal] = useState(false);
    const handleMainDeleteClose = () => setDeleteMainQueryModal(false);
    const [deleteMainThreadData, setDeleteMainThreadData] = useState('');
    const [spamMainThreadData, setSpamMainThreadData] = useState('');
    const [spamMainThreadShow, setSpamMainThreadShow] = useState(false);
    const [editQueryData, setEditQueryData] = useState('');
    const handleQuerySpamClose = () => {
        setSpamMainThreadShow(false);
    }
    const handleQuerySpamShow = () => {
        setSpamMainThreadShow(true);
    }
    const [threadSpamReason, setThreadSpamReason] = useState();
    const [queryResponseId, setQueryResponseId] = useState('');
    const [editQueryResp, setEditQueryResp] = useState()
    const [searchUser, setSearchUser] = useState(true);
    var date = new Date()
    var exactDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    const ActiveData = getQuery.filter(item => item?.Active)
    const DeleteModal = (qResp) => {
        setDeleteQueryModal(true);
        setDeleteDataStore(qResp?.Id)
    }

    const DeleteMainThread = (item) => {
        setDeleteMainQueryModal(true);
        setDeleteMainThreadData(item)
    }

    useEffect(() => {
        const LoadData = async () => {
            const findIndexItem = cardData?.find(item => item?.Id === selectedData?.Id);
            if (findIndexItem) return null
            if (findItemData?.Id) {
                setCardData([...cardData, findItemData?.Profile?.[0]])
            }
        }
        LoadData();

    }, [findItemData])

    useEffect(() => {
        const LoadData = async () => {
            const findItemData = cardData?.find(item => item?.Id === ProfileModalData?.Id);
            if (findItemData) return null

            if (findModalData?.Id) {
                setCardData([findModalData])
            }
        }
        LoadData();
    }, [findModalData])


    useEffect(() => {
        if (cardData?.length) {
            const totalFee = cardData.map(i => i.ConsultationFeePoint).reduce((a, b) => a + b);
        }
    }, [cardData])

    // editor state
    const eml = Getsaltyeml();
    const getMainProfiles = () => {
        const data = (search != null) ? search : "";
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "data": data },
            url: `${ApiUrl()}/User`

        };
        axios(option)
            .then(e => {
                const data = JSON.parse(e?.data?.data || '{}');
                setProfilelistUser(
                    data.filter(function (n, i) {
                        return n.UserName !== eml
                    })
                );
                setUserData();
                setSearchUser(true);

            })
            .catch((err) => {
                setSearchUser(false);
            });
    }

    // get profile api
    const getProfiles = () => {
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Profile`
        };
        axios(option)
            .then(e => {
                var pro = {};
                pro.Profile = JSON.parse(e?.data?.data).Profile?.[0];
                pro.FullName = GetFullName(pro.Profile);

                setProfilelist(pro);
                setUserDesignation(pro?.Profile?.Designation);
                setUserName(pro?.Profile?.ProfileName);
                setProfileImg(`${Getimage(pro?.Profile?.ProfilePhotoUrl)}`);
                setAnonymousName(pro?.Profile?.AnonymousName);

            })
            .catch((err) => {
                navigate('/');
            });
    }

    const GetFullName = (d) => {
        var name = d.FirstName || "";
        if (d.MiddleName != null) name += (" " + d.MiddleName);
        if (d.LastName != null) name += (" " + d.LastName || "");
        return name;

    }
    useEffect(() => {
        getMainProfiles();
    }, [search])

    useEffect(() => {
        getProfiles();
    }, [])

    // queries display api
    const getQueries = () => {
        setLoading(true)
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Queries/GetUserQueries`
        };

        axios(option)
            .then(e => {

                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                setGetQuery(data);
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getQueries();
    }, []);

    // askquestion queries api
    const Submit = () => {
        if (!validate()) return;
        setLoading(true);
        const data = {}
        data.Title = title;
        data.QueryText = queryText;
        data.Private = isPublic === "private";
        data.Anonymous = anonymous === "anonymous";
        data.AskToUser = cardData?.map(item => item?.UserId);
        data.SaveAsDraft = false;
        data.DateCreated = exactDate;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/askquestion`
        };

        axios(option)
            .then(e => {
                setLoading(false)
                toast.success(e?.data?.message);
                setTitle("");
                setQueryText('');
                setAnonymous('');
                setIsPublic('');
                setProfilelist(data);
                getQueries();
                window.location.reload();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
                setLoading(false)
            });
        // }

    }

    // validations 
    const validate = () => {
        if (!title || title.trim() === "") {
            toast.error("Please Enter Query Title")
            return false;
        }

        if (!queryText || queryText.trim() === "") {
            toast.error("Please Enter Your Queries")
            return false;
        }

        if (!cardData || cardData.length === 0) {
            toast.error("Please Select Users")
            return false;
        }
        if (isPublic == null) {
            toast.error("Please select Public or Private");
            return false;
        }
        if (anonymous == null) {
            toast.error("Please select Name or Anonymous");
            return false;
        }
        return true;
    }

    const validateDraft = () => {
        if (!title || title.trim() === "") {
            toast.error("Please Enter Query Title")
            return false;
        }

        if (!queryText || queryText.trim() === "") {
            toast.error("Please Enter Your Queries")
            return false;
        }
        return true;
    }

    const onRemove = (val) => {
        let arr = [...cardData]
        const index = arr.indexOf(val);

        if (index > -1) {
            arr.splice(index, 1)
        }
        setCardData(arr)

    }

    //  query like api
    const LikeSubmit = (el, Id) => {
        setLoading(true)
        const data = {};
        data.QueryId = Id;
        data.LikeOnDate = exactDate;
        var flag = (el.currentTarget.attributes['likeit'].value === 'true');
        data.IsUnlike = !flag;

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/LikeQuery`

        };
        axios(option)
            .then(e => {
                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                getQueries();
            })
            .catch((err) => {

                toast.error(err?.response?.data?.message);
                setLoading(false)
            });
    }

    const LikeQueryResponse = (el, qr) => {
        setLoading(true)
        const data = {};
        data.ResponseId = qr?.Id;
        data.LikeOnDate = exactDate;
        var flag = (el.currentTarget.attributes['likeit'].value === 'true');
        data.IsUnlike = !flag;

        const option = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/LikeResponse`
        };
        axios(option)
            .then(e => {
                setLoading(false);
                const data = JSON.parse(e?.data?.data || '{}');
                qr.LikeCount = data?.LikeCount;
                qr.IsLike = !qr.IsLike;
            })
            .catch((err) => {

                toast.error(err?.response?.data?.message);
                setLoading(false)
            });
    }

    const ReplyToQuery = (id, el) => {
        if (!QueryReplyValidate()) return;
        setLoading(true);
        const data = {};
        data.QueryId = id;
        data.Response = queryReply;
        data.ResponseDate = exactDate;


        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/ResponseToQuery`

        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    setQueryReply('');
                    getQueries();
                    toast.success(e?.data?.message);
                    getQueryResponse(id);
                }
            })
            .catch((err) => {
                toast.error(err?.data?.messsage);
            });
    }

    const QueryReplyValidate = () => {
        if (!queryReply || queryReply.trim() === "") {
            toast.error("Please Enter Query Reply")
            return false;
        }
        return true;
    }

    const getQueryResponse = (id) => {
        setLoading(true)
        const Data = {};
        Data.data = id;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: JSON.stringify(Data),
            url: `${ApiUrl()}/Queries/QueryResponse`
        };

        axios(option)
            .then(e => {
                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                setQueriesList(data);
            })
            .catch(err => {
                setLoading(false)
                toast.error(err?.data?.messsage);
            })
    }

    const spamMark = (qResp) => {
        setSpamMarkData(qResp)
        handlespamShow();
    }
    const spamMarkValidate = () => {
        if (!spamReason || spamReason?.trim() === '') {
            toast.error("Please Enter Spam Reason")
            return false;
        }
        return true;
    }

    const markSpamSubmit = () => {
        if (!spamMarkValidate()) return;
        const data = {}
        data.QueryId = spamMarkData?.QueryId;
        data.ResponseId = spamMarkData?.Id;
        data.SpamOnDate = exactDate;
        data.ReasonToMarkspam = spamReason;

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/MarkResponseAsSpam`,
        };

        axios(option)
            .then(e => {
                if (e?.status === 200) {
                    handlespamClose();
                    toast.success(e?.data?.message)
                    setSpamSuccessData(e);
                    getQueryResponse(queryResponseId);
                    setSpamReason('');
                }
            })
            .catch(err => {
                toast.error(err?.response?.data?.message);
            })
    }


    const DeleteUserQuery = () => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { 'Data': deleteDataStore },
            url: `${ApiUrl()}/Queries/DeleteQueryResponse`,
        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    handleDeleteClose();
                    window?.location?.reload();

                }
            })
            .catch(err => {
            })
    }

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, [])


    const DeleteQuery = () => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { 'Data': deleteMainThreadData?.Id },
            url: `${ApiUrl()}/Queries/DeleteQuery`
        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    handleMainDeleteClose();
                    toast.success(e?.data?.message);
                    getQueries();
                }
            })
            .catch(err => {

            })
    }

    const QueryThreadSpam = (item) => {
        setSpamMainThreadData(item);
        handleQuerySpamShow();
    }

    const QueryFieldValidate = () => {
        if (!threadSpamReason || threadSpamReason?.trim() === '') {
            toast.error("Please Enter Spam Reason")
            return false;
        }
        return true;
    }

    const MainQuerySpam = () => {
        if (!QueryFieldValidate()) return;
        const data = {};
        data.QueryId = spamMainThreadData?.Id;
        data.SpamOnDate = exactDate;
        data.ReasonToMarkspam = threadSpamReason;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/MarkQueryAsSpam`,
        };
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    handleQuerySpamClose();
                    getQueries();
                    setThreadSpamReason('');
                }
            })
            .catch(err => {

            })
    }

    const ThreadUnspam = (item) => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(item?.Id) },
            url: `${ApiUrl()}/Queries/UnspamQuery`,
        };
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    getQueries();
                }
            })
            .catch(err => {
            })
    }

    const unSpamResponse = (qResp) => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(qResp?.Id) },
            url: `${ApiUrl()}/Queries/UnspamResponse`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    getQueryResponse(queryResponseId);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
    }

    const handleEditQuery = (item) => {
        setEditQueryData(item);
        window?.scrollTo(0, 0)
    }

    useEffect(() => {
        const GetEditUsers = editQueryData?.QueryTo?.filter((l) => l?.UserId !== editQueryData?.OwnerId);
        if (editQueryData?.Id) {
            setTitle(editQueryData?.Title);
            setQueryText(editQueryData?.QueryText);
            setIsPublic(editQueryData?.Private ? "private" : "public");
            setAnonymous(editQueryData?.Anonymous ? "anonymous" : "name");
            setCardData(GetEditUsers);
        }
    }, [editQueryData]);


    const updateQuery = () => {
        if (!validate()) return;
        const data = {};
        data.Title = title;
        data.QueryText = queryText;
        data.Private = isPublic === "private";
        data.Anonymous = anonymous === "anonymous";
        data.AskToUser = cardData?.map(item => item?.UserId);
        data.DateCreated = exactDate;
        data.SaveAsDraft = false;
        data.Id = editQueryData?.Id || AnotherPageEditData?.Id;
        const option = {
            method: "POST", headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/askquestion`
        };
        axios(option)
            .then((e) => {

                if (e?.data?.success) {
                    toast.success("Query Update successfully");
                    window?.location?.reload();
                    window.history.replaceState({}, document.title)
                }
            })
            .catch((err) => {

            })
    }

    // edit through another page
    useEffect(() => {
        const GetEditUsers = AnotherPageEditData?.QueryTo?.filter((l) => l?.UserId !== AnotherPageEditData?.OwnerId);
        if (AnotherPageEditData?.Id) {
            setTitle(AnotherPageEditData?.Title);
            setQueryText(AnotherPageEditData?.QueryText);
            setIsPublic(AnotherPageEditData?.Private ? "private" : "public");
            setAnonymous(AnotherPageEditData?.Anonymous ? "anonymous" : "name");
            setCardData(GetEditUsers);
        }
    }, [AnotherPageEditData])

    const CancelButton = () => {
        if (AnotherPageEditData?.Id) {
            navigate("/publicDiscussion")
        } else {
            window?.location?.reload();
        }

    }

    const saveAsDraft = () => {
        if (!validateDraft()) return;
        setLoading(true);
        const data = {}
        data.Title = title;
        data.QueryText = queryText;
        data.Private = isPublic === "private";
        data.Anonymous = anonymous === "anonymous";
        data.AskToUser = cardData?.map(item => item?.UserId);
        data.SaveAsDraft = true;
        data.DateCreated = exactDate;
        data.Id = editQueryData?.Id;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/askquestion`
        };

        axios(option)
            .then(e => {
                setLoading(false)
                toast.success(e?.data?.message);
                setTitle("");
                setQueryText('');
                setAnonymous('');
                setIsPublic('');
                setProfilelist(data);
                getQueries();
                window.location.reload();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
                setLoading(false)
            });
    }
    const handleEditQueryResponse = (item) => {
        setEditQueryResp(item)
        setQueryReply(item?.Response)
    }
    const EditReplyToQuery = () => {
        if (!QueryReplyValidate()) return;
        setLoading(true);
        const data = {};
        data.QueryId = editQueryResp?.QueryId;
        data.Response = queryReply;
        data.ResponseDate = exactDate;
        data.Id = editQueryResp?.Id

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/ResponseToQuery`

        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    getQueryResponse( editQueryResp?.QueryId);
                    setQueryReply('');
                    setEditQueryResp([])
                    getQueries();
                    toast.success(e?.data?.message);
                }
            })
            .catch((err) => {
                toast.error(err?.data?.messsage);
            });
    }
    const getAdminRole = GetRoleName();
    return (
        <>
            <div className="page-container">
                {
                    loading ? <Loader /> : null
                }


                <Headers innerPages={"innerPages"} />
                <section className="bredcrum-ps">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active add-mr-right" aria-current="page"><a href="/#/queries">Your Queries</a></li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className={`quries-clrfix ${ActiveData.length >= 2 ? "second-queries-clrfix" : "new-queries-clrfix"}`}>
                    <div className="container new-responsive-padding">

                        <div className="row">
                            <div className="col-md-9">
                                <div className="col-12">
                                    <div className="mb-3 page-title-queries">
                                        {
                                            title ? <div className="maxPageTitle">{maxPageTitle - title.length} characters left</div> : <div className="maxPageTitle">40 characters left</div>
                                        }
                                        <input type="text" autoComplete="off" className="form-control wt-clrfix" id="exampleFormControlInput1" maxLength={40} placeholder="Query Title..." onChange={e => setTitle(e?.target?.value)} value={title}></input>
                                    </div>
                                    <div className="main-section-editor">
                                        <div className="editor add-mb">
                                            <CKEditor className="editor-main"
                                                editor={ClassicEditor}
                                                config={{
                                                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                                }}
                                                data={queryText}
                                                onChange={(event, editor) => setQueryText(editor?.getData())}

                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-2 col-sm-4 align-itm">
                                            <div className="btn-radio">
                                                <div className="form-check btn-radio-box">
                                                    <Form.Check
                                                        inline
                                                        label="Public"
                                                        name="group1"
                                                        type={'radio'}
                                                        id={`inline-${'radio'}-1`}
                                                        onChange={() => setIsPublic("public")} value={isPublic}
                                                        checked={isPublic === "public"}
                                                    />
                                                </div>
                                                <div className="form-check anonymous-radio-btn">
                                                    <Form.Check
                                                        inline
                                                        label="Private"
                                                        name="group1"
                                                        type={'radio'}
                                                        id={`inline-${'radio'}-2`}
                                                        onChange={() => setIsPublic("private")} value={isPublic}
                                                        checked={isPublic === "private"}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-12 col-md-2 col-sm-4 align-itm">
                                            <div className="btn-radio">
                                                <div className="form-check btn-radio-box">
                                                    <Form.Check
                                                        inline
                                                        label="Name"
                                                        name="group2"
                                                        type={'radio'}
                                                        id={`inline-${'radio'}-3`}
                                                        onChange={() => setAnonymous("name")} value={anonymous}
                                                        checked={anonymous === "name"}

                                                    />

                                                </div>

                                                <div className="form-check anonymous-radio-btn">
                                                    <Form.Check
                                                        inline
                                                        label="Anonymous"
                                                        name="group2"
                                                        type={'radio'}
                                                        id={`inline-${'radio'}-4`}
                                                        onChange={() => setAnonymous("anonymous")} value={anonymous}
                                                        checked={anonymous === "anonymous"}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-8 col-sm-12 update-btn-flex">
                                            {
                                                editQueryData?.Id || AnotherPageEditData?.Id ? <div style={{ position: 'relative' }}>
                                                    <button type="button" className="btn btn-primary btn-submit" onClick={() => updateQuery()}>Update</button>
                                                </div> : <div style={{ position: 'relative' }}>
                                                    <button type="button" className="btn btn-primary btn-submit new-btn-full-width" onClick={Submit}>Submit</button>
                                                </div>
                                            }
                                            <button type="button" className="btn btn-primary draft-query" onClick={() => saveAsDraft()}>Save As Draft</button>
                                            {
                                                editQueryData?.Id || AnotherPageEditData?.Id ? <button type="button" className="btn btn-primary cancel-button-edit-time" onClick={() => CancelButton()}>Cancel</button> : null
                                            }

                                        </div>


                                    </div>


                                    <div className="row mt-3">
                                        <div className="slct-name">

                                            <div className="row">
                                                {
                                                    cardData?.map((item, index) => {
                                                        return (
                                                            <div key={index} className="col-md-3 mb-40 card-tab-responsive">
                                                                <li className="user-queries-preview">
                                                                    <a>
                                                                        <span className="selected-user-span"> <img className="selected_user_img" src={`${Getimage(item?.ProfilePhotoUrl || item?.Profile?.[0]?.ProfilePhotoUrl, "images/dummy.svg")}`} onError={(e) => { e.target.src = "images/dummy.svg" }} alt="user_image" /></span>
                                                                        {item?.ProfileName || item?.Profile[0]?.ProfileName}
                                                                        <span className="users-add-preview"
                                                                            onClick={() => onRemove(item)}><ImCross className="cross-icon-service-queries" /></span>
                                                                        <div className="selected-user-fee">

                                                                            {
                                                                                item?.UserId === 27 ? <> <span className="fee-points-query"></span><span>{item?.ConsultationFeePoint}</span> </> : ""
                                                                            }

                                                                        </div>
                                                                    </a>

                                                                </li>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-3 mobile-sidebar-view">
                                    <div className="new-sticky-sidebar">
                                        <Tabs
                                            defaultActiveKey="Gold"
                                            id="uncontrolled-tab-example"
                                            className="members-tab-gold"
                                        >
                                            <Tab eventKey="Gold" title="Gold Members" className="tab-gold-active">
                                                <GoldMember profilelistUser={profilelistUser} searchUser={searchUser} search={search} setSearch={setSearch} onCardClick={(e) => {
                                                    if (cardData?.length >= 5) {
                                                        toast.error("You Can Only Share with up to 5 Users")
                                                        return
                                                    }
                                                    const findItem = cardData?.find(item => item?.UserId === e?.UserId);

                                                    if (findItem) {
                                                        toast.error("User Already Selected");
                                                    }
                                                    else {
                                                        setCardData([...cardData, e])

                                                    }
                                                }
                                                } />
                                            </Tab>
                                            <Tab eventKey="Other" title="Other Members" className="tab-others-active">
                                                <MemberSidebar searchUser={searchUser} onCardClick={(e) => {
                                                    if (cardData?.length >= 5) {
                                                        toast.error("You Can Only Share with up to 5 Users")
                                                        return
                                                    }
                                                    const findItem = cardData?.find(item => item?.UserId === e?.UserId);

                                                    if (findItem) {
                                                        toast.error("User Already Selected");
                                                    }
                                                    else {
                                                        setCardData([...cardData, e])
                                                    }

                                                }
                                                }
                                                />
                                            </Tab>

                                        </Tabs>

                                    </div>

                                </div>
                                <div className="col-12 new-get-query-div">
                                    <div>

                                        {
                                            getQuery?.map((item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {
                                                            item?.Active && item?.SaveAsDraft === true && profilelist?.Profile?.UserId === item?.OwnerId ?

                                                                <div key={index} data-aos="flip-down" data-aos-easing="ease-in-sine" data-aos-duration="400" className="pd-on-mob">
                                                                    <div className="row">
                                                                        <div className="ps-name-box">
                                                                            <span className="draft-badge">DRAFT</span>
                                                                            <img src={item?.Anonymous ? "images/anonymoususer.png" : `${Getimage(item?.ProfilePhotoUrl, "images/dummy.svg")}`} alt="User Img." className="image-main-cards-dshbord" />
                                                                            <div className="title-text new-title-text">
                                                                                <div>
                                                                                    <h1>{item?.ProfileName === "" ? "Anonymous" : item?.ProfileName} - {item?.Title}</h1>
                                                                                    {
                                                                                        item.Private ? <img src="images/lock-in-a-circle.png" className={`anonymous-img-queries ${item?.Anonymous ? "new-private-img-queries" : "second-anonymous"}`} tabIndex="0" data-toggle="tooltip" title="Private Query" /> : null
                                                                                    }
                                                                                    {
                                                                                        item?.Anonymous ? <img src="images/anonymous.jpg" className="anonymous-img-queries" tabIndex="0" data-toggle="tooltip" title="Anonymous User" /> : null
                                                                                    }
                                                                                    {
                                                                                        profilelist?.Profile?.UserId === item?.OwnerId && item?.ReplyCount < 1 ? <AiFillEdit className="edit-icon-query" onClick={() => handleEditQuery(item)} tabIndex="0" data-toggle="tooltip" title="Edit Query" /> : null
                                                                                    }

                                                                                    {
                                                                                        profilelist?.Profile?.UserId === item?.OwnerId && item?.ReplyCount < 1 ? <AiFillDelete className="delete-icon-query-final" onClick={() => DeleteMainThread(item)} tabIndex="0" data-toggle="tooltip" title="Delete Query" /> : null
                                                                                    }

                                                                                </div>
                                                                                <div className="query-text-figure" dangerouslySetInnerHTML={{ __html: item?.QueryText }}></div>
                                                                                <div className="new-like-comment">
                                                                                    <span className="undrline-txt">{item?.DateCreated === null ? null : <> <span className='published-date'>Published On:</span>{moment(item?.DateCreated)?.format('Do MMM YYYY, h:mm A')} </>}</span>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                item?.QueryTo?.length ?
                                                                                    <div className="outer-section-participants">

                                                                                        <OverlayTrigger
                                                                                            placement="right"
                                                                                            delay={{ show: 250, hide: 450 }}
                                                                                            overlay={
                                                                                                <Tooltip id="button-tooltip" className="new-query-tooltip">
                                                                                                    <div className="new-participant-section">
                                                                                                        <div className="participants-section">
                                                                                                            {
                                                                                                                item?.QueryTo?.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <div key={index} className="participants-inner-section">
                                                                                                                            <img src={item?.ProfilePhotoUrl === '' ? "images/anonymoususer.png" : `${Getimage(item?.ProfilePhotoUrl, "images/dummy.svg")}`} alt="image" className="participants-image" />
                                                                                                                            <div className="information_section_participants">
                                                                                                                                <p>{item?.ProfileName}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <h3>Participants

                                                                                                <div>
                                                                                                    <span>{item?.QueryTo?.length}</span>
                                                                                                </div>
                                                                                            </h3>

                                                                                        </OverlayTrigger>


                                                                                    </div> : null
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </Fragment>


                                                )
                                            })
                                        }
                                    </div>

                                </div>
                                <div className="col-12 new-get-query-div">
                                    <div>

                                        {
                                            getQuery?.map((item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {
                                                            item?.Active && item?.SaveAsDraft === false ?

                                                                <div key={index} data-aos="flip-down" data-aos-easing="ease-in-sine" data-aos-duration="400" className="pd-on-mob">
                                                                    <div className="row">
                                                                        <div className="ps-name-box">
                                                                            <img src={item?.Anonymous ? "images/anonymoususer.png" : `${Getimage(item?.ProfilePhotoUrl, "images/dummy.svg")}`} alt="User Img." className="image-main-cards-dshbord" />
                                                                            <div className="title-text new-title-text">
                                                                                <div>
                                                                                    <h1>{item?.ProfileName === "" ? "Anonymous" : item?.ProfileName} - {item?.Title}</h1>
                                                                                    {
                                                                                        item.Private ? <img src="images/lock-in-a-circle.png" className={`anonymous-img-queries ${item?.Anonymous ? "new-private-img-queries" : "second-anonymous"}`} tabIndex="0" data-toggle="tooltip" title="Private Query" /> : null
                                                                                    }
                                                                                    {
                                                                                        item?.Anonymous ? <img src="images/anonymous.jpg" className="anonymous-img-queries" tabIndex="0" data-toggle="tooltip" title="Anonymous User" /> : null
                                                                                    }
                                                                                    {
                                                                                        getAdminRole === "Admin" ?
                                                                                            <>
                                                                                                {/* Query Delete Section */}
                                                                                                <div className={`${item?.Private && item?.Anonymous ? "delete-icon-admin-inner" : "delete-icon-query-outer"}`}><RiDeleteBin6Line onClick={() => DeleteMainThread(item)} className="delete-icon-article" /></div>
                                                                                            </> : null
                                                                                    }
                                                                                    {
                                                                                        profilelist?.Profile?.UserId === item?.OwnerId && item?.ReplyCount < 1 ? <AiFillEdit className="edit-icon-query" onClick={() => handleEditQuery(item)} tabIndex="0" data-toggle="tooltip" title="Edit Query" /> : null
                                                                                    }

                                                                                    {
                                                                                        profilelist?.Profile?.UserId === item?.OwnerId && item?.ReplyCount < 1 ? <AiFillDelete className="delete-icon-query-final" onClick={() => DeleteMainThread(item)} tabIndex="0" data-toggle="tooltip" title="Delete Query" /> : null
                                                                                    }

                                                                                </div>
                                                                                <div className="query-text-figure" dangerouslySetInnerHTML={{ __html: item?.QueryText }}></div>
                                                                                <div className="new-like-comment">
                                                                                    <a className="like-btn active" id="aa" likeit={`${!item?.IsLiked}`} onClick={(e) => { LikeSubmit(e, item?.Id) }} >
                                                                                        {
                                                                                            (item?.IsLiked) ? <AiFillLike className="icons-queries" /> : <AiOutlineLike className="icons-queries" />
                                                                                        }
                                                                                    </a>

                                                                                    {
                                                                                        item?.SpamByUsers?.find((l) => l?.SpamByUserId === profilelist?.Profile?.UserId) ?
                                                                                            <button className="spam-icon button-spam add-bottom-position" onClick={() => ThreadUnspam(item)}>Mark UnSpam</button> :
                                                                                            profilelist?.Profile?.UserId === item?.OwnerId ? null :
                                                                                                item?.Anonymous && item?.QueryTo?.find((l) => l?.UserId === profilelist?.Profile?.UserId) ? <button className="spam-icon button-spam add-bottom-position" onClick={() => QueryThreadSpam(item)}>Mark As Spam</button>
                                                                                                    : null
                                                                                    }

                                                                                    <span id="qLikeCount" className="like-count">{item?.LikesTotal}</span>
                                                                                    <a className="like-btn comment-icon" onClick={() => { setShow(show !== item?.Id ? item?.Id : null); setReplyId(item?.Id); getQueryResponse(item?.Id); setQueryResponseId(item?.Id) }}><GoCommentDiscussion className="icons-queries" />{item?.ReplyCount}</a>
                                                                                    <span className="undrline-txt">{item?.DateCreated === null ? null : <> <span className='published-date'>Published On:</span> {moment(item?.DateCreated)?.format('Do MMM YYYY, h:mm A')} </>}</span>
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                item?.QueryTo?.length ?
                                                                                    <div className="outer-section-participants">

                                                                                        <OverlayTrigger
                                                                                            placement="right"
                                                                                            delay={{ show: 250, hide: 450 }}
                                                                                            overlay={
                                                                                                <Tooltip id="button-tooltip" className="new-query-tooltip">
                                                                                                    <div className="new-participant-section">
                                                                                                        <div className="participants-section">
                                                                                                            {
                                                                                                                item?.QueryTo?.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <div key={index} className="participants-inner-section">
                                                                                                                            <img src={item?.ProfilePhotoUrl === '' ? "images/anonymoususer.png" : `${Getimage(item?.ProfilePhotoUrl, "images/dummy.svg")}`} alt="image" className="participants-image" />
                                                                                                                            <div className="information_section_participants">
                                                                                                                                <p>{item?.ProfileName}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <h3>Participants

                                                                                                <div>
                                                                                                    <span>{item?.QueryTo?.length}</span>
                                                                                                </div>
                                                                                            </h3>

                                                                                        </OverlayTrigger>


                                                                                    </div> : null
                                                                            }
                                                                            {(show && replyId === item?.Id) ?

                                                                                <div className="ps-name-box add-position-reply-queries">
                                                                                    {
                                                                                        queriesList?.map((qResp, index) => {
                                                                                            return (
                                                                                                <Fragment key={index} >
                                                                                                    {
                                                                                                        qResp?.IsDelete !== true ?
                                                                                                            <div className="queries-reply-section-outer">
                                                                                                                <div className="query-response-box">
                                                                                                                    <img src={item?.OwnerId === qResp?.ReplyById && item?.Anonymous ? "images/anonymoususer.png" : `${Getimage(qResp?.ReplyByUserPhotoUrl, "images/dummy.svg")}`} alt="User Img." className="image-main-cards-dshbord" />
                                                                                                                    <div className="title-text new-title-text">
                                                                                                                        <div className="spam-mark-icon">
                                                                                                                            <h1>{qResp?.ReplyByUserName === "" ? "Anonymous" : qResp?.ReplyByUserName}</h1>

                                                                                                                            {
                                                                                                                                qResp?.SpamByUser?.find((l) => l?.SpamByUserId === profilelist?.Profile?.UserId) ? <button className="spam-icon respo-button-mob-spam" onClick={() => unSpamResponse(qResp)}>Mark UnSpam</button>
                                                                                                                                    : qResp?.ReplyById === profilelist?.Profile?.UserId ? null
                                                                                                                                        : item?.Anonymous && qResp?.ReplyById === item?.OwnerId ? <button className="spam-icon respo-button-mob-spam" onClick={() => spamMark(qResp)}>Mark As Spam</button> : null
                                                                                                                            }

                                                                                                                        </div>
                                                                                                                        <p dangerouslySetInnerHTML={{ __html: qResp?.Response }}></p>
                                                                                                                        <div className="new-like-comment">
                                                                                                                            <a className="like-btn active" id="aaa" likeit={`${!qResp?.IsLike}`} onClick={(e) => { LikeQueryResponse(e, qResp) }} >
                                                                                                                                {
                                                                                                                                    qResp?.IsLike ? <AiFillLike className="icons-queries icon-qresp-respon" />
                                                                                                                                        :
                                                                                                                                        <AiOutlineLike className="icons-queries icon-qresp-respon" />
                                                                                                                                }
                                                                                                                            </a>

                                                                                                                            <span id="qLikeCount" className="like-count like-qresp-count-respon">{qResp?.LikeCount}</span>
                                                                                                                            <span className="undrline-txt float-design">{qResp?.ResponseDate === null ? null : <><span className='published-date'>Published On:</span>{moment(qResp?.ResponseDate).format('Do MMM YYYY, h:mm A')}</>}</span>
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            profilelist?.Profile?.UserId === qResp?.ReplyById ? <AiFillEdit className="edit-icon-query-response" onClick={() => handleEditQueryResponse(qResp)} tabIndex="0" data-toggle="tooltip" title="Edit Query" /> : null
                                                                                                                        }

                                                                                                                        {
                                                                                                                            profilelist?.Profile?.UserId === qResp?.ReplyById ? <AiFillDelete id="dlt" className="delete-icon-modal-query" onClick={() => DeleteModal(qResp)} />
                                                                                                                                : null
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="separator-query-resp"></div>

                                                                                                            </div>
                                                                                                            : null
                                                                                                    }
                                                                                                </Fragment>

                                                                                            );
                                                                                        })
                                                                                    }

                                                                                    <div className="input-grp-fil mb-3 query-reply-box">
                                                                                        <CKEditor className="editor-main"
                                                                                            editor={ClassicEditor}
                                                                                            config={{
                                                                                                removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                                                                            }}
                                                                                            data={queryReply}
                                                                                            onChange={(event, editor) => setQueryReply(editor?.getData())}

                                                                                        />
                                                                                        {
                                                                                            editQueryResp?.Id ? <span className="input-group-text" id="basic-addon2" onClick={(e) => EditReplyToQuery(item?.Id, e)}>Update</span>: <span className="input-group-text" id="basic-addon2" onClick={(e) => ReplyToQuery(item?.Id, e)}>Reply</span>
                                                                                        }
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </Fragment>


                                                )
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="col-6 col-sm-3 col-md-3 mobile-sidebar-not-view">
                                <div className="new-sticky-sidebar">
                                    <Tabs
                                        defaultActiveKey="Gold"
                                        id="uncontrolled-tab-example"
                                        className="members-tab-gold"
                                    >
                                        <Tab eventKey="Gold" title="Gold Members" className="tab-gold-active">
                                            <GoldMember profilelistUser={profilelistUser} searchUser={searchUser} search={search} setSearch={setSearch} onCardClick={(e) => {
                                                if (cardData?.length >= 5) {
                                                    toast.error("You Can Only Share with up to 5 Users")
                                                    return
                                                }
                                                const findItem = cardData?.find(item => item?.UserId === e?.UserId);

                                                if (findItem) {
                                                    toast.error("User Already Selected");
                                                }
                                                else {
                                                    setCardData([...cardData, e])

                                                }
                                            }
                                            } />
                                        </Tab>
                                        <Tab eventKey="Other" title="Other Members" className="tab-others-active">
                                            <MemberSidebar onCardClick={(e) => {
                                                if (cardData?.length >= 5) {
                                                    toast.error("You Can Only Share with up to 5 Users")
                                                    return
                                                }
                                                const findItem = cardData?.find(item => item?.UserId === e?.UserId);

                                                if (findItem) {
                                                    toast.error("User Already Selected");
                                                }
                                                else {
                                                    setCardData([...cardData, e])

                                                }
                                            }
                                            } />
                                        </Tab>

                                    </Tabs>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>

                <Footers />

                <Modal show={showSpam} onHide={handlespamClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handlespamClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading "><span>Why</span> Spam?</h1>
                                        <textarea className="form-control txtReply spam-response-box" rows="4" cols="50" placeholder="Enter Spam Reason...." onChange={(e) => setSpamReason(e?.target?.value)} value={spamReason}></textarea>
                                        <div className="spam-button-end">
                                            <button className="spam_submit_button" onClick={() => markSpamSubmit()}>Submit</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal show={spamMainThreadShow} onHide={handleQuerySpamClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleQuerySpamClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading "><span>Why</span> Spam?</h1>
                                        <textarea className="form-control txtReply spam-response-box" rows="4" cols="50" placeholder="Enter Spam Reason...." onChange={(e) => setThreadSpamReason(e?.target?.value)} value={threadSpamReason}></textarea>
                                        <div className="spam-button-end">
                                            <button className="spam_submit_button" onClick={() => MainQuerySpam()}>Submit</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>

                {/* delete query response modal */}
                <Modal show={deleteQueryModal} onHide={handleDeleteClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleDeleteClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading delete-modal-heading"><span>Are</span> you sure want to delete this Query?</h1>
                                        <div className="why-spam-btn">
                                            <button className="btn btn-primary button-delete-modal" onClick={() => DeleteUserQuery()}>
                                                Yes
                                            </button>

                                            <button className="btn btn-danger button-delete-modal-No" onClick={() => handleDeleteClose()}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>
                {/* delete query modal */}
                <Modal show={deleteMainQueryModal} onHide={handleMainDeleteClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleMainDeleteClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading delete-modal-heading"><span>Are</span> you sure want to delete this Query?</h1>
                                        <div className="why-spam-btn">
                                            <button className="btn btn-primary button-delete-modal" onClick={() => DeleteQuery()}>
                                                Yes
                                            </button>

                                            <button className="btn btn-danger button-delete-modal-No" onClick={() => handleMainDeleteClose()}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>



            </div>

        </>
    );
}

export default Quaries;
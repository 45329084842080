import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import GoogleLogin from "react-google-login";
import Popup from "../popup/signinpopup/popup";
import { ApiUrl, getToken, setUserSession, removeUserSession, Getimage, GetRoleName } from "../../utils/common";
import { toast } from "react-toastify";
import Loader from "../Loader/loader";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { CgProfile } from 'react-icons/cg';
import { RiArticleLine, RiQuestionAnswerLine } from 'react-icons/ri';
import { AiOutlinePoweroff } from "react-icons/ai";


const Headers = ({ onSearch, search, onSearchClick, profile, innerPages, serviceData, getMainProfiles, DiscussionPath, articlePath, ArticleViewPath, UserQueryPath }) => {
    const [buttonPopup, setButtonPopup] = useState(false);
    const [loading, setLoading] = useState(false)
    const [profileData, setProfileData] = useState();
    const [activeUser, setActiveUser] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [requestEmail, setRequestEmail] = useState('');
    const [requestComment, setRequestComment] = useState('');
    const [messageLength, setMessageLength] = useState(180);
    var date = new Date()
    var exactDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    // api google login
    const navigate = useNavigate();
    const validate = () => {
        if (!requestEmail || requestEmail.trim() === "") {
            toast.error("Please Enter Registered Email")
            return false;
        }

        if (!requestComment || requestComment.trim() === "") {
            toast.error("Please Enter Your Message")
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (serviceData) {
            onSearch(serviceData);
        }
    }, [serviceData])

    const responseGoogle = (response) => {
        const Data = {}//new URLSearchParams();
        Data.tokenId = response.tokenId;

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: { Data: Data },
            url: `${ApiUrl()}/Account/auth/google`
        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    setUserSession(e?.data?.data);
                    const decoded = jwt_decode(e?.data?.data);
                    if (decoded?.role === 'Admin') {
                        toast.success("Admin has been successfully login");
                    } else {
                        toast.success("User has been successfully login");
                    }
                    navigate('/profile')
                }
            })
            .catch((err) => {
                if (err?.response?.data?.message === response?.profileObj?.email + " is disable or inactive") {
                    setRequestEmail(err?.response?.data?.message.split(" is")[0]);
                    setButtonPopup(false);
                    setTimeout(() => {
                        setActiveUser(true);
                    }, 1000);
                }
            });

    }

    const userToken = getToken();

    // profile api
    const getProfiles = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Profile`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                const data = JSON.parse(e?.data?.data)
                setProfileData(data?.Profile?.[0])

            })
            .catch((err) => {
                setLoading(false)
                if (err?.request?.status === 401) {
                    removeUserSession();
                    navigate('/')
                }
            });
    }

    useEffect(() => {
        if (userToken?.length) {
            getProfiles()
        }
    }, [userToken])

    const LogOut = () => {
        removeUserSession();
        navigate('/');
        window?.location?.reload();
    }

    const submitErrorMsg = () => {
        if (!validate()) return;
        const data = {};
        data.email = requestEmail;
        data.Comment = requestComment;
        data.RequestDate = exactDate;

        const option = {
            method: 'PATCH', headers: {
                'content-type': 'application/json',
            },
            data: { "data": JSON.stringify(data) },
            url: `${ApiUrl()}/User/AccountActivateRequest`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    setActiveUser(false);
                    setShowInput(false);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
    }
    const AdminRoleName = GetRoleName();

    return (
        <>
            {
                loading ? <Loader /> : null
            }
            <section className={innerPages ? "" : "header-bg"}>


                <Navbar expand="lg" className="header-sec padding-20-header">
                    <Container>
                        <Navbar.Brand href="/" className="hdr-logo logo-section-header" >
                            <img src="images/logo.png" alt="PrivateSqureLogo" className="logo-ps" />
                        </Navbar.Brand>
                        <Nav className="me-auto navbar-item-con">
                            <>
                                {
                                    userToken ?
                                        <>
                                            {
                                                AdminRoleName === 'Admin' ? <NavLink className={({ isActive }) => (isActive ? 'new-class-active' : 'discussion-link-header')} to="/polling">Create Poll</NavLink> : null
                                            }
                                            {
                                                AdminRoleName === 'Admin' ? <NavLink className={({ isActive }) => (isActive ? 'new-class-active' : 'discussion-link-header')} to="/listpolling">Poll Listing</NavLink> : null
                                            }
                                            {
                                                AdminRoleName === 'Admin' ? <NavLink className={({ isActive }) => (isActive ? 'new-class-active' : 'discussion-link-header')} to="/blockedUsers">Blocked Users</NavLink> : null
                                            }
                                            {
                                                AdminRoleName === 'Admin' ? <NavLink className={({ isActive }) => (isActive ? 'new-class-active' : 'discussion-link-header')} to="/spamqueries">Spam Queries</NavLink> : null
                                            }
                                            <NavLink className={({ isActive }) => (isActive ? 'new-class-active' : 'discussion-link-header')} to="/publicDiscussion">Discussion</NavLink>
                                            <NavLink className={({ isActive }) => (isActive ? 'new-class-active' : 'discussion-link-header')} to="/Articles">Articles</NavLink>
                                            <NavDropdown className="image-dropdown-links" title={<span><img src={`${Getimage(profileData?.ProfilePhotoUrl)}`} className="dropdown-image-icon" /></span>} id="basic-nav-dropdown">
                                                <NavLink className={({ isActive }) => (isActive ? 'new-dropdown-active' : 'new-dropdown-inactive')} to="/profile"><span className="dropdown-link-icon"><CgProfile /></span>Profile</NavLink>
                                                <NavLink className={({ isActive }) => (isActive ? 'new-dropdown-active' : 'new-dropdown-inactive')} to="/userquery"><span className="dropdown-link-icon"><RiQuestionAnswerLine /></span>My Queries</NavLink>
                                                <NavLink className={({ isActive }) => (isActive ? 'new-dropdown-active discussion-link-view' : 'new-dropdown-inactive discussion-link-view')} to="/publicDiscussion">Discussion</NavLink>
                                                <NavLink className={({ isActive }) => (isActive ? 'new-dropdown-active discussion-link-view' : 'new-dropdown-inactive discussion-link-view')} to="/Articles">Articles</NavLink>
                                                <NavLink className={({ isActive }) => (isActive ? 'new-dropdown-active' : 'new-dropdown-inactive')} to="/articlepost"><span className="dropdown-link-icon"><RiArticleLine /></span>My Articles</NavLink>
                                                {
                                                    AdminRoleName === 'Admin' ? <NavLink className={({ isActive }) => (isActive ? 'new-dropdown-active discussion-link-view' : 'new-dropdown-inactive discussion-link-view')} to="/spamqueries">Spam Queries</NavLink> : null
                                                }
                                                {
                                                    AdminRoleName === 'Admin' ? <NavLink className={({ isActive }) => (isActive ? 'new-dropdown-active discussion-link-view' : 'new-dropdown-inactive discussion-link-view')} to="/blockedUsers">Blocked Users</NavLink> : null
                                                }
                                                {
                                                    AdminRoleName === 'Admin' ? <NavLink className={({ isActive }) => (isActive ? 'new-dropdown-active discussion-link-view' : 'new-dropdown-inactive discussion-link-view')} to="/polling">Poll Questions</NavLink> : null
                                                }
                                                {
                                                    AdminRoleName === 'Admin' ? <NavLink className={({ isActive }) => (isActive ? 'new-dropdown-active discussion-link-view' : 'new-dropdown-inactive discussion-link-view')} to="/listpolling">Poll Listing</NavLink> : null
                                                }
                                                <NavLink className={'new-dropdown-inactive'} to="/" onClick={() => LogOut()}><span className="dropdown-link-icon"><AiOutlinePoweroff /></span>Logout</NavLink>
                                            </NavDropdown>
                                        </>
                                        :
                                        <button className="new-resp-login btn-log login-button-header" onClick={() => setButtonPopup(true)}>Login</button>
                                }
                            </>
                        </Nav>
                    </Container>
                </Navbar>
                {/* header end  */}

            </section>
            {/* main section */}
            
            {/* popup section */}
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} showactivebutton={true} class="login-popup">
                <h2 className="heading-inner-modal">sign in with <span>google</span></h2>

                {/* arrow animation */}
                <div className="main-section-animation-arrow">
                    <div className="arrow-container">
                        <div className="arrow-down"></div>
                    </div>
                </div>

                <div className="section-before-google-login">
                    <GoogleLogin className="google-login-button"
                        clientId="561093089554-apbopeknedv51u8ifn13vekm0cc4o51k.apps.googleusercontent.com"
                        buttonText="Login with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle} />
                </div>
            </Popup>


            <Popup trigger={activeUser} setTrigger={setActiveUser} showactivebutton={true} setShowInput={setShowInput} class="login-popup">
                {
                    showInput ? <>
                        <h1 className="why-spam-heading ">You are blocked by <span>Admin</span></h1>
                        <div className="row login-error ">
                            <div className="col-md-8 p-3 login-error-container">
                                <label>Email</label>
                                <input type="text" placeholder="Enter Registered Email" className="input-error-login" value={requestEmail} disable />
                            </div>
                            <div className="col-md-8 p-3 login-error-container">
                                <label>Message</label>
                                <textarea className="form-control txtReply spam-response-box" rows="4" maxLength={180} placeholder="Enter your Message..." onChange={(e) => setRequestComment(e?.target?.value)} value={requestComment}></textarea>
                                {
                                    requestComment ? <div className="maxRequestLength">{messageLength - requestComment.length} characters left</div> : <div className="maxRequestLength">180 characters left</div>
                                }
                            </div>
                        </div>
                        <div className="login-error" >
                            <a onClick={() => submitErrorMsg()}>
                                Submit
                            </a>
                        </div>
                    </> : <>
                        <h1 className="why-spam-heading pt-5">You are blocked by <span>Admin</span></h1>
                        <div className="login-error pb-5">
                            <a onClick={() => { setShowInput(true) }}>
                                Contact Admin
                            </a>
                        </div>
                    </>
                }


            </Popup>

        </>
    )
}
export default Headers;

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Form, Modal, Tab, Tabs } from "react-bootstrap";
import { AiFillDelete, AiFillEdit, AiFillLike, AiOutlineLike } from 'react-icons/ai';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footers from '../../components/Footers/Footers';
import GoldMember from '../../components/goldMember/goldmember';
import MemberSidebar from '../../components/goldmembersidebar/goldmember';
import Headers from '../../components/header/header';
import Loader from '../../components/Loader/loader';
import { ApiHost, ApiUrl, Getimage, Getsaltyeml, getToken } from "../../utils/common";
import Aos from 'aos';
import moment from 'moment';
import { GoCommentDiscussion } from 'react-icons/go';
import imageCompression from 'browser-image-compression';
import { MdCloudUpload } from 'react-icons/md';

const Post = () => {
    const articlePath = window?.location?.hash;
    const [loading, setLoading] = useState(false);
    const [profilelistUser, setProfilelistUser] = useState([]);
    const [userData, setUserData] = useState('');
    const [search, setSearch] = useState();
    const [isPublish, setIsPublish] = useState("yes");
    const location = useLocation();
    const selectedData = location?.state?.data
    const findItemData = profilelistUser?.find((i) => i?.Id === selectedData?.Id)
    const [cardData, setCardData] = useState([]);
    const [Profileimage, setProfileImg] = useState('');
    const [userName, setUserName] = useState('');
    const [userDesignation, setUserDesignation] = useState('');
    const navigate = useNavigate();
    const [profilelist, setProfilelist] = useState([]);
    const [postQuery, setPostQuery] = useState("");
    const [postTitle, setPostTitle] = useState("");
    const [maxPageTitle, setMaxPageTitle] = useState(100);
    const [articles, setArticles] = useState([]);
    const [articleComment, setArticleComment] = useState('');
    const [show, setShow] = useState()
    const [replyId, setReplyId] = useState(0);
    const [commentReply, setCommentReply] = useState([]);
    const [articleEditStore, setArticleEditStore] = useState('');
    const [deletePostModal, setDeletePostModal] = useState(false);
    const handleMainDeleteClose = () => setDeletePostModal(false);
    const [deletePostData, setDeletePostData] = useState('');
    const [editArticleHandleData, setEditArticleHandleData] = useState('');
    const [imgfile, setImageFile] = useState([]);
    const [articleimage, setArticleimage] = useState("");
    const [photoExtension, setPhotoExtension] = useState('');
    const [searchUser, setSearchUser] = useState(false);
    var date = new Date()
    var exactDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

    const ImgFileHandler = async (event) => {
        const imageFile = event.target.files[0];
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1500,
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            setArticleimage(URL.createObjectURL(compressedFile), 'image url');
            const base64 = await imageCompression.getDataUrlFromFile(imageFile, options);
            const base = base64.split(",")[1];
            setImageFile(base);
            setPhotoExtension(event?.target?.files[0].type.replace("image/", ""));
        } catch (error) {

        }
    }

    // editor state
    const eml = Getsaltyeml();
    const getMainProfiles = () => {
        const data = (search != null) ? search : "";
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "data": data },
            url: `${ApiUrl()}/User`

        };
        axios(option)
            .then(e => {
                const data = JSON.parse(e?.data?.data || '{}');
                setProfilelistUser(
                    data.filter(function (n, i) {
                        return n.UserName !== eml
                    }
                    ));
                setUserData();
                setSearchUser(true);

            })
            .catch((err) => {
                setSearchUser(false);
            });
    }

    const getProfiles = () => {
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Profile`
        };
        axios(option)
            .then(e => {
                var pro = {};
                pro.Profile = JSON.parse(e?.data?.data).Profile?.[0];
                pro.FullName = GetFullName(pro.Profile);

                setProfilelist(pro);
                setUserDesignation(pro?.Profile?.Designation);
                setUserName(pro?.Profile?.ProfileName);
                setProfileImg(`${ApiHost()}` + pro?.Profile?.ProfilePhotoUrl);
            })
            .catch((err) => {
                navigate('/');
            });
    }

    const GetFullName = (d) => {
        var name = d.FirstName || "";
        if (d.MiddleName != null) name += (" " + d.MiddleName);
        if (d.LastName != null) name += (" " + d.LastName || "");
        return name;

    }

    useEffect(() => {
        getProfiles();
    }, [])
    useEffect(() => {
        getMainProfiles();
    }, [search])


    const SubmitPost = () => {
        if (!ValidateFields()) return;
        setLoading(true);
        const data = {}
        data.Title = postTitle;
        data.Content = postQuery;
        data.Publish = isPublish === "yes";
        data.IsPrivate = false;
        data.DateCreated = exactDate;
        data.ArticleImage = imgfile;
        data.Extenssion = photoExtension;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Article/AddArticle`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                toast.success(e?.data?.message);
                setPostTitle('');
                setPostQuery('');
                setIsPublish('yes');
                setImageFile([]);
                setArticleimage('');
                getArticles();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
                setLoading(false);
            });
    }
    const getArticles = () => {
        setLoading(true)
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Article/GetUserArticles`
        };

        axios(option)
            .then(e => {

                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                setArticles(data);
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getArticles();
    }, [])

    const ValidateFields = () => {
        if (!postTitle || postTitle.trim() === "") {
            toast.error("Please Enter Post Title");
            return false;
        }

        if (!postQuery || postQuery.trim() === "") {
            toast.error("Please Enter Your Article Content");
            return false;
        }
        if (imgfile?.length === 0) {
            toast.error("Please Add Article Image");
            return false;
        }

        return true;
    }

    const UpdateValidateFields = () => {
        if (!postTitle || postTitle.trim() === "") {
            toast.error("Please Enter Post Title");
            return false;
        }

        if (!postQuery || postQuery.trim() === "") {
            toast.error("Please Enter Your Article Content");
            return false;
        }

        return true;
    }

    const LikeArticle = (el, Id) => {
        setLoading(true)
        const data = {};
        data.ArticleId = Id;
        data.LikeOnDate = exactDate;
        var flag = (el.currentTarget.attributes['likeit'].value === 'true');
        data.IsUnlike = !flag;

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Article/LikeArticle`

        };
        axios(option)
            .then(e => {

                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                if (e?.data?.success) {
                    getArticles();
                }

            })
            .catch((err) => {

                toast.error(err?.response?.data?.message);
                setLoading(false)
            });
    }
    const ArticleCommentValidate = () => {
        if (!articleComment || articleComment.trim() === "") {
            toast.error("Please Enter your comment")
            return false;
        }
        return true;
    }


    const ReplyToArticle = (Id, el) => {
        if (!ArticleCommentValidate()) return;
        setLoading(true);
        const data = {};
        data.ArticleId = Id;
        data.Comment = articleComment;
        data.CommentDate = exactDate;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Article/CommentOnArticle`

        };
        axios(option)
            .then(e => {
                setLoading(false);
                if (e?.data?.success) {
                    setArticleComment('');
                    getArticles();
                    toast.success(e?.data?.message);
                    getArticleResponse(Id);
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.data?.messsage);
            });
    }

    const getArticleResponse = (id) => {
        setLoading(true)
        const Data = {};
        Data.data = id;

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: JSON.stringify(Data),
            url: `${ApiUrl()}/Article/ArticleComments`
        };

        axios(option)
            .then(e => {
                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                setCommentReply(data);
            })
            .catch(err => {
                setLoading(false)
                toast.error(err?.data?.messsage);
            })
    }
    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, [])


    const EditArticleData = (item) => {
        setArticleEditStore(item);
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        if (articleEditStore?.Id) {
            setPostTitle(articleEditStore?.Title);
            setPostQuery(articleEditStore?.Content);
            setIsPublish(articleEditStore?.Publish ? 'yes' : 'no');
            setArticleimage(`${Getimage(articleEditStore?.ImageUrl)}`);
            setPhotoExtension(articleEditStore?.ImageUrl ? articleEditStore?.ImageUrl.split(".")[1] : null)
        }

    }, [articleEditStore?.Id])

    // update article

    const UpdateArticle = () => {
        if (!UpdateValidateFields()) return;
        setLoading(true);
        const data = {}
        data.Title = postTitle;
        data.Content = postQuery;
        data.Publish = isPublish === "yes";
        data.IsPrivate = false;
        data.DateCreated = exactDate;
        data.Id = articleEditStore?.Id;
        data.ArticleImage = imgfile?.length === 0 ? null : imgfile;
        data.Extenssion = photoExtension;
        data.ImageUrl = articleEditStore?.ImageUrl;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Article/AddArticle`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                toast.success(e?.data?.message);
                setPostTitle('');
                setPostQuery('');
                setIsPublish('yes');
                setCardData([]);
                setImageFile([]);
                setArticleimage('');
                getArticles();
                window?.location?.reload();

            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
                setLoading(false);
            });
    }
    const DeletePost = (item) => {
        setDeletePostModal(true);
        setDeletePostData(item)

    }
    const DeleteQuery = () => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { 'Data': deletePostData?.Id },
            url: `${ApiUrl()}/Article/DeleteArticle`
        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    handleMainDeleteClose();
                    toast.success(e?.data?.message);
                    getArticles();
                }
            })
            .catch(err => {

            })
    }


    const EditArticleHandler = (item) => {
        setEditArticleHandleData(item);

        window.scrollTo(0, 0)
    }

    useEffect(() => {
        if (editArticleHandleData?.Id) {
            setPostTitle(editArticleHandleData?.Title);
            setPostQuery(editArticleHandleData?.Content);
            setIsPublish(editArticleHandleData?.Publish ? 'yes' : 'no');
            setArticleimage(`${Getimage(editArticleHandleData?.ImageUrl)}`);
            setPhotoExtension(editArticleHandleData?.ImageUrl ? editArticleHandleData?.ImageUrl.split(".")[1] : null)
        }
    }, [editArticleHandleData?.Id])

    const EditArticleSubmit = () => {
        if (!UpdateValidateFields()) return;
        const data = {}
        data.Title = postTitle;
        data.Content = postQuery;
        data.Publish = isPublish === "yes";
        data.IsPrivate = false;
        data.DateCreated = exactDate;
        data.Id = editArticleHandleData?.Id;
        data.ArticleImage = imgfile?.length === 0 ? null : imgfile;
        data.Extenssion = photoExtension;
        data.ImageUrl = editArticleHandleData?.ImageUrl;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Article/AddArticle`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                toast.success(e?.data?.message);
                setPostTitle('');
                setPostQuery('');
                setIsPublish('yes');
                setCardData([]);
                setImageFile([]);
                setArticleimage('');
                getArticles();
                window?.location?.reload();

            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
                setLoading(false);
            });
    }

    const CancelButton = () => {
        window?.location?.reload();
    }




    return (
        <>
            <div className="page-container">
                {
                    loading ? <Loader /> : null
                }
                <Headers innerPages={"innerPages"} articlePath={articlePath} />
                {/* breadcrumb start */}
                <section className="bredcrum-ps">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active add-mr-right" aria-current="page"> Articles</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                {/* breadcrumb end */}

                {/* post section start */}

                <section className={`quries-clrfix`}>
                    <div className="container new-responsive-padding">

                        <div className="row">
                            <div className="col-md-9">
                                <div className="col-12">
                                    <div className="mmax-post-title page-title-queries">
                                        {
                                            postTitle ? <div className="maxPostTitle">{maxPageTitle - postTitle.length} characters left</div> : <div className="maxPostTitle">100 characters left</div>
                                        }
                                        <input type="text" className="form-control wt-clrfix" id="exampleFormControlInput1" maxLength={100} placeholder="Article Title..." onChange={(e) => setPostTitle(e?.target?.value)} value={postTitle}></input>
                                    </div>
                                    <div className="main-section-editor add-float">
                                        <div className="editor add-mb">
                                            <CKEditor className="editor-main"
                                                editor={ClassicEditor}
                                                onChange={(event, editor) => setPostQuery(editor?.getData())}
                                                data={postQuery}
                                                config={{
                                                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-4 img-article-outer'>
                                        <div className="col-12 col-md-12 col-sm-12 img-myarticle">
                                            <div className='article-img-container'>
                                                {
                                                    articleimage?.length ?
                                                        <div className='w-100 art-img-inner'>
                                                            <img src={articleimage} alt="img" className="art-img" />
                                                            <div className='change-article-image'>
                                                                <div className="change-preview-article">
                                                                    <img src='/images/edit-image.png' alt='edit-image' />
                                                                    <input type="file" className="input-file-article" accept="image/*" onChange={ImgFileHandler}></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <div className='article-upload-icon'><MdCloudUpload /></div>
                                                            <div className="edit-article">
                                                                Add Image
                                                                <input type="file" className="input-file-article" accept="image/*" onChange={ImgFileHandler}></input>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">


                                        <div className="col-12 col-md-4 col-sm-4 align-itm">
                                            <span className='publish-article'>Publish</span>
                                            <div className="btn-radio article-radio-btn">
                                                <div className="form-check btn-radio-box-post">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="group2"
                                                        type={'radio'}
                                                        id={`inline-${'radio'}-3`}
                                                        onChange={() => setIsPublish("yes")} value={isPublish}
                                                        checked={isPublish === "yes"}
                                                    />
                                                </div>

                                                <div className="form-check">
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="group2"
                                                        type={'radio'}
                                                        id={`inline-${'radio'}-4`}
                                                        onChange={() => setIsPublish("no")} value={isPublish}
                                                        checked={isPublish === "no"}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-8 col-sm-4 update-btn-flex">

                                            {
                                                editArticleHandleData?.Id ?
                                                    <div style={{ position: 'relative' }}>
                                                        <button type="button" className="btn btn-primary btn-submit" onClick={EditArticleSubmit}>Update</button>
                                                    </div> :
                                                    articleEditStore?.Id ?
                                                        <div style={{ position: 'relative' }}>
                                                            <button type="button" className="btn btn-primary btn-submit" onClick={UpdateArticle}>Update</button>
                                                        </div> :
                                                        <div style={{ position: 'relative', width: '100%' }}>
                                                            <button type="button" className="btn btn-primary btn-submit new-btn-full-width" onClick={SubmitPost}>Submit</button>
                                                        </div>
                                            }

                                            {
                                                editArticleHandleData?.Id ? <button type="button" className="btn btn-primary article-cancel-edit" onClick={() => CancelButton()}>Cancel</button> : null
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 new-get-query-div">
                                    <div>
                                        {
                                            articles?.map((item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {
                                                            item?.Active && profilelist?.Profile?.UserId === item?.OwnerId ?
                                                                <div data-aos="flip-down" data-aos-easing="ease-in-sine" data-aos-duration="400" className="pd-on-mob">
                                                                    <div className="row">
                                                                        <div className="ps-name-box mobile-ps-article">
                                                                            <img src={`${Getimage(item?.ProfilePhotoUrl)}`} alt="User Img." className="image-main-cards-dshbord" />
                                                                            <div className="title-text new-title-text">
                                                                                <div>
                                                                                    <h1>{item?.ProfileName} - {item?.Title}</h1>
                                                                                    {
                                                                                        profilelist?.Profile?.UserId === item?.OwnerId && item?.CommentCount < 1 ? <AiFillDelete className="delete-icon-article-final" onClick={() => DeletePost(item)} tabIndex="0" data-toggle="tooltip" title="Delete Query" /> : null
                                                                                    }

                                                                                    {
                                                                                        item?.Publish === false ? null : profilelist?.Profile?.UserId === item?.OwnerId && item?.CommentCount < 1 ? <AiFillEdit className="edit-icon-query article-edit-icon-svg" onClick={() => EditArticleHandler(item)} tabIndex="0" data-toggle="tooltip" title="Edit Query" /> : null
                                                                                    }
                                                                                </div>
                                                                                <div className="query-text-figure" dangerouslySetInnerHTML={{ __html: item?.Content }}></div>
                                                                                <div className="new-like-comment">
                                                                                    <a className="like-btn active" id="aa" likeit={`${!item?.IsLiked}`} onClick={(e) => { LikeArticle(e, item?.Id) }} >
                                                                                        {
                                                                                            (item?.IsLiked) ? <AiFillLike className="icons-queries" /> : <AiOutlineLike className="icons-queries" />
                                                                                        }
                                                                                    </a>



                                                                                    <span id="qLikeCount" className="like-count">{item?.LikesTotal}</span>
                                                                                    <a className="like-btn comment-icon" onClick={() => { setShow(show !== item?.Id ? item?.Id : null); setReplyId(item?.Id); getArticleResponse(item?.Id) }}><GoCommentDiscussion className="icons-queries" />{item?.CommentCount}</a>
                                                                                    <span className="undrline-txt article-underline-txt">{item?.DateCreated === null ? null : <> <span className='published-date'>Published On:</span> {moment(item?.DateCreated)?.format('Do MMM YYYY, h:mm A')} </>}</span>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                item?.Publish === false ? <button className='edit-article-button' onClick={() => EditArticleData(item)}>Edit</button> : null
                                                                            }
                                                                            {(show && replyId === item?.Id) ?

                                                                                <div className="ps-name-box mobile-ps-name add-position-reply-queries thread-responsive">
                                                                                    {
                                                                                        commentReply?.map((art, index) => {
                                                                                            return (
                                                                                                <Fragment key={index} >

                                                                                                    <div className="queries-reply-section-outer">
                                                                                                        <div className="query-response-box article-reply-box">
                                                                                                            <img src={`${Getimage(art?.UserPhotoUrl)}`} alt="User Img." className="image-main-cards-dshbord" />
                                                                                                            <div className="title-text new-title-text">
                                                                                                                <div className="spam-mark-icon">
                                                                                                                    <h1>{art?.CommentByUser}</h1>
                                                                                                                </div>
                                                                                                                <p dangerouslySetInnerHTML={{ __html: art?.Comment }}></p>
                                                                                                                <div className="new-like-comment">
                                                                                                                    <span className="undrline-txt float-design">{art?.CommentDate === null ? null : <><span className='published-date'>Published On:</span>{moment(art?.CommentDate).format('Do MMM YYYY, h:mm A')}</>}</span>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div className="separator-query-resp"></div>

                                                                                                    </div>
                                                                                                </Fragment>

                                                                                            );
                                                                                        })
                                                                                    }
                                                                                    <div className="input-grp-fil mb-3 query-reply-box">
                                                                                        <CKEditor className="editor-main"
                                                                                            editor={ClassicEditor}
                                                                                            config={{
                                                                                                removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                                                                            }}
                                                                                            data={articleComment}
                                                                                            onChange={(event, editor) => setArticleComment(editor?.getData())}

                                                                                        />
                                                                                        {/* <input type="text" className="form-control frm-cntrol txtReply" placeholder="Comment Here...." onChange={(e) => setArticleComment(e?.target?.value)} value={articleComment}></input> */}
                                                                                        <span className="input-group-text" id="basic-addon2" onClick={(e) => ReplyToArticle(item?.Id, e)}>Reply</span>
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </Fragment>


                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="col-6 col-sm-3 col-md-3 mobile-sidebar-not-view">
                                <div className="new-sticky-sidebar">
                                    <Tabs
                                        defaultActiveKey="Gold"
                                        id="uncontrolled-tab-example"
                                        className="members-tab-gold"
                                    >
                                        <Tab eventKey="Gold" title="Gold Members" className="tab-gold-active">
                                            <GoldMember profilelistUser={profilelistUser} searchUser={searchUser} search={search} setSearch={setSearch} />
                                        </Tab>
                                        <Tab eventKey="Other" title="Other Members" className="tab-others-active">
                                            <MemberSidebar />
                                        </Tab>
                                    </Tabs>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footers />


                <Modal show={deletePostModal} onHide={handleMainDeleteClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleMainDeleteClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading delete-modal-heading"><span>Are</span> you sure want to delete this Article?</h1>
                                        <div className="why-spam-btn">
                                            <button className="btn btn-primary button-delete-modal" onClick={() => DeleteQuery()}>
                                                Yes
                                            </button>

                                            <button className="btn btn-danger button-delete-modal-No" onClick={() => handleMainDeleteClose()}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>

            </div>
            {/* post section end */}
        </>
    )
}

export default Post;
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { BsInstagram, BsTwitter, BsFacebook, BsYoutube, BsPinterest, BsLinkedin, BsArrowRightShort, } from "react-icons/bs";
import {MdOutlineArrowRight} from 'react-icons/md';

function Footers() {
    return (
        <>
            <section className="ps-footer">
                <div className="container">
                    <div className="row inner-footer">
                        <div className="col-md-4 d-flex logo-footer"><Link to='/'><img src="images/logo.png" alt="PrivateSqureLogo" className="logo-ps-footer" /></Link></div>
                        <div className="col-md-4 help-us-links">
                            <div className="heplus-links-inner"><Link to='/howitworks'><BsArrowRightShort className="help-us-icon"/>How It Works</Link></div>
                            <div className="heplus-links-inner"><Link to=''><BsArrowRightShort className="help-us-icon"/>FAQS</Link></div>
                            <div className="heplus-links-inner"><Link to=''><BsArrowRightShort className="help-us-icon"/>Privacy Policy</Link></div>
                            <div className="heplus-links-inner"><Link to=''><BsArrowRightShort className="help-us-icon"/>Terms and Conditions</Link></div>
                        </div>
                        <div className="col-md-4 social-follow-container">
                            <div className="followus-footer">
                                Follow us on
                            </div>
                            <div className="followus-separator"></div>
                            <div className="social-links-outer">
                                <div className="social-links-inner"><a href="https://www.instagram.com/private_squares/" target="_blank"><img className="social-media-color-icon" src='/images/socialmedia/instagram.png'/></a></div>
                                <div className="social-links-inner"><a href="https://twitter.com/SquaresPrivate" target="_blank"><img className="social-media-color-icon" src='/images/socialmedia/twitter.png'/></a></div>
                                <div className="social-links-inner"><a href="https://www.facebook.com/Private-Squares-101035455157416" target="_blank"><img className="social-media-color-icon" src='/images/socialmedia/facebook.png'/></a></div>
                                <div className="social-links-inner"><a href="https://www.youtube.com/channel/UCs-rSUWEONYN5qRUBvW5hFw/about?view_as=subscriber" target="_blank"><img className="social-media-color-icon" src='/images/socialmedia/youtube.png'/></a></div>
                                <div className="social-links-inner"><a href="https://in.pinterest.com/PrivateSquares/" target="_blank"><img className="social-media-color-icon" src='/images/socialmedia/pinterest.png'/></a></div>
                                <div className="social-links-inner"><a href="https://www.linkedin.com/company/privatesquares/" target="_blank"><img className="social-media-color-icon" src='/images/socialmedia/linkedin.png'/></a></div>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <p>© 2023 PrivateSquares. All Rights Reserved</p>
                </div>
            </section>
        </>
    );
}
export default Footers;
import React, { useState } from "react";
import Footers from "../../components/Footers/Footers";
import Headers from "../../components/header/header";
import { Form } from "react-bootstrap";
import axios from "axios";
import { ApiUrl, getToken } from "../../utils/common";
import Loader from "../../components/Loader/loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Polling = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isPublish, setIsPublish] = useState('no')
    const [MultipleAnswer, setMultipleAnswer] = useState('no')
    const [question, setQuestion] = useState('');
    const [showPlus, setShowPlus] = useState(true);
    const [inputFields, setInputFields] = useState([{
        optionData: '',
    }]);
    const [maxPageTitle, setMaxPageTitle] = useState(300);
    const [maxOptionTitle, setmaxOptionTitle] = useState(100);
    const addInputField = () => {
        if (inputFields?.length === 3) {
            setShowPlus(false)
        } else {
            setShowPlus(true)
        }
        setInputFields([...inputFields, {
            optionData: '',
        }])
    }
    const removeInputFields = (index) => {
        setShowPlus(true)
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }

    const createPoll = () => {
        if (!question) {
            toast.error("Please enter question to create Poll");
            return;
        }
        if (inputFields?.length < 2) {
            toast.error("Please enter atleast 2 options to create Poll");
            return;
        }
        if(inputFields){
            let count=0;
            inputFields?.map((item, index) => {
                if(item?.optionData === ""){
                    count+=1;
                }
               
        })
        if(count>=1){
            toast.error("Option value can not be empty");
            return;
        }
        }
     
        const pollOptions = inputFields?.map((item, index) => (
            {
                OptionPoll: item?.optionData,
                DisplayNo: index + 1,
            }
        ))
        const data = {};
        data.Question = question;
        data.MultipleAnswer = MultipleAnswer === "yes";
        data.Publish = isPublish === "yes";
        data.PollOptions = pollOptions;
        setLoading(true)
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "data": JSON.stringify(data) },
            url: `${ApiUrl()}/Admin/SavePollQuestion`
        };

        axios(option)
            .then(e => {
                setLoading(false)
                toast.success(e?.data?.message)
                navigate("/listpolling")

            })
            .catch((err) => {
                setLoading(false)
            });
    }

    return (
        <>
            <div className="page-container">
                {
                    loading ? <Loader /> : null
                }
                <Headers innerPages={"innerPages"} />
                <section className="ps-profileyr">
                    <div className="text-center">
                        <h1 className="updat-yor">Create Poll Questions</h1>
                    </div>
                    <div className="profle-mndiv mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="frm-inpt-profle">
                                    <div className="row">

                                        <div className="col-md-12">
                                            <span className="frm-inpt-inr">
                                                <label htmlFor="fname">Question</label>
                                                {
                                                    question ? <div className="maxQuestionTitle">{maxPageTitle - question.length} characters left</div> : <div className="maxQuestionTitle">300 characters left</div>
                                                }
                                                <input type="text" autoComplete="off" maxLength={300} id="Question" name="Question" value={question} onChange={(e) => setQuestion(e?.target?.value)}></input>

                                            </span>
                                        </div>
                                        {
                                            inputFields?.length ? <>
                                                {
                                                    inputFields.map((item, index) => {
                                                        return (

                                                            <div className="col-md-8 poll_input" key={index}>
                                                                <span className="frm-inpt-inr poll-cross">
                                                                    <label>Option {index + 1}</label>
                                                                    {
                                                                        item?.optionData ? <div className="maxQuestionTitle">{maxOptionTitle - item?.optionData.length} characters left</div> : <div className="maxQuestionTitle">100 characters left</div>
                                                                    }
                                                                    <input type="text"  maxLength={100} onChange={(evnt) => handleChange(index, evnt)} value={item?.optionData} name="optionData" className="form-control polling-input-inner" placeholder="" ></input>
                                                                    <div>
                                                                        {(inputFields.length !== 1) ? <button className="btn btn-outline-primary removeOption" onClick={() => removeInputFields(index)}>x</button> : ''}
                                                                    </div>
                                                                </span>

                                                            </div>

                                                        )
                                                    })
                                                }
                                            </> : null
                                        }

                                        {
                                            showPlus ? <div className="row mt-4">
                                                <div className="col-sm-12 new-option-poll">
                                                    Add New Option
                                                    <button className="btn btn-outline-primary removeOptionCross " onClick={addInputField}>+</button>
                                                </div>
                                            </div> : null
                                        }



                                        <div className="col-12 col-md-6 col-sm-6 align-itm polling-radio-btn">
                                            <span className='publish-article'>Publish</span>
                                            <div className="btn-radio article-radio-btn">
                                                <div className="form-check btn-radio-box-post">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="group2"
                                                        type={'radio'}
                                                        id={`inline-${'radio'}-3`}
                                                        onChange={() => setIsPublish("yes")} value={isPublish}
                                                        checked={isPublish === "yes"}

                                                    />

                                                </div>

                                                <div className="form-check">
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="group2"
                                                        type={'radio'}
                                                        id={`inline-${'radio'}-4`}
                                                        onChange={() => setIsPublish("no")} value={isPublish}
                                                        checked={isPublish === "no"}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-sm-6 align-itm polling-radio-btn">
                                            <span className='publish-article'>Multiple Answers</span>
                                            <div className="btn-radio article-radio-btn">
                                                <div className="form-check btn-radio-box-post">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="group1"
                                                        type={'radio'}
                                                        id={`inline-${'radio'}-1`}
                                                        onChange={() => setMultipleAnswer("yes")} value={MultipleAnswer}
                                                        checked={MultipleAnswer === "yes"}

                                                    />

                                                </div>

                                                <div className="form-check">
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="group1"
                                                        type={'radio'}
                                                        id={`inline-${'radio'}-2`}
                                                        onChange={() => setMultipleAnswer("no")} value={MultipleAnswer}
                                                        checked={MultipleAnswer === "no"}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="servc-updt add-position mb-0 polling-btn-create">
                                            <button className="updt-prfile button-loader" onClick={() => createPoll()}>Create Poll</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <Footers />
            </div >
        </>
    )
}

export default Polling;
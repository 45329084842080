import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, FormGroup, Modal } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiUrl, Getimage, GetRoleName, getToken, removeUserSession, setUserSession } from "../../utils/common";
import Footers from "../Footers/Footers";
import Headers from "../header/header";
import Popup from "../popup/signinpopup/popup";
import Loader from "../Loader/loader";
import Aos from 'aos';
import ProfileModal from "../profileModal/profilemodal";
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import Emojis from "./EmojisList";
import moment from "moment/moment";
import Banner from "../banner/banner";
import { BsCalendarCheck } from "react-icons/bs";
const Dashboard = () => {
    var date = new Date()
    var exactDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    const [profilelist, setProfilelist] = useState([]);
    const [userData, setUserData] = useState('');
    const [search, setSearch] = useState('')
    const [isSearch, setIsSearch] = useState(false)
    const [userProfileData, setUserProfileData] = useState({});
    const [Loading, setLoading] = useState(false);
    const [buttonPopup, setButtonPopup] = useState(false);
    const [serviceData, setServiceData] = useState('');
    const [copyText, setCopyText] = useState(false);
    const [rightSideBarEmoji, setRightSideBarEmoji] = useState(false);
    const [emojiDataClick, setEmojiDataClick] = useState();
    const [showTracker, setShowTracker] = useState(false);
    const handleTrackerClose = () => setShowTracker(false);
    const handleTrackerShow = () => setShowTracker(true);
    const [showPollButton, setShowPollButton] = useState(true)
    const [pollAnswer, setPollAnswer] = useState(false);
    const handlePollClose = () => { setPollAnswer(false); setPollData([]) };
    const handlePollShow = () => { setPollAnswer(true) }
    const [pollSubmit, setPollSubmit] = useState(false)
    const PollSubmitClose = () => { setPollSubmit(false); setPollData([]) }
    const PollSubmitShow = () => { setPollSubmit(true); setPollAnswer(false) }
    const [isTimeSet, setIsTimeSet] = useState(false);
    const [emptySearch, setEmptySearch] = useState(false);
    const LoginnedUser = profilelist?.find((item) => item?.Id === userProfileData?.Id);
    const [listQuestions, setListQuestions] = useState([]);
    const [pollData, setPollData] = useState([])
    const polltime = window?.localStorage?.getItem('poll')
    const [pollTime, setPollTime] = useState(polltime);
    const [showpollAnalytics, setShowpollAnalytics] = useState()
    const [pollAnalytics, setPollAnalytics] = useState([])
    const handlepollAnalyticsClose = () => setShowpollAnalytics(false);
    const handlepollAnalyticsShow = () => setShowpollAnalytics(true);
    const [Bookslots, setBookslots] = useState()
    const handleBookslotsClose = () => setBookslots();
    const handleBookslotsShow = (item) => setBookslots(item);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [selectedDaysArr, setSelectedDaysArr] = useState([])
    const [timeSlots, setTimeSlots] = useState([]);
    const [userSelectedDay, setUserSelectedDay] = useState();
    const [bookedData, setBookedData] = useState();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
    const [confirmedData, setConfirmedData] = useState()
    const [confirmBooking, setconfirmBooking] = useState(false);
    const handleconfirmBookingClose = () => setconfirmBooking(false);
    const weekDays = [
        {
            label: "Sun",
            value: "Sunday",
            short: 'S',
            selected: false
        },
        {
            label: "Mon",
            value: "Monday",
            short: 'M',
            selected: false
        },
        {
            label: "Tue",
            value: "Tuesday",
            short: 'T',
            selected: false
        },
        {
            label: "Wed",
            value: "Wednesday",
            short: 'W',
            selected: false
        },
        {
            label: "Thu",
            value: "Thursday",
            short: 'T',
            selected: false
        },
        {
            label: "Fri",
            value: "Friday",
            short: 'F',
            selected: false
        },
        {
            label: "Sat",
            value: "Saturday",
            short: 'S',
            selected: false
        },
    ]
    useEffect(() => {
        if (getAdminRole !== "Admin") {
            if (search) {
                const timer = setTimeout(() => {
                    const data = profilelist?.filter((item) => item?.Active);
                    const privateProfile = data?.filter((item) => item?.Profile?.[0]?.ShowPublic === false);
                    if (privateProfile?.length === data.length) {
                        setEmptySearch(true);
                        if (LoginnedUser?.Profile?.[0]?.ShowPublic === false) {
                            setEmptySearch(false);
                        }
                    } else {
                        setEmptySearch(false);
                    }
                }, 500);

                return () => clearTimeout(timer);
            }
        }

    }, [profilelist])


    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, [])

    const userDetail = (e) => {
        setUserData(e);
    }
    // get data api
    const getMainProfiles = () => {

        const data = (search != null) && search;
        setEmptySearch(false);
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },

            data: { "data": data },
            url: `${ApiUrl()}/User`
        };
        axios(option)
            .then(e => {
                const data = JSON.parse(e?.data?.data || '{}');
                setProfilelist(data);
                setEmptySearch(false);
            })
            .catch((err) => {
                setProfilelist([]);
                setEmptySearch(true);
            });
    }

    useEffect(() => {
        const myTimeout = setTimeout(() => {
            getMainProfiles();
        }, 500);
        return () => clearTimeout(myTimeout)
    }, [search])

    const navigate = useNavigate();
    const responseGoogle = (response) => {
        const Data = {}
        Data.tokenId = response.tokenId;

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }, data: { Data: Data }, url: `${ApiUrl()}/Account/auth/google`
        };
        axios(option)
            .then(e => {
                setUserSession(e?.data?.data);
                toast.success("User has been successfully login");
                navigate('/profile')
            })
            .catch((err) => {
                toast.error(err)
            });

    }
    useEffect(() => {
        if (window.location.href?.split('=')?.[1]?.length && profilelist?.length) {
            const id = window.location.href?.split('=')?.[1] || '';
            const data = profilelist?.find((item) => item?.Id === Number(id))
            userDetail(data?.Profile?.[0])

        }
    }, [profilelist?.length])


    const GetModalUser = profilelist?.find((item) => item?.Id === userData?.UserId);

    const getProfiles = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Profile`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                const profile = JSON.parse(e?.data?.data)
                setUserProfileData(profile)

            })
            .catch((err) => {
                removeUserSession();

                setLoading(false)
            });
    }

    useEffect(() => {
        getProfiles()
    }, [])

    // askquestion token state
    const AskQuestion = getToken();

    const ServiceData = (item) => {
        setServiceData(item);
        setUserData('');
    }
    // emoji and mood Chart section
    const EmojiSend = (e) => {
        const data = {};
        data.EmojiId = e?.id;
        data.DateMoodSet = exactDate;

        const option = {
            method: 'PATCH', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            data: { "data": JSON.stringify(data) },
            url: `${ApiUrl()}/User/UserMood`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    getMainProfiles();
                    window.location.reload();
                }
            })
            .catch((err) => {

            })
    }

    const EmojiSubmit = (e) => {
        setEmojiDataClick(e);
        EmojiSend(e)
    }

    const EmojiDate = profilelist?.find((item) => item?.Id === userProfileData?.Id);
    useEffect(() => {
        if (EmojiDate?.EmojiId) {
            let days = moment().diff(moment(EmojiDate.DateMoodSet), 'days');

            if (days >= 1) {
                setIsTimeSet(true);
            }
        }

    }, [EmojiDate?.EmojiId])

    //create gold members
    const GoldCreate = (item) => {
        const data = {};
        data.Id = item?.Id;
        data.IsGoldMember = `${!(item?.IsGoldMember)}`;
        const option = {
            method: 'PATCH', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },

            data: { "data": JSON.stringify(data) },
            url: `${ApiUrl()}/Admin/SetGoldMember`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    getMainProfiles();
                }
            })
            .catch((err) => {
            })
    }

    //user active and inactive
    const ActiveInactiveUser = (item) => {
        const data = {};
        data.Id = item?.Id;
        data.Active = `${!(item?.Active)}`;
        const option = {
            method: 'PATCH', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },

            data: { "data": JSON.stringify(data) },
            url: `${ApiUrl()}/Admin/EnableDisableUser`
        }
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    getMainProfiles();
                }
            })
            .catch((err) => {
            })

    }

    //user polling
    const getPollList = () => {
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            url: `${ApiUrl()}/Poll/PublishedPoll`
        };

        axios(option)
            .then(e => {
                setLoading(false)
                setListQuestions(JSON.parse(e?.data?.data))
            })
            .catch((err) => {
                setLoading(false)
            });
    }
    useEffect(() => {
        getPollList()
    }, [])


    const PollAnalytics = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/json',
            },
            url: `${ApiUrl()}/Poll/PollAnalytics`
        };

        axios(option)
            .then((e) => {
                setLoading(false);
                const data = JSON.parse(e?.data?.data)
                setPollAnalytics(JSON.parse(data.Data))
            })
            .catch((err) => {
                setLoading(false);
            })
    }
    useEffect(() => {
        PollAnalytics()
    }, [])

    const handlePollValue = (e, item) => {
        if (e?.target?.checked) {
            if (item?.MultipleAnswer === true) {
                setPollData([...pollData, {
                    PollQuestionId: item?.Id,
                    UserOptionId: Number(e?.target?.value),
                }])
            } else {
                setPollData([{
                    PollQuestionId: item?.Id,
                    UserOptionId: Number(e?.target?.value),
                }])
            }

        } else {
            if (item?.MultipleAnswer === true) {
                const data = pollData?.filter((item) => item?.UserOptionId !== Number(e?.target?.value))
                setPollData(data);
            } else {
                setPollData([{
                    PollQuestionId: '',
                    UserOptionId: '',
                }])
            }

        }
    }

    const onSubmitPoll = () => {
        if (pollData.length < 1) {
            toast.error("Please select Poll Options")
            return;
        }
        setLoading(true)
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "data": JSON.stringify(pollData) },
            url: `${ApiUrl()}/Poll/UserPoll`
        };

        axios(option)
            .then(e => {
                setLoading(false)
                setShowPollButton(false);
                localStorage.setItem('poll', exactDate);
                PollSubmitShow()
                PollAnalytics()
            })
            .catch((err) => {
                setLoading(false)
            });
    }

    useEffect(() => {
        if (pollTime) {
            if (moment(exactDate).format('LTS') >= moment(pollTime).add(30, 'minutes').format('LTS')) {
                setShowPollButton(true);
                localStorage.removeItem('poll')
            } else {
                setShowPollButton(false);
            }
        }

    }, [pollTime])
    const getAdminRole = GetRoleName();




    const handleCreateTimeSlots = (item) => {
        console.log(item, 'item');
        const timeFields = item?.Profile?.[0]?.WorkingHours;
        setAllTimeSlots(timeFields);

        const aaa = item?.Profile?.[0]?.AvailabilityDays?.split(",");
        const updatedWeekDays = [...weekDays];
        weekDays?.map((i, index) => {
            if (aaa?.find(item => item?.includes(i?.label))) {
                updatedWeekDays[index].selected = true;
            }
        })
        console.log(updatedWeekDays, 'selectedWeekDays');
        setSelectedDaysArr(updatedWeekDays)
    }

    let allTimes = [];
    useEffect(() => {
        let newTime = [];
        newTime = allTimeSlots?.map(item => ({
            "StartTime": `${new Date(item?.StartTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`,
            "EndTime": `${new Date(item?.EndTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`,
            "slotInterval": 20,
        }))


        newTime.forEach(item => {
            let newArr = [];
            let StartTime = moment(item?.StartTime, "hh:mm A");
            let EndTime = moment(item?.EndTime, "hh:mm A").subtract(19, "minutes");
            while (StartTime < EndTime) {
                newArr.push({
                    "startHour": StartTime.format("hh:mm A"),
                    "endHour": StartTime.add(item?.slotInterval, 'minutes').format("hh:mm A"),
                    "slot": 4,
                    "weekDays": selectedDaysArr
                });
            }
            allTimes.push(newArr);
            return allTimes;
        });
        setTimeSlots(allTimes)
    }, [allTimeSlots, selectedDaysArr])


    const handlebookNow = (i) => {

        if (!userSelectedDay?.startHour) {
            toast.error("Please select Day");
            return;
        } else if (userSelectedDay?.startHour !== i?.startHour) {
            setUserSelectedDay({})
            toast.error("Please select the correct Day according to time slot")
            return;
        }
        let booked = []
        if (bookedData?.length) {
            const foundEl = bookedData?.find(el => el?.startHour === i?.startHour);
            const filtered = bookedData?.filter(el => el?.startHour !== i?.startHour)
            const id = foundEl?.profileData || [];
            id.push(profilelist?.Id)
            booked = [...filtered,
            {
                startHour: i?.startHour,
                endHour: i?.endHour,
                weekDays: userSelectedDay?.day,
                profileData: id,
                date: moment(new Date()).add((userSelectedDay?.weekNumber - new Date().getDay()), "days")
            }]
        } else {
            const id = [];
            id.push(profilelist?.Id)
            booked = [
                {
                    startHour: i?.startHour,
                    endHour: i?.endHour,
                    weekDays: userSelectedDay?.day,
                    profileData: id,
                    date: moment(new Date()).add((userSelectedDay?.weekNumber - new Date().getDay()), "days")
                }]
        }
        setSelectedTimeSlot({
            startHour: i?.startHour,
            endHour: i?.endHour,
            weekDays: userSelectedDay?.day,
            date: moment(new Date()).add((userSelectedDay?.weekNumber - new Date().getDay()), "days")
        })
        setConfirmedData(booked);
        setconfirmBooking(true);
    }
    const handleconfirmBookingSubmit = () => {
        localStorage.setItem("bookingData", JSON.stringify(confirmedData))
        navigate("/")
    }
    return (
        <>
            <div className="page-container">

                {
                    Loading ? <Loader /> : null
                }
                <Headers getMainProfiles={getMainProfiles} onSearch={(e) => setSearch(e)} search={search} onSearchClick={() => setIsSearch(!isSearch)} profile={userProfileData?.Profile} serviceData={serviceData} />

                <Banner getMainProfiles={getMainProfiles} onSearch={(e) => setSearch(e)} search={search} />


                <section className="dashbord-strt">
                    <div className="container">
                        <div>
                            {
                                emptySearch ?
                                    <div className="no-search-section">
                                        <div className="no-query-section-two">
                                            <h2>No match found</h2>
                                        </div>
                                    </div> :
                                    null
                            }

                            <div className="row dashboard-resp-row">
                                {
                                    profilelist
                                        .sort((a, b) => {
                                            return (a.IsGoldMember === b.IsGoldMember) ? 0 : a.IsGoldMember ? -1 : 1
                                        })
                                        .sort((a, b) => {
                                            return a.Id === userProfileData?.Id ? -1 : b.Id === userProfileData?.Id ? 1 : 0
                                        })
                                        ?.map((item, index) =>
                                        (
                                            <Fragment key={item?.Id}>
                                                {
                                                    getAdminRole === "Admin" ?
                                                        <>
                                                            <div data-aos="zoom-in-down" data-aos-easing="ease-in-sine" data-aos-duration="500" className="col-xs-12 col-md-3 col-sm-3 col-lg-3 col-xl-3 mb-20 set-in-mob set-intab" >
                                                                {
                                                                    item?.IsGoldMember ? <div className="gold-heading-section" style={{ position: 'relative' }}>
                                                                        <div className="gold-heading">
                                                                            <span className="inner-gold-heading">Gold</span>
                                                                        </div>
                                                                    </div> : null
                                                                }
                                                                <div className="gold-heading-section" style={{ position: 'relative' }}>
                                                                    <div className="points-heading">
                                                                        <span className="inner-points-heading">
                                                                            <span className="fee-points-query"></span>
                                                                            <span>{item?.TotalPoint}</span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="admin-dashbord-box text-center">
                                                                    <div className="box-card-user-dsbr" onClick={() => userDetail(item?.Profile?.[0])}>


                                                                        <img src={`${Getimage(item?.Profile?.[0]?.ProfilePhotoUrl, "images/dummy.svg")}`} onError={(e) => { e.target.src = "images/dummy.svg" }} alt="User Img." className="image-main-cards-dshbord change-dashboard" />


                                                                        <h1>{item?.Profile?.[0]?.ProfileName}</h1>
                                                                    </div>
                                                                    <p className="clr-chng mb-2">{item?.Profile?.[0]?.Designation}</p>

                                                                    {
                                                                        item?.Profile.length === 0 || item?.Profile?.[0]?.ConsultationFeePoint === 0 ? null : <p className="clr-chng mb-1 text-black text-opacity-75"><b>Consultation Fee: </b>{item?.Profile?.[0]?.ConsultationFeePoint} Points</p>
                                                                    }
                                                                    <p className="profile-info-data-dashboard">{item?.Profile?.[0]?.Description?.trim()?.substr(0, 90)}{item?.Profile?.[0]?.Description?.length > 90 ? "..." : null} </p>
                                                                    <div className="gold-active-admin">
                                                                        {
                                                                            item?.IsGoldMember ? <button className="gold-create-button" onClick={() => GoldCreate(item)}>Make UnGold</button> : <button className="gold-create-button" onClick={() => GoldCreate(item)}>Make Gold</button>
                                                                        }
                                                                        {
                                                                            item?.Id === userProfileData?.Id && item?.RoleId === 1 ? null : <>
                                                                                {
                                                                                    item?.Active ? <button className="active-create-button" onClick={() => ActiveInactiveUser(item)}>Make InActive</button> : <button className="active-create-button" onClick={() => ActiveInactiveUser(item)}> Make Active</button>
                                                                                }
                                                                            </>
                                                                        }


                                                                    </div>
                                                                    {
                                                                        userProfileData?.Id === item?.Id ? <div className="new-responsive-ask"><div className="btn-qstn my-queries-button"
                                                                            onClick={() => navigate("/userquery", { state: { data: item } })}>
                                                                            My Queries
                                                                        </div></div>
                                                                            : (

                                                                                AskQuestion ? <div className="new-responsive-ask"> <div className="btn-qstn"
                                                                                    onClick={() => navigate("/queries", { state: { data: item } })}>
                                                                                    Ask Me a Question
                                                                                </div></div> :
                                                                                    <div className="new-responsive-ask">
                                                                                        <div onClick={() => setButtonPopup(true)} className="btn-qstn">Ask Me a Question</div>
                                                                                    </div>
                                                                            )
                                                                    }


                                                                </div>
                                                            </div>
                                                        </> : <>
                                                            {
                                                                (LoginnedUser?.Profile?.[0]?.ShowPublic === false && item?.Active) || (((item?.Profile?.[0]?.ShowPublic === true) || (item?.Profile?.[0]?.ShowPublic === null)) && item?.Active) ?
                                                                    <div data-aos="zoom-in-down" data-aos-easing="ease-in-sine" data-aos-duration="500" className="col-xs-12 col-md-3 col-sm-3 col-lg-3 col-xl-3 mb-20 set-in-mob set-intab" >
                                                                        {
                                                                            item?.IsGoldMember ? <div className="gold-heading-section" style={{ position: 'relative' }}>
                                                                                <div className="gold-heading">
                                                                                    <span className="inner-gold-heading">Gold</span>
                                                                                </div>
                                                                            </div> : null
                                                                        }
                                                                        {
                                                                            userProfileData?.Id === item?.Id ? <div className="gold-heading-section" style={{ position: 'relative' }}>
                                                                                <div className="points-heading">
                                                                                    <span className="inner-points-heading">
                                                                                        <span className="fee-points-query"></span>
                                                                                        <span>{item?.TotalPoint}</span></span>
                                                                                </div>
                                                                            </div> : null
                                                                        }

                                                                        <div className="dashbord-box text-center">
                                                                            <div className="box-card-user-dsbr" onClick={() => userDetail(item?.Profile?.[0])}>


                                                                                <img src={`${Getimage(item?.Profile?.[0]?.ProfilePhotoUrl, "images/dummy.svg")}`} onError={(e) => { e.target.src = "images/dummy.svg" }} alt="User Img." className="image-main-cards-dshbord change-dashboard" />


                                                                                <h1>{item?.Profile?.[0]?.ProfileName}</h1>
                                                                            </div>
                                                                            <p className="clr-chng mb-2">{item?.Profile?.[0]?.Designation}</p>


                                                                            {
                                                                                item?.Id !== 27 || item?.Profile.length === 0 || item?.Profile?.[0]?.ConsultationFeePoint === 0 ? null : <p className="clr-chng mb-1 text-black text-opacity-75"><b>Consultation Fee: </b>{item?.Profile?.[0]?.ConsultationFeePoint} Points</p>
                                                                            }

                                                                            <p className="profile-info-data-dashboard">{item?.Profile?.[0]?.Description?.trim()?.substr(0, 90)}{item?.Profile?.[0]?.Description?.length > 90 ? "..." : null} </p>
                                                                            {item?.Id !== userProfileData?.Id && item?.Profile?.[0]?.WorkingHours?.length ? <button className="gold-create-button" onClick={() => { handleCreateTimeSlots(item); handleBookslotsShow(item) }}>Book Your Slot</button> : null}
                                                                            {
                                                                                userProfileData?.Id === item?.Id ? <div className="new-responsive-ask"><div className="btn-qstn my-queries-button"
                                                                                    onClick={() => navigate("/userquery", { state: { data: item } })}>
                                                                                    My Queries
                                                                                </div></div>
                                                                                    : (

                                                                                        AskQuestion ? <div className="new-responsive-ask"> <div className="btn-qstn"
                                                                                            onClick={() => navigate("/queries", { state: { data: item } })}>
                                                                                            Ask Me a Question
                                                                                        </div></div> :
                                                                                            <div className="new-responsive-ask">
                                                                                                <div onClick={() => setButtonPopup(true)} className="btn-qstn">Ask Me a Question</div>
                                                                                            </div>
                                                                                    )
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                    : null

                                                            }
                                                        </>
                                                }

                                            </Fragment>
                                        ))
                                }


                            </div>

                        </div>
                    </div>

                </section>
                <Button className="mood-chart-button" onClick={() => handleTrackerShow()}>Mood Chart</Button>
                <Button className="poll-ana-chart-button" onClick={() => handlepollAnalyticsShow()}>Poll Analytics</Button>
                {
                    LoginnedUser && showPollButton ? <Button className="poll-chart-button" onClick={() => handlePollShow()}>Today's Poll</Button> : null
                }
                <Modal show={showTracker} onHide={handleTrackerClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleTrackerClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl back-color-chart" >
                                    <h2 className="mood-chart-heading">Mood Chart</h2>
                                    <div className="mood-chart-outer">
                                        <div className="mood-chart-container">
                                            {
                                                Emojis?.map((item, index) => {
                                                    const data = profilelist?.filter((i) => i?.EmojiId == item?.id)?.length;

                                                    const percentage = (data / profilelist?.length) * 100;
                                                    return (
                                                        <Fragment key={index}>
                                                            <div className="row">
                                                                <div className="col-md-9 mood-chart-inner">
                                                                    <div >
                                                                        <img src={item?.image} style={{ width: '40px' }}></img>
                                                                    </div>
                                                                    <div className="mood-chart-percent-outer">
                                                                        <div className="mood-chart-percent-inner" style={{ width: `${percentage}%` }}></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 mood-chart-percent">{Math.round(percentage)}%</div>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal show={showpollAnalytics} onHide={handlepollAnalyticsClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handlepollAnalyticsClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl back-color-chart" >
                                    {
                                        pollAnalytics?.length ? <>
                                            <h2 className="mood-chart-heading">Poll Analytics</h2>
                                            <div className="mood-chart-outer ps-3">
                                                <div className="mood-chart-container pb-3">
                                                    <h1 className="poll-analytic-heading">{pollAnalytics?.[0]?.Question}</h1>
                                                    {
                                                        pollAnalytics?.map((item, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className="row poll-ana-resp">
                                                                        <div className="mood-chart-inner poll-chart-inner">
                                                                            <div className="option-poll-chart">
                                                                                <div><span className="poll-chart-opt">Option {index + 1}: </span>{item?.OptionPoll}</div>
                                                                            </div>
                                                                            <div className="poll-analytics-cont">
                                                                                <div className="mood-chart-percent-outer">
                                                                                    <div className="mood-chart-percent-inner" style={{ width: `${item?.CountPercentage}%` }}></div>
                                                                                </div>
                                                                                <div className="mood-chart-percent ps-3">{Math.round(item?.CountPercentage)}%</div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </> : <h2 className="mood-chart-heading poll-chart-not-head">Poll Analytics not published yet.</h2>

                                    }

                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal show={pollAnswer} onHide={handlePollClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handlePollClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="dash-poll" >
                                    {
                                        listQuestions?.Id ? <><h1 className="dash-poll-head">{listQuestions?.Question} </h1>
                                            {
                                                listQuestions?.MultipleAnswer === true ? <span className="dash_multiple_opt">You can select multiple options.</span> : null
                                            }
                                            {
                                                listQuestions?.PollOptions?.map((option, index) => (
                                                    <div className={pollData?.filter(item => item?.UserOptionId === option?.Id).length === 1 ? "dash-poll-inner-border" : "dash-poll-inner"} key={index}>
                                                        <label className="dash-poll-label">
                                                            <FormGroup className="box-checked-poll">
                                                                <input name="polloption" className={listQuestions?.MultipleAnswer === true ? "inputcheckbox" : "inputradio"} type={listQuestions?.MultipleAnswer === true ? "checkbox" : "radio"} value={option?.Id} onChange={(e) => handlePollValue(e, listQuestions)} />
                                                            </FormGroup>
                                                            {option?.OptionPoll}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                            <Button className="dash-poll-btn" onClick={() => onSubmitPoll()}>Submit</Button></> : <h1 className="dash-poll-head">No Poll for today.</h1>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal show={pollSubmit} onHide={PollSubmitClose}
                    className="modal modl-PS responsive-mobile-modal dash-modal-poll" id="modalPS">
                    <div className="modal-dialog m-none ">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="dash-poll-img"><img src="images/check.png" /></div>
                                <h1 className="dash-poll-head">Yay! Your poll has been created.</h1>
                                <Button className="dash-poll-btn" onClick={() => { PollSubmitClose(); handlePollClose() }}>Continue</Button>
                            </div>

                        </div>
                    </div>
                </Modal>

                {/* emoji section */}
                {
                    isTimeSet ?
                        <div className="emoji-container">
                            {
                                rightSideBarEmoji ?
                                    <div className="section-right-sidebar">
                                        <div style={{ position: 'relative' }}>
                                            <div className="sidebar-close-section" onClick={() => setRightSideBarEmoji(false)}>
                                                <AiOutlineRight />
                                            </div>
                                        </div>
                                        <div>
                                            <h2 className="mood-heading-sidebar">How's Your Mood Today</h2>
                                        </div>

                                        <div className="emoji-main-section">
                                            {
                                                Emojis?.map((item, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <img key={item} src={item?.image} className="images-sidebar" onClick={() => EmojiSubmit(item)} />
                                                            <p className="emoji-text">{item?.value}</p>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    : <div className="section-emoji-open" onClick={() => setRightSideBarEmoji(true)}>
                                        <AiOutlineLeft />
                                    </div>
                            }
                        </div> : EmojiDate?.EmojiId === null ?
                            <div>
                                <div className="section-emoji-open" onClick={() => setRightSideBarEmoji(true)}>
                                    <AiOutlineLeft />
                                </div>

                                {
                                    rightSideBarEmoji ?
                                        <div className="section-right-sidebar">
                                            <div style={{ position: 'relative' }}>
                                                <div className="sidebar-close-section" onClick={() => setRightSideBarEmoji(false)}>
                                                    <AiOutlineRight />
                                                </div>
                                            </div>
                                            <div>
                                                <h2 className="mood-heading-sidebar">How's Your Mood Today</h2>
                                            </div>

                                            <div className="emoji-main-section">
                                                {
                                                    Emojis?.map((item, index) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                <img key={item} src={item?.image} className="images-sidebar" onClick={() => EmojiSubmit(item)} />
                                                                <p className="emoji-text">{item?.value}</p>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                            </div> : null
                }


                {/* modal */}
                <ProfileModal userData={userData} setUserData={setUserData} ServiceData={(e) => ServiceData(e)}
                    setCopyText={setCopyText} copyText={copyText} GetModalUser={GetModalUser}
                    profilelist={profilelist} userProfileData={userProfileData}
                    setButtonPopup={setButtonPopup}
                />
                {/* </Link> */}


                <Footers />

                <Popup trigger={buttonPopup} setTrigger={setButtonPopup} showactivebutton={true}>
                    <h2 className="heading-inner-modal">sign in with <span>google</span></h2>

                    {/* arrow animation */}
                    <div className="main-section-animation-arrow">
                        <div className="arrow-container">
                            <div className="arrow-down"></div>
                        </div>
                    </div>

                    <div className="section-before-google-login">
                        <GoogleLogin className="google-login-button"
                            clientId="561093089554-apbopeknedv51u8ifn13vekm0cc4o51k.apps.googleusercontent.com"
                            buttonText="Login with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle} />
                    </div>
                </Popup>


                {/* booking modal */}
                <Modal show={Bookslots?.Id} onHide={handleBookslotsClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none booking-table">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleBookslotsClose()}></button>
                            </div>

                            <div className="modal-body">
                                {
                                    allTimeSlots?.length ?
                                        <div className="booking-slots-container">
                                            <div className="slots-booking-head">
                                                <table className="booking_slots_table">
                                                    <thead>
                                                        <tr className="booking_slots_thead">
                                                            <td className="booking_slots_td1">Time</td>
                                                            <td>Available Days for Current Week</td>
                                                            {/* <td className="booking_slots_td2">Slots</td> */}
                                                            <td className="booking_slots_td3"></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {timeSlots?.map((item, index) => (

                                                            item?.map((i, l) => {
                                                                return (
                                                                    <tr key={l} className="booking_slots_tr">
                                                                        <td className="booking_slots_td1">{i?.startHour}-{i?.endHour}</td>
                                                                        <td className="booking_slot_days_td">
                                                                            {
                                                                                i?.weekDays?.map((days, index) => (
                                                                                    <button
                                                                                        onClick={() => (setUserSelectedDay({ startHour: i?.startHour, day: days?.value, weekNumber: index }))}
                                                                                        disabled={days?.selected === false || index <= new Date().getDay()}
                                                                                        className={days?.selected === true && userSelectedDay?.startHour === i?.startHour && userSelectedDay?.day === days?.value ? "booking_slot_days-3" : days?.selected === true && index > new Date().getDay() ? "booking_slot_days-2" : "booking_slot_days"}
                                                                                    >{days?.short}</button>
                                                                                ))
                                                                            }
                                                                        </td>
                                                                        {/* <td className="booking_slots_td2">{foundElement ? foundElement?.slot : i?.slot}</td> */}
                                                                        <td className="booking_slots_td3">
                                                                            <button
                                                                                onClick={() => handlebookNow(i)}
                                                                                className={selectedTimeSlot?.startHour === i?.startHour ? "booking_slots_btn1" : "booking_slots_btn"}
                                                                                key={index}>{ "Book"}
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : null
                                }
                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal show={confirmBooking} onHide={handleconfirmBookingClose}
                    className="modal modl-PS responsive-mobile-modal confirmBooking-time" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleconfirmBookingClose()}></button>
                            </div>
                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <BsCalendarCheck className="calenderIcon-booking" />
                                        <h1 className="why-spam-heading "><span>Confirm Booking</span></h1>
                                        <div className="time-confirm-modal">{selectedTimeSlot?.startHour} - {selectedTimeSlot?.endHour}</div>
                                        <div className="day-confirm-modal">{selectedTimeSlot?.weekDays}, {moment(selectedTimeSlot?.date).format('LL')}</div>
                                        <div className="booking-button-end gap-3">
                                            <button className="spam_submit_button" onClick={() => handleconfirmBookingSubmit()}>Save</button>
                                            <button className="spam_submit_button" onClick={() => handleconfirmBookingClose()}>Cancel</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
}
export default Dashboard;




import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AutoComplete from "../../components/autocompleteservices/autoservice";
import Footers from "../../components/Footers/Footers";
import { ImCross } from 'react-icons/im';
import { ApiUrl, Getimage, getToken, removeUserSession, setRoleId, setRoleName, } from "../../utils/common";
import Loader from "../../components/Loader/loader";
import Headers from "../../components/header/header";
import imageCompression from 'browser-image-compression';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
const Profiles = () => {

    // upload image and preview
    const [imgfile, SetImageFile] = useState([]);
    const [profilePublic, setProfilePublic] = useState(false);
    const ImgFileHandler = async (event) => {
        const imageFile = event.target.files[0];
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 500,
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            setProfileImg(URL.createObjectURL(compressedFile), 'image url');
            const base64 = await imageCompression.getDataUrlFromFile(imageFile, options);
            SetImageFile(base64);
            setPhotoExtension(event?.target?.files[0].type.replace("image/", ""));
        } catch (error) {

        }
    }

    // all states Define
    const navigate = useNavigate();
    const [Designation, setDesignation] = useState('');
    const [Description, setDescription] = useState('');
    const [Anonymousname, setAnonymousName] = useState('');
    const [ProfileName, setProfileName] = useState('');
    const [Email, setEmail] = useState('');
    const [Profileimage, setProfileImg] = useState('');
    const [userName, setUserName] = useState('');
    const [userDesignation, setUserDesignation] = useState('');
    const [serviceList, setServiceList] = useState([]);
    const [inputValue, setInputValue] = useState();
    const [selectedServices, setSelectedServices] = useState([]);
    const [photoExtension, setPhotoExtension] = useState('');
    const [userImage, setUserImage] = useState('');
    // loader states worked on update button
    const [loading, setLoading] = useState(false);
    const [maxProfiledata, setMaxProfiledata] = useState(300);
    const [anonymousLength, setAnonymousLength] = useState(40);
    const [designationLength, setDesignationLength] = useState(40);
    const [anonymousNameVisible, setAnonymousNameVisible] = useState(false);
    const [profilelist, setProfilelist] = useState({});
    console.log(profilelist, 'profilelist59');
    const [consultationPoints, setConsultationPoints] = useState();
    const [showPlus, setShowPlus] = useState(true);
    const weekDays = [
        {
            label: "Sun",
            value: "Sunday"
        },
        {
            label: "Mon",
            value: "Monday"
        },
        {
            label: "Tue",
            value: "Tuesday"
        },
        {
            label: "Wed",
            value: "Wednesday"
        },
        {
            label: "Thu",
            value: "Thursday"
        },
        {
            label: "Fri",
            value: "Friday"
        },
        {
            label: "Sat",
            value: "Saturday"
        },
    ]
    const [selectedWeekDays, setSelectedWeekDays] = useState([]);

    const handleSelectedWeekDays = (item) => {
        const alreadySelected = selectedWeekDays?.find(i => i?.value === item?.value);
        if (alreadySelected?.value) {
            const unselectFiltered = selectedWeekDays?.filter(i => i?.value !== item?.value);
            setSelectedWeekDays(unselectFiltered)
        } else {
            setSelectedWeekDays([...selectedWeekDays, item])
        }
    }

    const [timeFields, setTimeFields] = useState([{
        StartTime: new Date(),
        EndTime: new Date(),
    }]);
    console.log(timeFields, 'timeFields107');

    const addTimeField = () => {
        if (timeFields?.length === 2) {
            setShowPlus(false)
        } else {
            setShowPlus(true)
        }
        setTimeFields([...timeFields, {
            StartTime: new Date(),
            EndTime: new Date(),
        }])
    }
    useEffect(() => {
        addTimeField()
    }, [])
    const removeTimeFields = (index) => {
        setShowPlus(true)
        const rows = [...timeFields];
        rows.splice(index, 1);
        setTimeFields(rows);
    }
    const handleChangeTimeField = (index, name, evnt) => {
        const list = [...timeFields];
        list[index][name] = new Date(evnt);
        setTimeFields(list);
    }
    // services api
    const getServices = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Service`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                const data = JSON.parse(e?.data?.data);
                setServiceList(data)
            })
            .catch((err) => {
                setLoading(false)

                toast.error(err?.data?.messsage);
                if (err?.request?.status === 401) {
                    removeUserSession();
                    navigate('/')
                }
            });
    }

    // profiles api
    const getProfiles = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Profile`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                var pro = {};
                pro = JSON.parse(e?.data?.data);
                console.log(pro, "opot");
                if (pro?.IsGoldMember) {
                    const availabilityofDays = pro?.Profile[0]?.AvailabilityDays?.split(",")
                    const selectedDays = weekDays.filter(el => {
                        return availabilityofDays.find(element => {
                            return element === el.value;
                        });
                    });
                    setSelectedWeekDays(selectedDays);
                    let time = pro.Profile[0].WorkingHours.map((i) => ({
                        StartTime: new Date(i.StartTime),
                        EndTime: new Date(i.EndTime),
                    }))
                    time.sort((a, b) => a.StartTime - b.StartTime);
                    setTimeFields(time)
                }
                pro.Profile = JSON.parse(e?.data?.data).Profile?.[0];
                setRoleName(pro?.Role?.RoleName)
                setRoleId(pro?.RoleId)
                pro.FullName = GetFullName(pro.Profile);
                setProfilelist(pro);
                setAnonymousName(pro?.Profile?.AnonymousName);
                setDescription(pro?.Profile?.Description);
                setUserDesignation(pro?.Profile?.Designation);
                setUserName(pro?.Profile?.ProfileName);
                setDesignation(pro?.Profile?.Designation);
                setProfileName(pro?.Profile?.ProfileName);
                setEmail(pro?.Profile?.Email);
                setProfileImg(`${Getimage(pro?.Profile?.ProfilePhotoUrl, "images/dummy.svg")}`);
                setUserImage(`${Getimage(pro?.Profile?.ProfilePhotoUrl, "images/dummy.svg")}`);
                setSelectedServices(pro?.Profile?.Services.split(','));
                SetImageFile(pro?.Profile?.ProfilePhoto);
                setProfilePublic(pro?.Profile?.ShowPublic);
                setConsultationPoints(pro?.Profile?.ConsultationFeePoint);


            })
            .catch((err) => {
                setLoading(false)
                toast.error(err?.data?.messsage);
                if (err?.message === "Network Error") {
                    removeUserSession();
                    navigate('/')
                    toast.error("Session Timeout Please Login Again")
                }

            });
    }
    const GetFullName = (d) => {
        var name = d.FirstName || "";
        if (d.MiddleName != null) name += (" " + d.MiddleName);
        if (d.LastName != null) name += (" " + d.LastName || "");
        return name;

    }
    // use effect call
    useEffect(() => {
        getProfiles();
        getServices();

    }, [])

    // data send press updatebutton
    const SaveData = () => {
        if (!ValidateInputs()) return;
        setLoading(true);
        const data = {};
        data.ProfileName = ProfileName;
        data.Anonymousname = Anonymousname;
        data.Designation = Designation;
        data.Description = Description;
        data.ProfileName = ProfileName;
        data.Email = Email;
        data.ProfilePhoto = imgfile?.length === 0 ? null : imgfile;
        data.PhotoExtenssion = photoExtension;
        data.Services = selectedServices.length === 0 ? null : selectedServices.join();
        data.ShowPublic = profilePublic;
        data.ConsultationFeePoint = consultationPoints;
        let availabilityofDays = "";
        for (let i = 0; i < selectedWeekDays?.length; i++) {
            availabilityofDays = availabilityofDays + "," + selectedWeekDays[i]?.value
        }
        data.AvailabilityDays = availabilityofDays?.slice(1, availabilityofDays?.length);
        let newTime = timeFields?.map(item => ({
            "StartTime": `${new Date(item?.StartTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`,
            "EndTime": `${new Date(item?.EndTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`,
        }))

        data.WorkingHours = newTime;
        const option = {
            method: 'PATCH', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Profile`,
        };
        axios(option)
            .then(e => {
                setLoading(false)
                getProfiles();
                if (e?.data?.success) {
                    toast.success(e?.data?.message)
                    navigate('/');
                }

            })
            .catch((err) => {
                setLoading(false)
                toast.error(err?.response?.data?.message);
            });
    }

    const onSelect = (val) => {
        if (selectedServices?.find(item => item.trim().toLowerCase() === val.trim().toLowerCase() || item.trim() === "")) {
            toast.error("Service Already Selected");
            return null
        }
        setInputValue('');
        setSelectedServices([...selectedServices, val])
    }

    // remove services click of cross button
    const onRemove = (val) => {
        let arr = [...selectedServices]
        const index = arr.indexOf(val);
        if (index > -1) {
            arr.splice(index, 1);
        }
        setSelectedServices(arr)
    }

    const handleClick = (val) => {
        if (inputValue.trim() === "") {
            toast.error("Please Enter Service")
            return;
        }
        if (selectedServices?.find(item => item?.trim() === inputValue.trim() || item.trim().toLowerCase() === inputValue.trim().toLowerCase())) {
            toast.error("Service Already Selected")
            return;
        }
        if (inputValue?.length) {
            setSelectedServices([...selectedServices, inputValue])
            setInputValue('');
        }

    }
    const handleDescription = (e) => {
        setDescription(e?.target?.value);

    }
    // validations 
    const ValidateInputs = () => {
        if (!ProfileName || ProfileName.trim() === "") {
            toast.error("Please Enter Your Name")
            return false;
        }

        // if (!consultationPoints) {
        //     toast.error("Please Enter your Consultation fee points")
        //     return false;
        //  }
        const StartTime = `${new Date(timeFields?.[0]?.StartTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        const EndTime = `${new Date(timeFields?.[0]?.EndTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`

        if (timeFields?.length === 1 && StartTime === EndTime) {
            toast.error("Start Time and End Time can not be equal.")
            return false;
        }
        if (selectedWeekDays?.length < 1) {
            toast.error("Please select Availability of Days")
            return false;
        }
        return true;
    }
    const inputref = useRef();
    const minTime = new Date();
    minTime.setHours(8, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(20, 0, 0)
    return (
        <>
            <div className="page-container">
                {
                    loading ? <Loader /> : null
                }

                <Headers innerPages={"innerPages"} />
                <section className="ps-profileyr">

                    <div className="text-center">
                        <h1 className="updat-yor">Update Your Profile</h1>
                    </div>
                    {/* <form> */}
                    <div className="profle-mndiv">
                        <div className="container border-btm">

                            <div className="row">
                                <div className="col-12 col-md-4 col-sm-4 col-lg-4 set-main-psn-img">
                                    <img src={Profileimage} alt="img" className="prfl-img" />
                                    <div className="edit-prfl">
                                        Edit
                                        <input type="file" className="input-file-profile" accept="image/*" onChange={ImgFileHandler}></input>
                                    </div>
                                </div>
                                <div className="col-12 col-md-8 col-sm-8 col-lg-8">

                                    <div className="frm-inpt-profle">
                                        <div className="row">
                                            {/* fullname */}
                                            <div className="col-md-6">
                                                <span className="frm-inpt-inr">
                                                    <label htmlFor="fname">Full Name</label>
                                                    <input type="text" id="fname" name="fname" placeholder="" onChange={e => setProfileName(e?.target?.value)} value={ProfileName}></input>

                                                </span>
                                            </div>

                                            {/* Anonymous name */}
                                            <div className="col-md-6">
                                                <span className="frm-inpt-inr">
                                                    <label htmlFor="Anonymous">Anonymous Name</label>
                                                    <div className="anonymous-input-section">
                                                        <input type={"text" && anonymousNameVisible ? 'text' : 'password'} id="Anonymous" name="Anonymous" autoComplete="off" maxLength={40} ref={inputref} onChange={e => setAnonymousName(e?.target?.value)} value={Anonymousname}></input>
                                                        {
                                                            anonymousNameVisible ? <AiOutlineEye className="eye-anonymousName" onClick={() => setAnonymousNameVisible(!anonymousNameVisible)} /> :
                                                                <AiOutlineEyeInvisible className="eye-anonymousName" onClick={() => setAnonymousNameVisible(!anonymousNameVisible)} />
                                                        }


                                                    </div>
                                                </span>
                                                {
                                                    Anonymousname ? <div className="maxprofiledata">{anonymousLength - Anonymousname.length} characters left</div> : <div className="maxprofiledata">40 characters left</div>
                                                }
                                            </div>

                                            {/* Designation */}
                                            <div className="col-md-6">
                                                <span className="frm-inpt-inr">
                                                    <label htmlFor="designation">Designation</label>
                                                    <input type="text" id="designation" maxLength={40} name="designation" placeholder="Enter your designation" onChange={e => setDesignation(e?.target?.value)} value={Designation}></input>
                                                </span>
                                                {
                                                    Designation ? <div className="maxprofiledata">{designationLength - Designation.length} characters left</div> : <div className="maxprofiledata">40 characters left</div>
                                                }

                                            </div>

                                            {/* Email */}
                                            <div className="col-md-6">
                                                <span className="frm-inpt-inr">
                                                    <label htmlFor="email">Email</label>
                                                    <input type="text" id="email" name="email" onChange={e => setEmail(e?.target?.value)} value={Email} disabled></input>

                                                </span>
                                            </div>


                                            <div className="col-md-12">
                                                <span className="frm-inpt-inr">
                                                    <label htmlFor="fname">Profile</label>

                                                    <input type="text" autoComplete="off" maxLength={300} id="prifileinfo" name="pinfo" placeholder="Enter you Info." onChange={handleDescription} value={Description}></input>

                                                </span>
                                                {
                                                    Description ? <div className="maxprofiledata">{maxProfiledata - Description.length} characters left</div> : <div className="maxprofiledata">300 characters left</div>
                                                }
                                            </div>

                                            <div className="col-md-6 align-itm profile-public-container">
                                                <span>Make Your Profile Public</span>
                                                <div className="btn-radio profile-public-show">
                                                    <div className="form-check">
                                                        <Form.Check
                                                            inline
                                                            label="Yes"
                                                            name="group2"
                                                            type={'radio'}
                                                            id={`inline-${'radio'}-3`}
                                                            onChange={() => setProfilePublic(true)} value={profilePublic}
                                                            checked={profilePublic === true}

                                                        />

                                                    </div>

                                                    <div className="form-check">
                                                        <Form.Check
                                                            inline
                                                            label="No"
                                                            name="group2"
                                                            type={'radio'}
                                                            id={`inline-${'radio'}-4`}
                                                            onChange={() => setProfilePublic(false)} value={profilePublic}
                                                            checked={profilePublic === false}

                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 consultation-profile">
                                                {
                                                    profilelist?.Id === 27 ? <span className="frm-inpt-inr">
                                                        <label htmlFor="consultation">Consultation Fee Points</label>
                                                        <input type="number" id="consultation" name="consultation" onChange={e => setConsultationPoints(e?.target?.value)} value={consultationPoints}></input>

                                                    </span> : null
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        {
                            profilelist?.IsGoldMember ?
                                <div className="profile-border-btm">
                                    <div className="servc-ps">
                                        <p className="srvc-txt">Working Hours</p>
                                        <div className="row p-4">
                                            <div className="col-md-10">
                                                <div className="frm-inpt-profle">
                                                    <div className="row">
                                                        {
                                                            timeFields?.length ? <>
                                                                {
                                                                    timeFields.map((item, index) => {
                                                                        return (
                                                                            <Fragment key={index}>
                                                                                <div className="col-md-5">
                                                                                    <div className="frm-inpt-inr profile-timepicker">
                                                                                        <label htmlFor="StartTime">Start Time</label>
                                                                                        <DatePicker
                                                                                            selected={item?.StartTime}
                                                                                            onChange={(evnt) => handleChangeTimeField(index, "StartTime", evnt)}
                                                                                            showTimeSelect
                                                                                            showTimeSelectOnly
                                                                                            timeIntervals={30}
                                                                                            timeCaption="Start Time"
                                                                                            dateFormat="h:mm aa"
                                                                                            minTime={minTime}
                                                                                            maxTime={maxTime}
                                                                                            className="booking-time-slot"
                                                                                        />
                                                                                        {/* <input type="text" id="StartTime" name="StartTime" onChange={(evnt) => handleChangeTimeField(index, evnt)} value={item?.StartTime}></input> */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <div className="frm-inpt-inr profile-timepicker">
                                                                                        <label htmlFor="EndTime">End Time</label>
                                                                                        <DatePicker
                                                                                            selected={item?.EndTime}
                                                                                            onChange={(evnt) => handleChangeTimeField(index, "EndTime", evnt)}
                                                                                            showTimeSelect
                                                                                            showTimeSelectOnly
                                                                                            timeIntervals={30}
                                                                                            timeCaption="End Time"
                                                                                            dateFormat="h:mm aa"
                                                                                            minTime={new Date(item?.StartTime?.getTime() + 30 * 60000)}
                                                                                            maxTime={maxTime}
                                                                                            className="booking-time-slot"
                                                                                            disabled={!(item?.StartTime)}
                                                                                        />
                                                                                        {/* <input type="text" id="EndTime" name="EndTime" onChange={(evnt) => handleChangeTimeField(index, evnt)} value={item?.EndTime}></input> */}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-2 StartTime-profile">
                                                                                    {(timeFields.length !== 1) ? <button className="btn btn-outline-primary removeOption-profile" onClick={() => removeTimeFields(index)}>x</button> : ''}
                                                                                </div>
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </> : null
                                                        }
                                                    </div>
                                                    {
                                                        showPlus && timeFields?.length === 2 ? <div className="row">
                                                            <div className="col-sm-12 new-option-poll">
                                                                Add More
                                                                <button className="btn btn-outline-primary removeOptionCross-profile" onClick={() => addTimeField()}>+</button>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-md-12 days-available"><span>Availability of Days</span></div>
                                            <div className="col-md-12 d-flex gap-4">

                                                {
                                                    weekDays?.map((item, index) => (
                                                        <div className={selectedWeekDays?.find(i => i?.value === item?.value) ? "selectedWeekDay" : "unselectedWeekDay"} key={index} onClick={() => handleSelectedWeekDays(item)}>{item?.label}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }
                        {/* container */}
                        <div className="servc-ps">
                            <p className="srvc-txt">Services</p>

                            <ul className="srvc-list">
                                <div className="profile-service-row">
                                    {
                                        selectedServices?.map((item, index) => (
                                            <div key={index} className="column-services">
                                                <li className="main-section-services-prev">
                                                    <a>
                                                        {item}
                                                        <span className="services-add-preview"
                                                            onClick={() => onRemove(item)}><ImCross className="cross-icon-service" /></span></a></li>
                                            </div>
                                        ))

                                    }

                                </div>


                            </ul>

                            {/* input add services  */}
                            <div className="input-services-services">
                                <AutoComplete value={inputValue}
                                    onValueChange={(e) => setInputValue(e?.target?.value)}
                                    onSelect={(val) => onSelect(val)} handleClick={(val) => handleClick(val)} options={serviceList} />
                            </div>
                        </div>
                        {/* Service section */}


                    </div>
                    {/* </form> */}
                    {/* profle-mndiv */}


                    <div className="servc-updt add-position">

                        <button className="updt-prfile button-loader" onClick={() => SaveData()}>Update Profile</button>
                    </div>
                </section>
                <Footers />
            </div>
        </>
    );
}

export default Profiles;
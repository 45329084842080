import React, { useEffect, useRef, useState } from 'react';
import Footers from '../../components/Footers/Footers';
import Headers from '../../components/header/header';
import Loader from '../../components/Loader/loader';

const HowItWorksNew = () => {
    const [loading, setLoading] = useState(false);
    const [visibleSection, setVisibleSection] = useState();
    const headerRef = useRef(null);
    const tutorialOne = useRef(null);
    const tutorialTwo = useRef(null);
    const tutorialThree = useRef(null);
    const tutorialFour = useRef(null);
    const tutorialFive = useRef(null);
    const tutorialSix = useRef(null);
    const tutorialSeven = useRef(null);
    const getDimensions = (e) => {
        const { height } = e.getBoundingClientRect();
        const offsetTop = e.offsetTop + 200;
        const offsetBottom = offsetTop + height;

        return {
            height,
            offsetTop,
            offsetBottom,
        };
    };

    const sectionRefs = [
        { section: "tutorial-1", ref: tutorialOne },
        { section: "tutorial-2", ref: tutorialTwo },
        { section: "tutorial-3", ref: tutorialThree },
        { section: "tutorial-4", ref: tutorialFour },
        { section: "tutorial-5", ref: tutorialFive },
        { section: "tutorial-6", ref: tutorialSix },
        { section: "tutorial-7", ref: tutorialSeven },
    ];
    const handlescroll = (element) => {
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    useEffect(() => {
        const handleScrollfn = () => {
            const { height: headerHeight } = getDimensions(headerRef.current);
            const scrollPosition = window.scrollY + headerHeight;

            const selected = sectionRefs.find(({ section, ref }) => {
                const ele = ref.current;
                if (ele) {
                    const { offsetBottom, offsetTop } = getDimensions(ele);
                    return scrollPosition > offsetTop && scrollPosition <= offsetBottom;
                }
            });

            if (selected && selected.section !== visibleSection) {
                setVisibleSection(selected.section);
            } else if (!selected && visibleSection) {
                setVisibleSection(undefined);
            }
        };

        handleScrollfn();
        window.addEventListener("scroll", handleScrollfn);
        return () => {
            window.removeEventListener("scroll", handleScrollfn);
        };
    }, [visibleSection]);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="page-container">
                {
                    loading ? <Loader /> : null
                }

                <Headers innerPages={"innerPages"} />
                <section>
                    <div className='tutorial-heading'>
                        How it Works
                    </div>
                </section>
                <section className='how-it-works-2-cont'>
                    <div className='container'>
                        <div className="row tutorial-inner" >
                            <div className="col-2 tutorial-step-column" >
                                <div id="simple-list-example" className="d-flex flex-column tutorial-links-section" ref={headerRef}>
                                    <button className={`tutorial-links ${visibleSection === "tutorial-1" ? "selected-tutorial" : ""}`} onClick={() => {
                                        handlescroll(tutorialOne.current);
                                    }}>Step 1</button>
                                    <button className={`tutorial-links ${visibleSection === "tutorial-2" ? "selected-tutorial" : ""}`} onClick={() => {
                                        handlescroll(tutorialTwo.current);
                                    }} >Step 2</button>
                                    <button className={`tutorial-links ${visibleSection === "tutorial-3" ? "selected-tutorial" : ""}`} onClick={() => {
                                        handlescroll(tutorialThree.current);
                                    }} >Step 3</button>
                                    <button className={`tutorial-links ${visibleSection === "tutorial-4" ? "selected-tutorial" : ""}`} onClick={() => {
                                        handlescroll(tutorialFour.current);
                                    }} >Step 4</button>
                                    <button className={`tutorial-links ${visibleSection === "tutorial-5" ? "selected-tutorial" : ""}`} onClick={() => {
                                        handlescroll(tutorialFive.current);
                                    }} >Step 5</button>
                                    <button className={`tutorial-links ${visibleSection === "tutorial-6" ? "selected-tutorial" : ""}`} onClick={() => {
                                        handlescroll(tutorialSix.current);
                                    }} >Step 6</button>
                                    <button className={`tutorial-links ${visibleSection === "tutorial-7" ? "selected-tutorial" : ""}`} onClick={() => {
                                        handlescroll(tutorialSeven.current);
                                    }} >Step 7</button>

                                </div>
                            </div>
                            <div className="col-10 tutorial-data-column">
                                <div>

                                    <div ref={tutorialOne} id="tutorial-1" className='tutorial-container'>
                                        <h2 className='tutorial-step'>
                                            Step 1
                                        </h2>
                                        <div className='tutorial-head-inner'>Raise Queries Or Answer Queries
                                        </div>
                                        <p className='tutorial-data'><span>(i)</span>Once your profile is updated you can now click on “My Queries”. Here you can ask your own queries to people or you can answer the queries raised to you.</p>
                                        <p className='tutorial-data'><span>(ii)</span>Write a title in the “Page title” position and write your query in the “Paragraph” position.</p>
                                        <p className='tutorial-data'><span>(iii)</span>Select either ‘Public’ (If you want your query to be visible to everyone), ‘Private’ (To share the query only to the person you want).</p>
                                        <p className='tutorial-data'><span>(iv)</span>Select ‘Name’ (If you want your name to be seen), ‘Anonymous’ (If you want to keep your name anonymous).</p>
                                        <p className='tutorial-data'><span>(v)</span>Select the user you want to share your query with and click on submit</p>
                                        <br />
                                        <div className='tutorial-conainer-inner'>
                                            <div className='row w-100'>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-3(i).jpg' alt="step-3" /></div>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-4(i).jpg' alt="step-4" /></div>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-4(ii).jpg' alt="step-4" /></div>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-4(iii).jpg' alt="step-4" /></div>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-5(i).jpg' alt="step-5" /></div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='tutorial-separator-page2'></div>
                                    <div ref={tutorialTwo} id="tutorial-2" className='tutorial-container'>
                                        <h2 className='tutorial-step'>
                                            Step 2
                                        </h2>
                                        <div className='tutorial-head-inner'>Go to the ‘Articles’ to read the published articles
                                        </div>
                                        <p className='tutorial-data'><span>(i)</span>Click on the ‘Articles’ to read the published articles.</p>
                                        <p className='tutorial-data'><span>(ii)</span>Once you click on the ‘Articles’ a new page will open up from where you can choose to read different articles published by various people. Click on the ‘Read more’ option to read the full article.</p>
                                        <p className='tutorial-data'><span>(iii)</span>After clicking on ‘Read More’ option, the Selected article will open up and you can Read the full article.</p>
                                        <br />
                                        <div className='tutorial-conainer-inner'>
                                            <div className='row w-100'>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-6(i).jpg' alt="step-6" /></div>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-6(ii).jpg' alt="step-6" /></div>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-6(iii).jpg' alt="step-6" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='tutorial-separator-page2'></div>

                                    <div ref={tutorialThree} id="tutorial-3" className='tutorial-container'>
                                        <h2 className='tutorial-step'>
                                            Step 3
                                        </h2>
                                        <div className='tutorial-head-inner'>Write your own articles
                                        </div>
                                        <p className='tutorial-data'><span>(i)</span>Click on ‘My Articles’ option to write your own articles.</p>
                                        <p className='tutorial-data'><span>(ii)</span>Write the title of your article on the ‘Post Title’ place and start writing your article in the ‘Paragraph’ place.</p>
                                        <p className='tutorial-data'><span>(iii)</span>Once you complete writing your article click on ‘Submit’.</p>
                                        <br />
                                        <div className='tutorial-conainer-inner'>
                                            <div className='row w-100'>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-7(i).jpg' alt="step-7" /></div>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-7(ii).jpg' alt="step-7" /></div>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-7(iii).jpg' alt="step-7" /></div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='tutorial-separator-page2'></div>

                                    <div ref={tutorialFour} id="tutorial-4" className='tutorial-container'>
                                        <h2 className='tutorial-step'>
                                            Step 4
                                        </h2>
                                        <div className='tutorial-head-inner'>Click on ‘Discussion’
                                        </div>
                                        <p className='tutorial-data'><span>(i)</span>To read all the public discussions click on the ‘Discussions’ option.</p>
                                        <br />
                                        <div className='tutorial-conainer-inner'>
                                            <div className='row w-100'>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-8(i).jpg' alt="step-8" /></div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='tutorial-separator-page2'></div>

                                    <div ref={tutorialFive} id="tutorial-5" className='tutorial-container'>
                                        <h2 className='tutorial-step'>
                                            Step 5
                                        </h2>
                                        <div className='tutorial-head-inner'>Click on the ‘Mood Chart’ option to check your mood for the day
                                        </div>
                                        <p className='tutorial-data'><span>(i)</span>Click on the smiley (Smile, Sad, Laugh, Angry or Heart) to select your mood according to your preference.</p>
                                        <p className='tutorial-data'><span>(ii)</span>Once you select your mood, it will be updated. It can be changed again after 24 hours.</p>
                                        <br />
                                        <div className='tutorial-conainer-inner'>
                                            <div className='row w-100'>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-9.jpg' alt="step-9" /></div>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-9(i).jpg' alt="step-9" /></div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='tutorial-separator-page2'></div>

                                    <div ref={tutorialSix} id="tutorial-6" className='tutorial-container'>
                                        <h2 className='tutorial-step'>
                                            Step 6
                                        </h2>
                                        <div className='tutorial-head-inner'>Edit or Delete your Query and your Article
                                        </div>
                                        <p className='tutorial-data'><span>(i)</span>The user can ‘Edit’ or ‘Delete’ their query or article only until someone has responded to their queries or articles.</p>
                                        <br />
                                        <div className='tutorial-conainer-inner'>
                                            <div className='row w-100'>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-10(i).jpg' alt="step-10" /></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='tutorial-separator-page2'></div>

                                    <div ref={tutorialSeven} id="tutorial-7" className='tutorial-container'>
                                        <h2 className='tutorial-step'>
                                            Step 7
                                        </h2>
                                        <div className='tutorial-head-inner'>Mark ‘Spam’
                                        </div>
                                        <p className='tutorial-data'><span>(i)</span>You can mark spam by selecting ‘Mark As Spam’ to the queries raised to you only if the query raised is anonymous.</p>
                                        <p className='tutorial-data'><span>(ii)</span>You have to provide the reason why you are marking the particular query as a spam and click on submit.</p>
                                        <br />
                                        <div className='tutorial-conainer-inner'>
                                            <div className='row w-100'>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-11(i).jpg' alt="step-11" /></div>
                                                <div className='col-md-6 pb-4'><img className="tutorial-image" src='/images/howitworks/step-11(ii).jpg' alt="step-11" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='tutorial-separator-page2'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footers />
            </div>
        </>
    )
}

export default HowItWorksNew;
import Aos from "aos";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Footers from "../../components/Footers/Footers";
import Headers from "../../components/header/header";
import { ApiUrl, Getimage, getToken } from "../../utils/common";

const BlockedUsers = () => {

    const [blockedUser, setBlockedUser] = useState([]);
    const noBlockedUsers = blockedUser.filter(item =>item?.UserId !== null && item?.Status === "Pending")
    const getBlockedUSers = () => {
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Admin/UsersRequestList`
        };
        axios(option)
            .then(e => {

                const data = JSON.parse(e?.data?.data)
                setBlockedUser(data)
            })
            .catch((err) => {
            });
    }

    useEffect(() => {
        getBlockedUSers();
    }, [])


    const ActiveInactiveUser = (item) => {
        const data = {};
        data.Id = item?.UserId;
        data.Active = "true";
        data.UserRequestId = item?.Id;
        const option = {
            method: 'PATCH', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },

            data: { "data": JSON.stringify(data) },
            url: `${ApiUrl()}/Admin/EnableDisableUser`
        }
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    getBlockedUSers();
                }
            })
            .catch((err) => {
            })

    }


    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, [])


    return (
        <>
            <div className="page-container">
                <Headers innerPages={"innerPages"} />
                <section className="bredcrum-ps">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active add-mr-right" aria-current="page"> <a href="/#/blockedUsers">Blocked Users</a></li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section className="dashbord-strt py-5 blocked-user-start">
                    <div className="container">
                        <div>
                            {
                                noBlockedUsers?.length === 0 ?
                                    <div className="no-search-section">
                                        <div className="no-query-section-two">
                                            <h2>No Account Activation Requests found.</h2>
                                        </div>
                                    </div> :
                                    null
                            }


                            <div className="row dashboard-resp-row blocked-resp-user">

                                {
                                    blockedUser.map((item) => (
                                        <Fragment key={item?.Id}>
                                            {
                                                item?.UserId != null && item?.Status === "Pending" ? <div data-aos="zoom-in-down" data-aos-easing="ease-in-sine" data-aos-duration="500" className="col-xs-12 col-md-3 col-sm-3 col-lg-3 col-xl-3 mb-20 set-in-mob set-intab" >
                                                    <div className="admin-dashbord-box text-center">
                                                        <div className="box-card-user-dsbr">
                                                            <img src={`${Getimage(item?.ProfilePhotoUrl, "images/dummy.svg")}`} onError={(e)=>{e.target.src="images/dummy.svg"}} alt="User Img." className="image-main-cards-dshbord change-dashboard" />
                                                            <h1>{item?.ProfileName}</h1>
                                                        </div>
                                                        <p className="clr-chng mt-2"><b>Status: </b>{item?.Status}</p>
                                                        <p className="profile-info-data-dashboard mt-2">{item?.Comment.trim()?.substr(0, 185)} </p>
                                                        <div className="request-active-blocked">
                                                            <button className="active-blocked-button" onClick={() => { ActiveInactiveUser(item) }}>Make Active</button>
                                                        </div>
                                                    </div>
                                                </div> : null
                                            }
                                        </Fragment>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </section>
                <Footers />
            </div>
        </>
    )
}

export default BlockedUsers;

import './App.css';
import Dashboard from './components/dashboard/dashboard';
import Quaries from './pages/queries/queries';
import Articles from './pages/Articles/Articles';
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Routes
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Profiles from '../src/pages/profile/profile';
import AutoService from './components/autocompleteservices/autoservice';
import Post from './pages/post/post';
import UserQuery from './pages/userQuery/userquery';
import Discussion from './pages/discussion/discussion';
import ArticleView from './pages/articleView/ArticleView';
import SpamQueries from './pages/queries/spamquery';
import HowItWorksNew from './pages/howitworks/howitworksnew';
import BlockedUsers from './pages/blockedUsers/blockedUsers';
import Polling from './pages/polling/polling';
import ListPoll from './pages/polling/listPoll';
import Booking from './pages/Booking/Booking';
import BookingNew from './pages/Booking/BookingNew';
function App() {


  return (
    <>

      <ToastContainer theme='colored' />
      <HashRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/login" element="" />
          <Route path="/queries" element={<Quaries />} />
          <Route path="/Articles" element={<Articles />} />
          <Route path="/ArticleView" element={<ArticleView />} />
          <Route path="/profile" element={<Profiles />} />
          <Route path="/autoservice" element={<AutoService />} />
          <Route path="/articlepost" element={<Post />} />
          <Route path="/userquery" element={<UserQuery />} />
          <Route path='/publicDiscussion' element={<Discussion />} />
          <Route path="/spamqueries" element={<SpamQueries />} />
          <Route path="/howitworks" element={<HowItWorksNew />} />
          <Route path="/blockedUsers" element={<BlockedUsers />} />
          <Route path="/polling" element={<Polling />} />
          <Route path="/listpolling" element={<ListPoll />} />
          <Route path="/Booking" element={<Booking />} />
          <Route path="/Bookingnew" element={<BookingNew />} />
        </Routes>
      </HashRouter>
      <Router>

      </Router>
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footers from "../../components/Footers/Footers";
import Headers from "../../components/header/header";
import { ApiUrl, Getimage, GetRoleName, getToken } from "../../utils/common";
import axios from "axios";
import Loader from "../../components/Loader/loader";
import Aos from 'aos';
import Slider from "react-slick";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";


function Articles() {
    const ArticleViewPath = window?.location?.hash;
    const [articlesData, setArticleData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deletePostModal, setDeletePostModal] = useState(false);
    const handleMainDeleteClose = () => setDeletePostModal(false);
    const [deletePostData, setDeletePostData] = useState('');
    const navigate = useNavigate();
    const settings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 2,
                    dots: true,
                    arrows: false,
                    appendDots: dots => <ul>{dots}</ul>,
                    customPaging: i => (
                        <div className="ft-slick-dots-custom">
                        </div>
                    )
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 2,
                    dots: true,
                    arrows: false,
                    appendDots: dots => <ul>{dots}</ul>,
                    customPaging: i => (
                        <div className="ft-slick-dots-custom">
                        </div>
                    )
                }
            }
        ]
    };

    const ArticlesGet = () => {
        setLoading(true)
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            url: `${ApiUrl()}/Article/GetUserArticles`
        };
        axios(option)
            .then((e) => {
                setLoading(false)
                const data = JSON.parse(e?.data?.data)
                setArticleData(data)

            })
            .catch((err) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        ArticlesGet()
    }, [])

    const ReadMoreArticle = (item) => {
        navigate('/ArticleView', { state: item })
    }
    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, [])

    const getAdminRole = GetRoleName();
    const DeletePost = (item) => {
        setDeletePostModal(true);
        setDeletePostData(item)

    }
    const DeleteQuery = () => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { 'Data': deletePostData?.Id },
            url: `${ApiUrl()}/Article/DeleteArticle`
        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    handleMainDeleteClose();
                    toast.success(e?.data?.message);
                    ArticlesGet();
                }
            })
            .catch(err => {

            })
    }


    return (
        <>
            <div className="page-container">
                {
                    loading ? <Loader /> : null
                }

                <Headers innerPages={"innerPages"} ArticleViewPath={ArticleViewPath} />
                <section className="bredcrum-ps">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page"> Articles</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {/* breadcrumb */}

                <section className="article-ps">
                    <div className="container">
                        {articlesData?.length === 0 ?
                            <div className="no-query-section">
                                <div className="no-query-section-two">
                                    <h2>You have not published any articles.</h2>
                                    <p>To publish articles, go back to post articles page.</p>
                                    <Link className="no-query-link" to="/articlepost">Go Back</Link>
                                </div>
                            </div>
                            :

                            <div className="row articles-slider">

                                <Slider {...settings}>
                                    {
                                        articlesData?.map((item, index) => {
                                            return (
                                                item?.Active && item?.Publish ?
                                                    <div key={index} className="view-article-box col-md-4 col-sm-4">
                                                        <div className="article-box p-0" >
                                                            <img src={`${Getimage(item?.ImageUrl, "images/no-image.png")}`}  onError={(e)=>{e.target.src="images/no-image.png"}} onClick={() => ReadMoreArticle(item)}className="grl-musicn" alt="img" />
                                                            <div className="pding-20" onClick={() => ReadMoreArticle(item)}>
                                                                <h2>{item?.Title}</h2>
                                                                <p dangerouslySetInnerHTML={{ __html: `${item?.Content.trim().substring(0, 140)}${item?.Content?.length > 140 ? "..." : ""}` }}></p>
                                                            </div>
                                                            <div className="article-bx-inr flex-center-add">
                                                                <div className="col-md-7 img-name-articles" onClick={() => ReadMoreArticle(item)}>
                                                                    <img src={`${Getimage(item?.ProfilePhotoUrl,"images/dummy.svg")}`} alt="img" />
                                                                    <h5>{item?.ProfileName}</h5>
                                                                </div>
                                                                <div className="col-md-5 article-btn-container">
                                                                    <button type="button" className="btn btn-primary btn readmore" onClick={() => ReadMoreArticle(item)}>Read More</button>
                                                                    {
                                                                        getAdminRole === "Admin" ?
                                                                            <>
                                                                                {/* Article Delete Section */}
                                                                                <div className="delete-icon-article-outer"><RiDeleteBin6Line className="delete-icon-article" onClick={() => DeletePost(item)} /></div>
                                                                            </> : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null

                                            )
                                        })
                                    }
                                </Slider>

                            </div>
                        }


                    </div>

                </section>

                <Footers />
                <Modal show={deletePostModal} onHide={handleMainDeleteClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleMainDeleteClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading delete-modal-heading"><span>Are</span> you sure want to delete this Article?</h1>
                                        <div className="why-spam-btn">
                                            <button className="btn btn-primary button-delete-modal" onClick={() => DeleteQuery()}>
                                                Yes
                                            </button>

                                            <button className="btn btn-danger button-delete-modal-No" onClick={() => handleMainDeleteClose()}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default Articles;
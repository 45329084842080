
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { AiFillDelete, AiFillEdit, AiFillLike, AiOutlineLike } from "react-icons/ai";
import { GoCommentDiscussion } from "react-icons/go";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiUrl, Getimage, GetRoleName, getToken } from "../../utils/common";
import Footers from "../../components/Footers/Footers";
import Loader from '../../components/Loader/loader';
import Headers from "../../components/header/header";
import moment from "moment";
import { RiDeleteBin6Line } from "react-icons/ri";
import Aos from 'aos';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Discussion = () => {
    // all states
    const location = useLocation();
    const selectedData = location?.state?.data
    const DiscussionPath = window?.location?.hash;
    const [profilelist, setProfilelist] = useState([]);
    const [profilelistUser, setProfilelistUser] = useState([])
    const [userData, setUserData] = useState('');
    const findItemData = profilelistUser?.find((i) => i?.Id === selectedData?.Id)
    const [cardData, setCardData] = useState([])
    const [Profileimage, setProfileImg] = useState('');
    const [userName, setUserName] = useState('');
    const [userDesignation, setUserDesignation] = useState('');
    const navigate = useNavigate();
    const [getQuery, setGetQuery] = useState([]);
    const [show, setShow] = useState();
    const [replyId, setReplyId] = useState(0);
    const [queryReply, setQueryReply] = useState();
    const [loading, setLoading] = useState(false);
    const [queriesList, setQueriesList] = useState([]);
    const [showSpam, setShowSpam] = useState(false);
    const handlespamClose = () => setShowSpam(false);
    const handlespamShow = () => setShowSpam(true);
    const [spamMarkData, setSpamMarkData] = useState();
    const [spamReason, setSpamReason] = useState();
    const [spamSucessData, setSpamSuccessData] = useState();
    const [deleteQueryModal, setDeleteQueryModal] = useState(false);
    const handleDeleteClose = () => setDeleteQueryModal(false);
    const [deleteDataStore, setDeleteDataStore] = useState('');
    const [tabsValue, setTabsValue] = useState();
    const ActiveData = getQuery.filter(item => item?.Active)
    const PublicData = ActiveData.filter(item => !item?.Private)

    const [spamMainThreadData, setSpamMainThreadData] = useState('');
    const [spamMainThreadShow, setSpamMainThreadShow] = useState(false);
    const handleQuerySpamClose = () => {
        setSpamMainThreadShow(false);
    }
    const handleQuerySpamShow = () => {
        setSpamMainThreadShow(true);
    }
    const [threadSpamReason, setThreadSpamReason] = useState();
    const [deleteMainQueryModal, setDeleteMainQueryModal] = useState(false);
    const handleMainDeleteClose = () => setDeleteMainQueryModal(false);
    const [deleteMainThreadData, setDeleteMainThreadData] = useState('');
    const [queryResponseId, setQueryResponseId] = useState('');
    const [editQueryResp, setEditQueryResp] = useState()

    var date = new Date()
    var exactDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();


    const DeleteModal = (qResp) => {
        setDeleteQueryModal(true)
        setDeleteDataStore(qResp?.Id)
    }

    const DeleteMainThread = (item) => {
        setDeleteMainQueryModal(true);
        setDeleteMainThreadData(item)
    }

    useEffect(() => {
        const findIndexItem = cardData?.find(item => item?.Id === selectedData?.Id);
        if (findIndexItem) return;
        if (findItemData?.Id) {
            setCardData([...cardData, findItemData])
        }
    }, [findItemData])

    // editor state 
    const data = "";
    const getMainProfiles = () => {
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "data": data },
            url: `${ApiUrl()}/User`
        };
        axios(option)
            .then(e => {
                const data = JSON.parse(e?.data?.data || '{}');
                setProfilelistUser(data);
                setUserData()

            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            });
    }

    // get profile api
    const getProfiles = () => {
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Profile`
        };
        axios(option)
            .then(e => {
                var pro = {};
                pro.Profile = JSON.parse(e?.data?.data).Profile?.[0];
                pro.FullName = GetFullName(pro.Profile);

                setProfilelist(pro);
                setUserDesignation(pro?.Profile?.Designation);
                setUserName(pro?.Profile?.ProfileName);
                setProfileImg(`${Getimage(pro?.Profile?.ProfilePhotoUrl)}`);
            })
            .catch((err) => {
                navigate('/');
            });
    }

    const GetFullName = (d) => {
        var name = d.FirstName || "";
        if (d.MiddleName != null) name += (" " + d.MiddleName);
        if (d.LastName != null) name += (" " + d.LastName || "");
        return name;
    }

    useEffect(() => {
        getMainProfiles();
        getProfiles();
        getQueries();
    }, [])

    // queries display api
    const getQueries = () => {
        setLoading(true)
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Queries/GetUserQueries`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                setGetQuery(data);

            })
            .catch((err) => {
                setLoading(false)
            })
    }
    //  query like api
    const LikeSubmit = (el, Id) => {
        setLoading(true);
        const data = {};
        data.QueryId = Id;
        data.LikeOnDate = new Date();
        var flag = (el.currentTarget.attributes['likeit'].value === 'true');
        data.IsUnlike = !flag;

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/LikeQuery`

        };
        axios(option)
            .then(e => {
                setLoading(false);
                const data = JSON.parse(e?.data?.data || '{}');
                getQueries();

            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.message);


            });
    }

    const LikeQueryResponse = (el, qr) => {
        setLoading(true)
        const data = {};
        data.ResponseId = qr?.Id;
        data.LikeOnDate = exactDate;
        var flag = (el.currentTarget.attributes['likeit'].value === 'true');
        data.IsUnlike = !flag;

        const option = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/LikeResponse`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                qr.LikeCount = data?.LikeCount;
                qr.IsLike = !qr.IsLike;
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
                setLoading(false)
            });
    }

    const ReplyToQuery = (id, el) => {
        if (!QueryReplyValidate()) return
        const data = {};
        data.QueryId = id;
        data.Response = queryReply;
        data.ResponseDate = exactDate;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/ResponseToQuery`
        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    setQueryReply('');
                    getQueries();
                    toast.success(e?.data?.message);
                    getQueryResponse(id);
                }
            })
            .catch((err) => {
                toast.error(err?.data?.messsage);
            });
    }

    const QueryReplyValidate = () => {
        if (!queryReply || queryReply.trim() === "") {
            toast.error("Please Enter Query Reply")
            return false;
        }
        return true;
    }

    const getQueryResponse = (id) => {
        setLoading(true)
        const Data = {};
        Data.data = id;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: JSON.stringify(Data),
            url: `${ApiUrl()}/Queries/QueryResponse`
        };
        axios(option)
            .then(e => {
                setLoading(false)
                const data = JSON.parse(e?.data?.data || '{}');
                setQueriesList(data);
            })
            .catch(err => {
                setLoading(false)
                toast.error(err?.data?.messsage);
            })
    }

    const spamMark = (qResp) => {
        setSpamMarkData(qResp)
        handlespamShow();
    }

    const spamMarkValidate = () => {
        if (!spamReason || spamReason?.trim() === '') {
            toast.error("Please Enter Spam Reason")
            return false;
        }
        return true;
    }

    const markSpamSubmit = () => {
        if (!spamMarkValidate()) return;
        const data = {}
        data.QueryId = spamMarkData?.QueryId;
        data.ResponseId = spamMarkData?.Id
        data.SpamOnDate = exactDate;
        data.ReasonToMarkspam = spamReason;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/MarkResponseAsSpam`,
        };
        axios(option)
            .then(e => {
                if (e?.status === 200) {
                    handlespamClose();
                    toast.success(e?.data?.message)
                    setSpamSuccessData(e);
                    getQueryResponse(queryResponseId);
                    setSpamReason('');
                }
            })
            .catch(err => {
                toast.error(err?.response?.data?.message);
            })
    }

    // delete userquery api
    const DeleteUserQuery = () => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { 'Data': deleteDataStore },
            url: `${ApiUrl()}/Queries/DeleteQueryResponse`,
        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    handleDeleteClose();
                    window?.location?.reload();

                }
            })
            .catch(err => {
            })
    }

    const DeleteQuery = () => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { 'Data': deleteMainThreadData?.Id },
            url: `${ApiUrl()}/Queries/DeleteQuery`
        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    handleMainDeleteClose();
                    toast.success(e?.data?.message);
                    getQueries();
                }
            })
            .catch(err => {

            })
    }


    useEffect(() => {
        Aos.init();
    }, []);

    useEffect(() => {
        Aos.init();
    }, [tabsValue])


    const QueryThreadSpam = (item) => {
        setSpamMainThreadData(item);
        handleQuerySpamShow();
    }

    const QueryFieldValidate = () => {
        if (!threadSpamReason || threadSpamReason.trim() === '') {
            toast.error("Please Enter Spam Reason");
            return false;
        }
        return true;
    }

    const MainQuerySpam = () => {
        if (!QueryFieldValidate()) return;
        const data = {};
        data.QueryId = spamMainThreadData?.Id;
        data.SpamOnDate = exactDate;
        data.ReasonToMarkspam = threadSpamReason;
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/MarkQueryAsSpam`,
        };
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    handleQuerySpamClose();
                    getQueries();
                    setThreadSpamReason('');
                }
            })
            .catch(err => {

            })
    }

    const ThreadUnspam = (item) => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(item?.Id) },
            url: `${ApiUrl()}/Queries/UnspamQuery`,
        };
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    getQueries();
                }
            })
            .catch(err => {

            })
    }

    const unSpamResponse = (qResp) => {
        const option = {
            method: 'DELETE', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(qResp?.Id) },
            url: `${ApiUrl()}/Queries/UnspamResponse`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.success) {
                    toast.success(e?.data?.message);
                    getQueryResponse(queryResponseId);
                }

            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
    }

    const handleEditQuery = (item) => {
        navigate('/queries', { state: { editData: item } })
    }
    const handleEditQueryResponse = (item) => {
        setEditQueryResp(item)
        setQueryReply(item?.Response)
    }
    const EditReplyToQuery = () => {
        if (!QueryReplyValidate()) return;
        setLoading(true);
        const data = {};
        data.QueryId = editQueryResp?.QueryId;
        data.Response = queryReply;
        data.ResponseDate = exactDate;
        data.Id = editQueryResp?.Id

        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "Data": JSON.stringify(data) },
            url: `${ApiUrl()}/Queries/ResponseToQuery`

        };
        axios(option)
            .then(e => {
                if (e?.data?.success) {
                    getQueryResponse(editQueryResp?.QueryId);
                    setQueryReply('');
                    setEditQueryResp([])
                    getQueries();
                    toast.success(e?.data?.message);
                }
            })
            .catch((err) => {
                toast.error(err?.data?.messsage);
            });
    }
    const getRoleAdmin = GetRoleName()
    return (
        <>
            <div className="page-container">
                {
                    loading ? <Loader /> : null
                }
                <Headers innerPages={"innerPages"} DiscussionPath={DiscussionPath} />
                <section className="bredcrum-ps">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active add-mr-right" aria-current="page"> <a href="/#/publicDiscussion">Discussion</a></li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section className="quries-clrfix">
                    <div className="container userquery-tab-container">
                        <div className={`row ${ActiveData.length === 0 ? "tab-row-set" : "tab-row-set-new"}`}>
                            <div className="col-md-9">
                                {
                                    PublicData.length === 0 ?
                                        <div className="no-query-section">
                                            <div className="no-query-section-two">
                                                <h2>You have no queries.</h2>
                                                <p>To ask query, select a member and ask a question.</p>
                                                <Link className="no-query-link" to="/">Select a Member</Link>
                                            </div>
                                        </div>
                                        :

                                        <Tabs
                                            defaultActiveKey={"public"}
                                            id="uncontrolled-tab-example"
                                            onSelect={(e) => setTabsValue(e)}
                                            className="mb-3"
                                        >

                                            {
                                                PublicData.length === 0 ? null :
                                                    <Tab eventKey="public" title="Public Messages">
                                                        <div className="row">
                                                            <div className="col-12 set-width-on-tab new-get-query-div">
                                                                <div className="row">
                                                                </div>

                                                                {
                                                                    getQuery?.map((item, index) => {
                                                                        return (

                                                                            <Fragment key={index}>
                                                                                {

                                                                                    item?.Private === false && item?.Active && item?.SaveAsDraft === false ? <>

                                                                                        <div data-aos="flip-down" data-aos-easing="ease-in-sine" data-aos-duration="400" className="pd-on-mob">
                                                                                            <div className={`row ${PublicData.length >= 1 ? "new-height-private" : "auto-height-private"}`}>
                                                                                                <div className="ps-name-box">
                                                                                                    <img src={item?.Anonymous ? "images/anonymoususer.png" : `${Getimage(item?.ProfilePhotoUrl, "images/dummy.svg")}`} alt="User Img." className="image-main-cards-dshbord" />
                                                                                                    <div className="title-text new-title-text">
                                                                                                        <div>
                                                                                                            <h1>{item?.ProfileName === "" ? "Anonymous" : item?.ProfileName} - {item?.Title}</h1>
                                                                                                            {
                                                                                                                item.Private ? <img src="images/lock-in-a-circle.png" className="anonymous-img-queries new-private-img-queries" tabIndex="0" data-toggle="tooltip" title="Private Query" /> : null
                                                                                                            }
                                                                                                            {
                                                                                                                item?.Anonymous ? <img src="images/anonymous.jpg" className="anonymous-img-queries" tabIndex="0" data-toggle="tooltip" title="Anonymous User" /> : null
                                                                                                            }
                                                                                                            {
                                                                                                                getRoleAdmin === "Admin" ?
                                                                                                                    <>
                                                                                                                        {/* Query Delete Section */}
                                                                                                                        <div className="delete-icon-query-outer" onClick={() => DeleteMainThread(item)}><RiDeleteBin6Line className="delete-icon-article" /></div>
                                                                                                                    </>
                                                                                                                    : null
                                                                                                            }

                                                                                                            {
                                                                                                                profilelist?.Profile?.UserId === item?.OwnerId && item?.ReplyCount < 1 ? <AiFillEdit className="edit-icon-query" onClick={() => handleEditQuery(item)} tabIndex="0" data-toggle="tooltip" title="Edit Query" /> : null
                                                                                                            }

                                                                                                            {
                                                                                                                profilelist?.Profile?.UserId === item?.OwnerId && item?.ReplyCount < 1 ? <AiFillDelete className="delete-icon-query-final" onClick={() => DeleteMainThread(item)} tabIndex="0" data-toggle="tooltip" title="Delete Query" /> : null
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className="query-text-figure" dangerouslySetInnerHTML={{ __html: item?.QueryText }}></div>
                                                                                                        <div className="new-like-comment">
                                                                                                            <a className="like-btn active" id="aa" likeit={`${!item?.IsLiked}`} onClick={(e) => { LikeSubmit(e, item?.Id) }} >
                                                                                                                {
                                                                                                                    (item?.IsLiked) ? <AiFillLike className="icons-queries" /> : <AiOutlineLike className="icons-queries" />
                                                                                                                }
                                                                                                            </a>
                                                                                                            {
                                                                                                                item?.SpamByUsers?.find((l) => l?.SpamByUserId === profilelist?.Profile?.UserId) ?
                                                                                                                    <button className="spam-icon button-spam-discussion add-bottom-position" onClick={() => ThreadUnspam(item)}>Mark UnSpam</button> :
                                                                                                                    profilelist?.Profile?.UserId === item?.OwnerId ? null :
                                                                                                                        item?.Anonymous && item?.QueryTo?.find((l) => l?.UserId === profilelist?.Profile?.UserId) ? <button className="spam-icon button-spam-discussion add-bottom-position" onClick={() => QueryThreadSpam(item)}>Mark As Spam</button>
                                                                                                                            : null
                                                                                                            }

                                                                                                            <span id="qLikeCount" className="like-count">{item?.LikesTotal}</span>
                                                                                                            <a className="like-btn comment-icon" onClick={() => { setShow(show !== item?.Id ? item?.Id : null); setReplyId(item?.Id); getQueryResponse(item?.Id); setQueryResponseId(item?.Id) }}><GoCommentDiscussion className="icons-queries" />{item?.ReplyCount}</a>
                                                                                                            <span className="undrline-txt">{item?.DateCreated === null ? null : <> <span className='published-date'>Published On:</span> {moment(item?.DateCreated)?.format('Do MMM YYYY, h:mm A')} </>}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        item?.QueryTo?.length ?
                                                                                                            <div className="outer-section-participants">
                                                                                                                <OverlayTrigger
                                                                                                                    placement="right"
                                                                                                                    delay={{ show: 250, hide: 250 }}
                                                                                                                    overlay={
                                                                                                                        <Tooltip id="button-tooltip" className="new-query-tooltip">
                                                                                                                            <div className="new-participant-section">
                                                                                                                                <div className="participants-section">
                                                                                                                                    {
                                                                                                                                        item?.QueryTo?.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <div key={index} className="participants-inner-section">
                                                                                                                                                    <img src={item?.ProfilePhotoUrl === '' ? "images/anonymoususer.png" : `${Getimage(item?.ProfilePhotoUrl, "images/dummy.svg")}`} alt="image" className="participants-image" />
                                                                                                                                                    <div className="information_section_participants">
                                                                                                                                                        <p>{item?.ProfileName}</p>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </Tooltip>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <h3>Participants

                                                                                                                        <div>
                                                                                                                            <span>{item?.QueryTo?.length}</span>
                                                                                                                        </div>
                                                                                                                    </h3>

                                                                                                                </OverlayTrigger>
                                                                                                            </div> : null
                                                                                                    }
                                                                                                    {(show && replyId === item?.Id) ?
                                                                                                        <div className="ps-name-box add-position-reply-queries">
                                                                                                            {
                                                                                                                queriesList?.map((qResp, index) => {
                                                                                                                    return (
                                                                                                                        <Fragment key={index}>
                                                                                                                            {
                                                                                                                                qResp?.IsDelete !== true ?
                                                                                                                                    <div className="queries-reply-section-outer">
                                                                                                                                        <div className="query-response-box">

                                                                                                                                            <img src={item?.OwnerId === qResp?.ReplyById && item?.Anonymous ? "images/anonymoususer.png" : `${Getimage(qResp?.ReplyByUserPhotoUrl, "images/dummy.svg")}`} alt="User Img." className="image-main-cards-dshbord" />
                                                                                                                                            <div className="title-text new-title-text">
                                                                                                                                                <div className="spam-mark-icon">
                                                                                                                                                    <h1>{qResp?.ReplyByUserName === "" ? "Anonymous" : qResp?.ReplyByUserName}</h1>
                                                                                                                                                    {
                                                                                                                                                        qResp?.SpamByUser?.find((l) => l?.SpamByUserId === profilelist?.Profile?.UserId) ? <button className="spam-icon respo-button-mob-spam" onClick={() => unSpamResponse(qResp)}>Mark UnSpam</button>
                                                                                                                                                            : qResp?.ReplyById === profilelist?.Profile?.UserId ? null
                                                                                                                                                                : item?.Anonymous && qResp?.ReplyById === item?.OwnerId ? <button className="spam-icon respo-button-mob-spam" onClick={() => spamMark(qResp)}>Mark As Spam</button> : null
                                                                                                                                                    }

                                                                                                                                                </div>
                                                                                                                                                <p dangerouslySetInnerHTML={{ __html: qResp?.Response }}></p>
                                                                                                                                                <div className="new-like-comment">
                                                                                                                                                    <a className="like-btn active" id="aaa" likeit={`${!qResp?.IsLike}`} onClick={(e) => { LikeQueryResponse(e, qResp) }} >
                                                                                                                                                        {
                                                                                                                                                            qResp?.IsLike ? <AiFillLike className="icons-queries icon-qresp-respon" />
                                                                                                                                                                :
                                                                                                                                                                <AiOutlineLike className="icons-queries icon-qresp-respon" />
                                                                                                                                                        }
                                                                                                                                                    </a>

                                                                                                                                                    <span id="qLikeCount" className="like-count like-qresp-count-respon">{qResp?.LikeCount}</span>
                                                                                                                                                    <span className="undrline-txt float-design">{qResp?.ResponseDate === null ? null : <> <span className='published-date'>Published On:</span> {moment(qResp?.ResponseDate).format('Do MMM YYYY, h:mm A')}</>}</span>
                                                                                                                                                </div>
                                                                                                                                                {
                                                                                                                                                    profilelist?.Profile?.UserId === qResp?.ReplyById ? <AiFillEdit className="edit-icon-query-response" onClick={() => handleEditQueryResponse(qResp)} tabIndex="0" data-toggle="tooltip" title="Edit Query" /> : null
                                                                                                                                                }

                                                                                                                                                {
                                                                                                                                                    profilelist?.Profile?.UserId === qResp?.ReplyById ? <AiFillDelete id="dlt" className="delete-icon-modal-query" onClick={() => DeleteModal(qResp)} />
                                                                                                                                                        : null
                                                                                                                                                }

                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="separator-query-resp"></div>
                                                                                                                                    </div>
                                                                                                                                    : null
                                                                                                                            }
                                                                                                                        </Fragment>
                                                                                                                    );
                                                                                                                })
                                                                                                            }


                                                                                                            <div className="input-grp-fil mb-3 query-reply-box">
                                                                                                                <CKEditor className="editor-main"
                                                                                                                    editor={ClassicEditor}
                                                                                                                    config={{
                                                                                                                        removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                                                                                                    }}
                                                                                                                    data={queryReply}
                                                                                                                    onChange={(event, editor) => setQueryReply(editor?.getData())}
                                                                                                                />
                                                                                                                {
                                                                                                                    editQueryResp?.Id ? <span className="input-group-text" id="basic-addon2" onClick={(e) => EditReplyToQuery(item?.Id, e)}>Update</span> : <span className="input-group-text" id="basic-addon2" onClick={(e) => ReplyToQuery(item?.Id, e)}>Reply</span>
                                                                                                                }                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> </>
                                                                                        : null
                                                                                }
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </div>

                                                        </div>
                                                    </Tab>
                                            }
                                        </Tabs>
                                }

                            </div>
                        </div>
                    </div>
                </section>

                <Footers />

                <Modal show={showSpam} onHide={handlespamClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handlespamClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading "><span>Why</span> Spam?</h1>
                                        <textarea className="form-control txtReply spam-response-box" rows="4" cols="50" placeholder="Enter Spam Reason...." onChange={(e) => setSpamReason(e?.target?.value)} value={spamReason}></textarea>
                                        <div className="spam-button-end">
                                            <button className="spam_submit_button" onClick={() => markSpamSubmit()}>Submit</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>

                {/* main thread show modal */}
                <Modal show={spamMainThreadShow} onHide={handleQuerySpamClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleQuerySpamClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading "><span>Why</span> Spam?</h1>
                                        <textarea className="form-control txtReply spam-response-box" rows="4" cols="50" placeholder="Enter Spam Reason...." onChange={(e) => setThreadSpamReason(e?.target?.value)} value={threadSpamReason}></textarea>
                                        <div className="spam-button-end">
                                            <button className="spam_submit_button" onClick={() => MainQuerySpam()}>Submit</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>

                {/* delete query response modal */}
                <Modal show={deleteQueryModal} onHide={handleDeleteClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleDeleteClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading delete-modal-heading"><span>Are</span> you sure want to delete this Query?</h1>
                                        <div className="why-spam-btn">
                                            <button className="btn btn-primary button-delete-modal" onClick={() => DeleteUserQuery()}>
                                                Yes
                                            </button>

                                            <button className="btn btn-danger button-delete-modal-No" onClick={() => handleDeleteClose()}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>


                {/* delete query modal */}
                <Modal show={deleteMainQueryModal} onHide={handleMainDeleteClose}
                    className="modal modl-PS responsive-mobile-modal" id="modalPS">
                    <div className="modal-dialog m-none">
                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleMainDeleteClose()}></button>
                            </div>

                            <div className="modal-body">
                                <div className="main-mdl">
                                    <div className="modal-spam-section">
                                        <h1 className="why-spam-heading delete-modal-heading"><span>Are</span> you sure want to delete this Query?</h1>
                                        <div className="why-spam-btn">
                                            <button className="btn btn-primary button-delete-modal" onClick={() => DeleteQuery()}>
                                                Yes
                                            </button>

                                            <button className="btn btn-danger button-delete-modal-No" onClick={() => handleMainDeleteClose()}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}
export default Discussion;
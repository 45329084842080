import moment from "moment";
import React, { Fragment, useState } from "react";
import Headers from "../../components/header/header";
import Footers from "../../components/Footers/Footers";
import { toast } from "react-toastify";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";
import axios from "axios";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/loader";
import { BsCalendarCheck } from "react-icons/bs";

const BookingNew = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState({});;
    const [profilelist, setProfilelist] = useState()
    const [showPlus, setShowPlus] = useState(true);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [timeFields, setTimeFields] = useState([{
        startTime: new Date(),
        endTime: new Date(),
    }]);
    const [timeSlots, setTimeSlots] = useState([]);
    const [bookedData, setBookedData] = useState();
    const [confirmedData, setConfirmedData] = useState()
    const [confirmBooking, setconfirmBooking] = useState(false);
    const handleconfirmBookingClose = () => setconfirmBooking(false);
    const [alreadyBooked, setAlreadyBooked] = useState()
    const bookingData = JSON.parse(localStorage?.getItem("bookingData"))
    const [userSelectedDay, setUserSelectedDay] = useState();

    const addTimeField = () => {
        if (timeFields?.length === 2) {
            setShowPlus(false)
        } else {
            setShowPlus(true)
        }
        setTimeFields([...timeFields, {
            startTime: new Date(),
            endTime: new Date(),
        }])
    }

    const removeTimeFields = (index) => {
        setShowPlus(true)
        const rows = [...timeFields];
        rows.splice(index, 1);
        setTimeFields(rows);
    }

    const handleChangeTimeField = (index, name, evnt) => {
        const list = [...timeFields];
        list[index][name] = new Date(evnt);
        setTimeFields(list);
    }

    const weekDays = [
        {
            label: "Sun",
            value: "Sunday",
            short: 'S',
            selected: false
        },
        {
            label: "Mon",
            value: "Monday",
            short: 'M',
            selected: false
        },
        {
            label: "Tue",
            value: "Tuesday",
            short: 'T',
            selected: false
        },
        {
            label: "Wed",
            value: "Wednesday",
            short: 'W',
            selected: false
        },
        {
            label: "Thu",
            value: "Thursday",
            short: 'T',
            selected: false
        },
        {
            label: "Fri",
            value: "Friday",
            short: 'F',
            selected: false
        },
        {
            label: "Sat",
            value: "Saturday",
            short: 'S',
            selected: false
        },
    ]

    let allTimes = [];
    const [selectedWeekDays, setSelectedWeekDays] = useState(weekDays);
    const [selectedDaysArr, setSelectedDaysArr] = useState([])
    const handleSelectedWeekDays = (item, index) => {
        const unselectFiltered = selectedWeekDays?.filter(i => i?.value !== item?.value);
        const newArr = [...unselectFiltered.slice(0, index), { label: item?.label, value: item?.value, short: item?.short, selected: !item?.selected }, ...unselectFiltered.slice(index, unselectFiltered.length)];
        setSelectedWeekDays(newArr)
    }

    useEffect(() => {
        let newTime = [];
        newTime = allTimeSlots?.map(item => ({
            "startTime": `${new Date(item?.startTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`,
            "endTime": `${new Date(item?.endTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`,
            "slotInterval": 20,
        }))


        newTime.forEach(item => {
            let newArr = [];
            let startTime = moment(item?.startTime, "hh:mm A");
            let endTime = moment(item?.endTime, "hh:mm A").subtract(19, "minutes");
            while (startTime < endTime) {
                newArr.push({
                    "startHour": startTime.format("hh:mm A"),
                    "endHour": startTime.add(item?.slotInterval, 'minutes').format("hh:mm A"),
                    "slot": 4,
                    "weekDays": selectedDaysArr
                });
            }
            allTimes.push(newArr);
            return allTimes;
        });
        setTimeSlots(allTimes)
    }, [allTimeSlots, selectedDaysArr])

    const getProfiles = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/Profile`
        };
        axios(option)
            .then(e => {
                var pro = {};

                pro = JSON.parse(e?.data?.data);
                pro.Profile = JSON.parse(e?.data?.data).Profile?.[0];
                setProfilelist(pro);
                setLoading(false)

            })
            .catch((err) => {
                toast.error(err?.data?.messsage);
                if (err?.message === "Network Error") {
                    removeUserSession();
                    navigate('/')
                    toast.error("Session Timeout Please Login Again")
                }

            });
    }

    useEffect(() => {
        getProfiles()
    }, [])

    useEffect(() => {
        if (bookingData) {
            setBookedData(bookingData);
        }
    }, [profilelist?.Id])

    useEffect(() => {
        if (bookedData && profilelist?.Id) {
            const id = profilelist?.Id
            const data = bookedData?.find(item => item?.profileData?.includes(id));
            if (data?.profileData?.length) {
                setAlreadyBooked(data)
            }
        }
    }, [bookedData, profilelist?.Id])

    useEffect(() => {
        const handleEsc = (e) => {
            if (e.key === 'Escape' || e.keyCode === 27) {
                const id = profilelist?.Id
                const data = bookedData?.find(item => item?.profileData.includes(id));
                if (data?.profileData?.length) {
                    setAlreadyBooked(data)
                }
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [alreadyBooked?.startHour]);

    const handleCreateTimeSlots = () => {
        const selected = selectedWeekDays?.filter(item => item?.selected === true)
        if (selected?.length < 1) {
            toast.error("Please select availability of days.")
            return;
        }
        const StartTime = `${new Date(timeFields?.[0]?.startTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        const EndTime = `${new Date(timeFields?.[0]?.endTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`

        if (timeFields?.length === 1 && StartTime === EndTime) {
            toast.error("Start Time and End Time can not be equal.")
            return false;
        }
        setAllTimeSlots(timeFields); setSelectedDaysArr(selectedWeekDays)
    }
    const handlebookNow = (i) => {

        if (!userSelectedDay?.startHour) {
            toast.error("Please select Day");
            return;
        } else if (userSelectedDay?.startHour !== i?.startHour) {
            setUserSelectedDay({})
            toast.error("Please select the correct Day according to time slot")
            return;
        }
        const addDays = userSelectedDay?.week === "current" ? (userSelectedDay?.weekNumber - new Date().getDay()) : (userSelectedDay?.weekNumber - new Date().getDay() + 7)
        let booked = []
        if (bookedData?.length) {
            const foundEl = bookedData?.find(el => el?.startHour === i?.startHour && el?.slotDate?.date === moment(new Date()).add(addDays, "days"));
            const filtered = bookedData?.filter(el => el?.startHour !== i?.startHour)
            const id = foundEl?.profileData || [];
            id.push(profilelist?.Id)
            booked = [...filtered,
            {
                startHour: i?.startHour,
                endHour: i?.endHour,
                slotDate: {
                    slot: foundEl?.date === moment(new Date()).add(addDays, "days") ? foundEl?.slotDate - 1 : i?.slot - 1,
                    date: moment(new Date()).add(addDays, "days")
                },
                weekDays: userSelectedDay?.day,
                profileData: id,
            }]
        } else {
            const id = [];
            id.push(profilelist?.Id)
            booked = [
                {
                    startHour: i?.startHour,
                    endHour: i?.endHour,
                    slotDate: {
                        slot: i?.slot - 1,
                        date: moment(new Date()).add(addDays, "days")
                    },
                    weekDays: userSelectedDay?.day,
                    profileData: id,
                }]
        }
        setSelectedTimeSlot({
            startHour: i?.startHour,
            endHour: i?.endHour,
            slotDate: {
                slot: i?.slot - 1,
                date: moment(new Date()).add(addDays, "days")
            },
            weekDays: userSelectedDay?.day,
        })
        setConfirmedData(booked);
        setconfirmBooking(true);
    }
    const handleconfirmBookingSubmit = () => {
        localStorage.setItem("bookingData", JSON.stringify(confirmedData))
        navigate("/")
    }
    const handleAlreadyBooked = () => {
        navigate("/")
    }
    const minTime = new Date();
    minTime.setHours(8, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(20, 0, 0);
    return (

        <div className="page-container">
            <Headers innerPages={"innerPages"} />
            {
                loading ? <Loader /> : null
            }

            <div className="container booking-container">
                <div className="row p-4 w-100">
                    <div className="col-md-12 days-available"><span>Availability of Days</span></div>
                    <div className="col-md-12 d-flex gap-4">

                        {
                            selectedWeekDays?.map((item, index) => (
                                <div className={item?.selected === true ? "selectedWeekDay" : "unselectedWeekDay"} key={index} onClick={() => handleSelectedWeekDays(item, index)}>{item?.label}</div>
                            ))
                        }
                    </div>
                    <div className="col-md-12 days-available"><span>Working Hours</span></div>
                    <div className="col-md-10">
                        <div className="frm-inpt-profle">
                            <div className="row">
                                {
                                    timeFields?.length ? <>
                                        {
                                            timeFields.map((item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <div className="col-md-5">
                                                            <div className="frm-inpt-inr profile-timepicker">
                                                                <label htmlFor="startTime">Start Time</label>
                                                                <DatePicker
                                                                    selected={item?.startTime}
                                                                    onChange={(evnt) => handleChangeTimeField(index, "startTime", evnt)}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={30}
                                                                    timeCaption="Start Time"
                                                                    dateFormat="h:mm aa"
                                                                    minTime={minTime}
                                                                    maxTime={maxTime}
                                                                    className="booking-time-slot"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div className="frm-inpt-inr profile-timepicker">
                                                                <label htmlFor="endTime">End Time</label>
                                                                <DatePicker
                                                                    selected={item?.endTime}
                                                                    onChange={(evnt) => handleChangeTimeField(index, "endTime", evnt)}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={30}
                                                                    timeCaption="End Time"
                                                                    dateFormat="h:mm aa"
                                                                    minTime={minTime}
                                                                    maxTime={maxTime}
                                                                    className="booking-time-slot"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 startTime-profile">
                                                            {(timeFields.length !== 1) ? <button className="btn btn-outline-primary removeOption-profile" onClick={() => removeTimeFields(index)}>x</button> : ''}
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </> : null
                                }
                            </div>
                            {
                                showPlus ? <div className="row">
                                    <div className="col-sm-12 new-option-poll">
                                        Add More
                                        <button className="btn btn-outline-primary removeOptionCross-profile" onClick={() => addTimeField()}>+</button>
                                    </div>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
                <div onClick={() => handleCreateTimeSlots()} className="time_slots_btn">Create Time Slots</div>
                {
                    allTimeSlots?.length ?
                        <div className="booking-slots-container">
                            <div className="slots-booking-head">
                                <table className="booking_slots_table">
                                    <thead>
                                        <tr className="booking_slots_thead">
                                            <td className="booking_slots_td1">Time</td>
                                            {
                                                selectedDaysArr.filter((item, ind) => !(item?.selected === false || ind <= new Date().getDay()))?.length ? <td>Available Days for Current Week</td> :
                                                    <td>Available Days for Next Week</td>
                                            }

                                            <td className="booking_slots_td2">Slots</td>
                                            <td className="booking_slots_td3"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {timeSlots?.map((item, index) => (

                                            item?.map((i, l) => {
                                                const foundElement = bookedData?.find(el => el?.startHour === i?.startHour);
                                                return (
                                                    <tr key={l} className="booking_slots_tr">
                                                        <td className="booking_slots_td1">{i?.startHour}-{i?.endHour}</td>
                                                        <td className="booking_slot_days_td">
                                                            {
                                                                i?.weekDays?.filter((item, ind) => !(item?.selected === false || ind <= new Date().getDay()))?.length ? <>
                                                                    {
                                                                        i?.weekDays?.map((days, index) => {
                                                                            return (
                                                                                <button
                                                                                    onClick={() => (setUserSelectedDay({ startHour: i?.startHour, day: days?.value, weekNumber: index, week: "current" }))}
                                                                                    disabled={days?.selected === false || index <= new Date().getDay()}
                                                                                    className={days?.selected === true && userSelectedDay?.startHour === i?.startHour && userSelectedDay?.day === days?.value ? "booking_slot_days-3" : days?.selected === true && index > new Date().getDay() ? "booking_slot_days-2" : "booking_slot_days"}
                                                                                >{days?.short}</button>
                                                                            )
                                                                        })
                                                                    }
                                                                </> : <>
                                                                    {
                                                                        i?.weekDays?.map((days, index) => {
                                                                            return (
                                                                                <button
                                                                                    onClick={() => (setUserSelectedDay({ startHour: i?.startHour, day: days?.value, weekNumber: index, week: "next" }))}
                                                                                    disabled={days?.selected === false}
                                                                                    className={days?.selected === true && userSelectedDay?.startHour === i?.startHour && userSelectedDay?.day === days?.value ? "booking_slot_days-3" : days?.selected === true ? "booking_slot_days-2" : "booking_slot_days"}
                                                                                >{days?.short}</button>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            }

                                                        </td>
                                                        <td className="booking_slots_td2">{foundElement?.startHour ? foundElement?.slotDate?.slot : i?.slot}</td>
                                                        <td className="booking_slots_td3">
                                                            <button
                                                                disabled={foundElement?.slot < 1}
                                                                onClick={() => handlebookNow(i)}
                                                                className={selectedTimeSlot?.startHour === i?.startHour ? "booking_slots_btn1" : "booking_slots_btn"}
                                                                key={index}>{foundElement?.slot < 1 ? "Booked" : "Book"}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div> : null
                }
            </div>
            <Footers />
            <Modal show={confirmBooking} onHide={handleconfirmBookingClose}
                className="modal modl-PS responsive-mobile-modal confirmBooking-time" id="modalPS">
                <div className="modal-dialog m-none">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleconfirmBookingClose()}></button>
                        </div>
                        <div className="modal-body">
                            <div className="main-mdl">
                                <div className="modal-spam-section">
                                    <BsCalendarCheck className="calenderIcon-booking" />
                                    <h1 className="why-spam-heading "><span>Confirm Booking</span></h1>
                                    <div className="time-confirm-modal">{selectedTimeSlot?.startHour} - {selectedTimeSlot?.endHour}</div>
                                    <div className="day-confirm-modal">{selectedTimeSlot?.weekDays}, {moment(selectedTimeSlot?.slotDate?.date).format('LL')}</div>
                                    <div className="booking-button-end gap-3">
                                        <button className="spam_submit_button" onClick={() => handleconfirmBookingSubmit()}>Save</button>
                                        <button className="spam_submit_button" onClick={() => handleconfirmBookingClose()}>Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </Modal>

            <Modal show={alreadyBooked?.startHour} onHide={() => setAlreadyBooked()} backdrop="static"
                className="modal modl-PS responsive-mobile-modal" id="modalPS" backdropClassName="already-booked-modal">
                <div className="modal-dialog m-none">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="main-mdl">
                                <div className="modal-spam-section">
                                    <BsCalendarCheck className="calenderIcon-booking" />
                                    <h1 className="why-spam-heading "><span>You have already booked your appointment.</span></h1>
                                    {/* <div className="day-confirm-modal-details">Appointment Details</div> */}
                                    <div className="time-confirm-modal">{alreadyBooked?.startHour} - {alreadyBooked?.endHour}</div>
                                    <div className="day-confirm-modal">{alreadyBooked?.weekDays}, {moment(alreadyBooked?.date).format('LL')}</div>
                                    <div className="booking-button-end gap-3">
                                        {/* <h1 className="why-spam-heading ">You have already booked your slot at <span>{alreadyBooked?.startHour}-{alreadyBooked?.endHour}</span></h1>
                                    <div className="spam-button-end"> */}
                                        <button className="spam_submit_button" onClick={() => handleAlreadyBooked()}>Go Back to Dashboard</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default BookingNew;
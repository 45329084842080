import React, { useEffect, useState } from "react";
import { ApiUrl, getToken } from "../../utils/common";
import axios from "axios";
import Loader from "../../components/Loader/loader";
import Headers from "../../components/header/header";
import Footers from "../../components/Footers/Footers";
import { Button, Card, ListGroup } from "react-bootstrap";
import { toast } from "react-toastify";

const ListPoll = () => {
    const [loading, setLoading] = useState(false);
    const [listQuestions, setListQuestions] = useState([]);
    const getPollList = () => {
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: {},
            url: `${ApiUrl()}/Admin/ListPollQuestion`
        };

        axios(option)
            .then(e => {
                setLoading(false)
                setListQuestions(JSON.parse(e?.data?.data))
            })
            .catch((err) => {
                setLoading(false)
            });
    }
    useEffect(() => {
        getPollList()
    }, [])

    const updatePoll=(item)=>{
        const data = {};
        data.id=item?.Id;
        data.Publish = !item?.Publish;
        setLoading(true)
        const option = {
            method: 'POST', headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            data: { "data": JSON.stringify(data) },
            url: `${ApiUrl()}/Admin/PublishPollQuestion`
        };

        axios(option)
            .then(e => {
                toast.success(e?.data?.message)
                getPollList();
            })
            .catch((err) => {
                setLoading(false)
            });
    }

    return (
        <div className="page-container">
            {
                loading ? <Loader /> : null
            }
            <Headers innerPages={"innerPages"} />
            <div className="container my-5 listpoll">
                <div className="row listpoll-container">
                    
                    {
                        listQuestions?.map((item, index) => (
                            <div className="col-md-3" key={index}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className="ques-heading-poll">Question {index + 1}</Card.Title>
                                        <Card.Text>
                                            {item?.Question?.trim()?.substr(0, 60)}{item?.Question?.length > 60 ? "..." : null}
                                        </Card.Text>
                                    </Card.Body>
                                    <ListGroup className="list-group-flush">
                                        {
                                            item?.PollOptions?.map((option, index) => (
                                                <ListGroup.Item key={index}>{option?.DisplayNo}. {option?.OptionPoll?.trim()?.substr(0, 40)}{option?.OptionPoll?.length > 40 ? "..." : null}</ListGroup.Item>
                                            ))
                                        }
                                    </ListGroup>
                                    <div className="listpoll-btn-wrapper">
                                        {
                                            item?.Publish === false ?  <Button className="blue-poll" onClick={()=>updatePoll(item)}>Publish</Button> :  <Button className="orange-poll" onClick={()=>updatePoll(item)}>Published</Button>
                                        }
                                    </div>
                                </Card>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Footers />
        </div>
    )
}

export default ListPoll;
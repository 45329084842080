// return the user data from the local storage
export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
}

// return the token from the local storage
export const getToken = () => {
    return localStorage.getItem('token') || null;
}

export const Getsaltyeml = () =>
{
    return JSON.parse(window.atob( `${getToken()}`.split('.')[1])).email;

}

// remove the token and user from the local storage
export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('rolename');
    localStorage.removeItem('roleId');
    localStorage.removeItem('poll')
}

// set the token and user from the local storage
export const setUserSession = (token) => {
    localStorage.setItem('token', token);
}

// global api url 
export const ApiUrl = () => {
    return ApiHost() + '/api';
}

export const ApiHost = () => {
    // return 'http://198.12.253.79:2600';
    return 'https://api.privatesquares.com';
}

export const setRoleName = (rolename) => {
    localStorage.setItem('rolename', rolename)
}

export const setRoleId = (roleId) => {
    localStorage.setItem('roleId', roleId)
}

export const GetRoleName = () => {
    return localStorage.getItem('rolename') || null;
}


// image render function
export const Getimage = (src, emptyimg) => {
    if (!src || src=== null || src=== undefined) { return emptyimg; }
    else if (src.includes("http")) { return src }
    else {

        return ApiHost() + src;
    }
}
